import { Flex } from '@mantine/core';
import { FC } from 'react';
import { isMissing } from 'utilitype';
import { LiitFormContainerFooter } from './LiitFormContainerFooter';
import { LiitFormContainerStyles } from './LiitFormContainerStyles';

type LiitFormContainerProps = {
  children: React.ReactNode;
  confirmText: string;
  onCancel: () => void;
};

export const LiitFormContainer: FC<LiitFormContainerProps> = ({
  children,
  confirmText,
  onCancel,
}) => {
  const { classes } = LiitFormContainerStyles();

  if (isMissing(children)) {
    return <></>;
  }

  return (
    <Flex w={'100%'} className={classes.wrapper} direction={'column'}>
      <div className={classes.content}>{children}</div>
      <LiitFormContainerFooter {...{ confirmText, onCancel }}></LiitFormContainerFooter>
    </Flex>
  );
};
