import React, { useEffect, useState } from 'react';
import {
  Stack,
  Button,
  Textarea,
  Text,
  Group,
  Slider,
  Grid,
  Input,
  Loader,
  createStyles,
  useMantineTheme,
  Badge,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { ApplicationReviewModel } from '../../../models/ApplicationReviewModel';
import { ApplicationReviewStatus } from '../../../models/ApplicationReviewStatus';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import { formatValue } from '../../../components/LiitGrid/FormatValue';
import { LiitNumberInput } from '../../../components/LiitNumberInput/LiitNumberInput';
import { t } from 'ttag';
import { showFailNotification, showSuccessNotification } from '../../../utils/notificationHelper';
import { Application } from '../../../models/Application';
import { isMissing, isPresent } from 'utilitype';
import { ErrorMessages } from '../../../utils/errorMessages';
import { IconCheck } from '@tabler/icons';
import { useDebouncedValue } from '@mantine/hooks';
import { PaymentOptions } from '../../../models/PaymentOptions';
import Formatter from '../../../utils/formatter';

const useStyles = createStyles((theme) => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
  },
  biasedButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
  },
}));

interface ApplicationReviewFormProps {
  application: Application;
  onStatusChanged: (status: ApplicationReviewStatus) => void;
}

interface FormModel {
  scores: { score: number; criteriaId: string }[];
  suggestedAmount: number | null;
  comment: string;
  status: ApplicationReviewStatus;
}

export const ApplicationReviewForm: React.FC<ApplicationReviewFormProps> = ({
  application,
  onStatusChanged,
}) => {
  const { selectedOrganisation, refreshCounters } = useSession();
  const api = useApi();
  const [review, setReview] = useState<ApplicationReviewModel>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showSavingIndicator, setShowSavingIndicator] = useState(false);
  const [allowAutoSave, setAllowAutoSave] = useState(false);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const form = useForm<FormModel>({
    initialValues: {
      scores: [],
      suggestedAmount: null,
      comment: '',
      status: ApplicationReviewStatus.NotStarted,
    },
  });

  const [debounced, cancel] = useDebouncedValue(form.values, 1000);

  const { id: applicationId, requestedAmount } = application;
  const { status } = form.values;

  const refresh = async () => {
    if (!selectedOrganisation || !applicationId) {
      return;
    }

    const response = await api.getApplicationReviewForUser(selectedOrganisation.id, applicationId);

    setReview(response);

    form.reset();

    form.setValues({
      status: response.status,
      comment: response.comment ?? '',
      suggestedAmount: response.suggestedAmount,
      scores: response.scores.map((x) => ({ score: x.score, criteriaId: x.criteriaId })),
    });
    setAllowAutoSave(response.status !== ApplicationReviewStatus.Completed);
    setShowSavingIndicator(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await refresh();
    };

    if (selectedOrganisation) {
      fetchData();
    }
  }, [selectedOrganisation, application.id]);

  useEffect(() => {
    if (allowAutoSave && form.isTouched()) {
      setShowSavingIndicator(true);
      setIsSaving(true);
    }
  }, [form.values]);

  const autoSave = async (): Promise<void> => {
    if (!selectedOrganisation || !applicationId) {
      return;
    }

    if (status === ApplicationReviewStatus.NotStarted) {
      form.values.status = ApplicationReviewStatus.Ongoing;
    }

    try {
      setIsSaving(true);
      await api.updateApplicationReview(selectedOrganisation.id, applicationId, form.values);
      onStatusChanged(form.values.status);
    } catch {
      showFailNotification(
        t`Fel vid automatisk sparning`,
        t`Något gick fel vid den automatiska sparningen av din granskaning`,
      );
    } finally {
      setIsSaving(false);
    }
  };

  const save = async (newStatus: ApplicationReviewStatus): Promise<void> => {
    if (!selectedOrganisation || !applicationId) {
      return;
    }

    const validationErrors = form.validate().errors;

    if (isMissing(validationErrors)) {
      return;
    }

    cancel();
    setAllowAutoSave(false);
    setShowSavingIndicator(false);

    form.values.status = newStatus;
    form.setFieldValue('status', newStatus);

    if (
      form.values.status === ApplicationReviewStatus.Biased ||
      application.paymentOption === PaymentOptions.NoPayment
    ) {
      form.values.suggestedAmount = null;
    }

    try {
      const response = await api.updateApplicationReview(
        selectedOrganisation.id,
        applicationId,
        form.values,
      );
      if (response.ok) {
        showSuccessNotification(
          t`Underlag sparat`,
          t`Granskningsunderlag sparat för ansökan: ${application.number}`,
        );

        if (refreshCounters) {
          await refreshCounters();
        }

        onStatusChanged(form.values.status);
      }
    } catch {
      showFailNotification(
        t`Något gick fel vid sparning`,
        t`Något gick fel vid sparning av granskning`,
      );
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (allowAutoSave && form.isTouched()) {
      autoSave().finally(() => setIsSaving(false));
    }
  }, [debounced]);

  const isCompleted = form.values.status === ApplicationReviewStatus.Completed;

  const buttons = () => {
    return (
      <Input.Wrapper label={t`Min rekommendation:`}>
        <Grid gutter={'xs'}>
          <Grid.Col span={4}>
            <Button
              fullWidth
              color={'red.4'}
              onClick={async () => {
                await save(ApplicationReviewStatus.Biased);
              }}>
              {t`Jäv`}
            </Button>
          </Grid.Col>
          <Grid.Col span={8}>
            <Button
              fullWidth
              color={'green.5'}
              className={classes.button}
              onClick={async () => {
                await save(ApplicationReviewStatus.Completed);
              }}>
              {t`Klarmarkera`}
            </Button>
          </Grid.Col>
        </Grid>
      </Input.Wrapper>
    );
  };

  if (isMissing(review)) {
    return (
      <Group position={'center'}>
        <Loader />
      </Group>
    );
  }

  const getCriterias = () => {
    return (
      <Grid>
        {review.scores.map((score, index) => (
          <React.Fragment key={`criteria_${score.criteriaId}`}>
            <Grid.Col xs={6}>
              <Text size="sm">{score.title}</Text>
            </Grid.Col>
            <Grid.Col xs={5}>
              <Slider
                disabled={isCompleted}
                size="md"
                min={0}
                max={review.maxCriteriaScore}
                label={null}
                value={form.values.scores[index].score}
                onChange={(value) => {
                  form.setFieldValue(`scores.${index}.score`, value);
                }}
                onChangeEnd={(value) => {
                  form.setFieldValue(`scores.${index}.score`, value);
                }}
              />
            </Grid.Col>
            <Grid.Col xs={1}>
              <Text size="sm">{form.values.scores[index].score}</Text>
            </Grid.Col>
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  if (
    form.values.status === ApplicationReviewStatus.Completed ||
    form.values.status === ApplicationReviewStatus.Biased
  ) {
    return (
      <Stack>
        {form.values.status === ApplicationReviewStatus.Biased && (
          <Badge color={'red.7'}>{t`Jäv`}</Badge>
        )}
        {form.values.status !== ApplicationReviewStatus.Biased && getCriterias()}
        {form.values.status === ApplicationReviewStatus.Completed &&
          !isMissing(form.values.suggestedAmount) && (
            <Input.Wrapper label={t`Föreslå belopp`}>
              <Text size="sm">{Formatter.formatCurrency(form.values.suggestedAmount)}</Text>
            </Input.Wrapper>
          )}
        {isPresent(form.values.comment) && (
          <Input.Wrapper label="Kommentar:">
            <Text size="sm">{form.values.comment}</Text>
          </Input.Wrapper>
        )}
        <Group position="right">
          <Button
            variant="outline"
            onClick={() => {
              save(ApplicationReviewStatus.Ongoing).finally(() => {
                onStatusChanged(ApplicationReviewStatus.Ongoing);
                setAllowAutoSave(true);
              });
            }}>
            Ångra klarmarkering
          </Button>
        </Group>
      </Stack>
    );
  }

  return (
    <form>
      <Stack>
        {getCriterias()}
        {application.paymentOption !== PaymentOptions.NoPayment && (
          <>
            <LiitNumberInput
              placeholder="Belopp"
              label="Föreslå belopp:"
              hideControls
              value={form.values.suggestedAmount ?? undefined}
              onChange={(value) => form.setFieldValue('suggestedAmount', value ?? null)}
            />
            <Text size="xs">
              Sökt belopp: <b>{formatValue(requestedAmount, PropertyType.Currency)}</b>
            </Text>
          </>
        )}
        <Textarea
          minRows={3}
          placeholder="Skriv en eventuell kommentar ..."
          label="Kommentar:"
          {...form.getInputProps('comment')}
        />
        {showSavingIndicator && (
          <Group position={'right'} spacing={4}>
            {isSaving ? (
              <Loader size={'xs'} />
            ) : (
              <IconCheck size={20} color={theme.colors.green[5]} />
            )}

            <Text size={'sm'}>{isSaving ? t`Sparar` : t`Sparad`}</Text>
          </Group>
        )}
        {buttons()}
      </Stack>
    </form>
  );
};
