import { Grid, Space, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import {
  IconArrowLeft,
  IconArrowRight,
  IconMinus,
  IconPlus,
  IconSettings,
  IconTrash,
} from '@tabler/icons';
import { FormBuilderPropertyView } from './FormBuilderPropertyView';
import { FormBuilderToolbox } from './FormBuilderToolbox/FormBuilderToolbox';
import { FormElement } from './models/FormElement';
import { FormElementType } from './models/FormElementType';
import { FormElementComponent } from './FormElementComponent';
import { FormElementMode } from './FormElementMode';
import { FormPage } from './models/FormPage';
import { ToolboxItems } from './FormBuilderToolbox/ToolBoxItems';
import { formPageBuilderStyles } from './FormBuilderStyles/formPageBuilderStyles';
import {
  getGenericDefaults,
  getElementWithOptionsDefaults,
  getRepeaterDefaults,
  getTextAreaDefaults,
  getAmountTableDefaults,
  getTypedTextBoxDefaults,
  getElementWithOptionsAndControlQuestionDefaults,
} from './FormElements/ElementDefaultOptions';
import { isPresent } from 'utilitype';
import { getNewSize } from './ElementSizeCalculator';
import { reorderArray } from '../../utils/reorderArray';
import { LiitActionIcon } from '../LiitActionIcon/LiitActionIcon';
import { t } from 'ttag';
import { LiitDrawer } from '../LiitDrawer/LiitDrawer';
import {
  InputTypesSetting,
  ToolboxSetting,
} from './FormBuilderToolbox/ToolboxItemSettings';
import { TypedTextBoxTypeItems } from './FormElements/TypedTextBox/TypedTextBoxTypeItems';
import { WayOfPayment } from '../../models/WayOfPayment';
import { WayOfPaymentElementValue } from './FormElements/WayOfPaymentElement/WayOfPaymentValue';
import { IdentityNumberPersonValue } from './FormElements/IdentityNumberPerson/IdentityNumberPersonValue';
import { ContactPersonOrganizationValue } from './FormElements/ContactPersonOragnization/ContactPersonOrganizationValue';
import { ContactPersonValue } from './FormElements/ContactPerson/ContactPersonValue';

export enum ElementChangeType {
  None,
  ElementAdded,
  ElementRemoved,
  ElementUpdated,
}
interface FormPageBuilderProps {
  page: FormPage;
  questionNumber: number;
  onPageUpdated: () => void;
  toolbox: ToolboxSetting;
}

export const FormPageBuilder: React.FC<FormPageBuilderProps> = ({
  page,
  questionNumber,
  onPageUpdated,
  toolbox,
}) => {
  const [propertyEditorOpened, setPropertyEditorOpened] = useState(false);
  const [selectedElement, setSelectedElement] = useState<FormElement>();
  const { classes } = formPageBuilderStyles();

  const updatePage = () => {
    onPageUpdated();
  };

  const selectElement = (element: FormElement) => {
    setSelectedElement(element);
    setPropertyEditorOpened(true);
  };

  const deleteElement = (element: FormElement) => {
    page.elements = page.elements.filter((el) => el.id !== element.id);
    updatePage();
  };

  const updateElement = (element: FormElement) => {
    const index = page.elements.findIndex((x) => x.id === element.id);
    page.elements[index] = element;
    setSelectedElement(element);

    setPropertyEditorOpened(false);
    updatePage();
  };

  const renderElement = (element: FormElement, index: number) => {
    const key = `element_${element.id}_${index}`;
    return (
      <FormElementComponent
        key={key}
        number={questionNumber + index}
        element={element}
        mode={FormElementMode.Editor}
        triggerSave={() => onPageUpdated()}
      />
    );
  };

  const appendElement = (element: FormElement) => {
    page.elements.push(element);
  };

  const addElementTypeMap: { [key in FormElementType]: () => void } = {
    [FormElementType.TextArea]: () => appendElement(getTextAreaDefaults(FormElementType.TextArea)),
    [FormElementType.Dropdown]: () =>
      appendElement(getElementWithOptionsAndControlQuestionDefaults(FormElementType.Dropdown)),
    [FormElementType.RadioButtonGroup]: () =>
      appendElement(getElementWithOptionsAndControlQuestionDefaults(FormElementType.RadioButtonGroup)),
    [FormElementType.CheckboxGroup]: () =>
      appendElement(getElementWithOptionsDefaults(FormElementType.CheckboxGroup, [])),
    [FormElementType.AmountsTable]: () =>
      appendElement(getAmountTableDefaults(FormElementType.AmountsTable)),
    [FormElementType.FileGroup]: () =>
      appendElement(getGenericDefaults(FormElementType.FileGroup, null)),
    [FormElementType.Repeater]: () => appendElement(getRepeaterDefaults(FormElementType.Repeater)),
    [FormElementType.TypedTextBox]: () =>
      appendElement(getTypedTextBoxDefaults(FormElementType.TypedTextBox)),
    [FormElementType.SectionTitle]: () =>
      appendElement(getGenericDefaults(FormElementType.SectionTitle)),
    [FormElementType.DatePickerElement]: () =>
      appendElement(getGenericDefaults(FormElementType.DatePickerElement, null)),
    [FormElementType.ContactPerson]: () =>
      appendElement(
        getGenericDefaults(FormElementType.ContactPerson, {
          isByProxy: false,
          contactPersonFirstName: '',
          contactPersonLastName: '',
        } as ContactPersonValue),
      ),
    [FormElementType.ContactPersonOrganization]: () =>
      appendElement(
        getGenericDefaults(FormElementType.ContactPersonOrganization, {
          isByProxy: false,
          contactPersonFirstName: '',
          contactPersonLastName: '',
        } as ContactPersonOrganizationValue),
      ),
    [FormElementType.IdentityNumberPerson]: () =>
      appendElement(
        getGenericDefaults(FormElementType.IdentityNumberPerson, {
          missingIdentityNumber: false,
          identityNumber: '',
        } as IdentityNumberPersonValue),
      ),
    [FormElementType.IdentityNumberOrganization]: () =>
      appendElement(getGenericDefaults(FormElementType.IdentityNumberOrganization, '')),
    [FormElementType.WayOfPayment]: () =>
      appendElement(
        getGenericDefaults(FormElementType.WayOfPayment, {
          wayOfPayment: WayOfPayment.Undefined,
          pgNumber: '',
          bgNumber: '',
          clearingNumber: '',
          accountNumber: '',
        } as WayOfPaymentElementValue),
      ),
  };

  const addElement = (elementType: FormElementType) => {
    addElementTypeMap[elementType]();
    updatePage();
  };

  const renderElements = () => {
    return (
      <Grid>
        {page.elements.map((element: FormElement, index) => {
          return (
            <Grid.Col lg={element.size} key={element.id}>
              <div className={classes.item}>
                {element.size > 2 && (
                  <div className={classes.elementTypeName}>
                    <Text color={'#909296'} size={'xs'}>
                      {ToolboxItems[element.type].label}
                    </Text>
                  </div>
                )}
                <Stack
                  key={element.id}
                  style={{ width: '100%' }}
                  spacing="xs"
                  onClick={() =>
                    element.type !== FormElementType.Repeater &&
                    element.type !== FormElementType.ContactPerson &&
                    element.type !== FormElementType.ContactPersonOrganization &&
                    element.type !== FormElementType.WayOfPayment
                      ? selectElement(element)
                      : null
                  }>
                  {renderElement(element, index)}
                  <Space h={'lg'} />
                </Stack>
                <div className={classes.elementControlls}>
                  {element.type !== FormElementType.ContactPerson &&
                    element.type !== FormElementType.ContactPersonOrganization &&
                    element.type !== FormElementType.WayOfPayment && (
                      <LiitActionIcon
                        tooltip={t`Inställningar`}
                        icon={<IconSettings size={16} />}
                        onClick={() => selectElement(element)}
                      />
                  )}

                  <LiitActionIcon
                    tooltip={t`Ta bort`}
                    icon={<IconTrash size={16} />}
                    onClick={() => deleteElement(element)}
                  />
                </div>
                {ToolboxItems[element.type].canResize && (
                  <div className={classes.sizeControlls}>
                    <LiitActionIcon
                      tooltip={t`Minska storlek`}
                      icon={<IconMinus size={16} />}
                      onClick={() => {
                        const newSize = getNewSize(false, element.size);

                        if (isPresent(newSize)) {
                          element.size = newSize;
                          updatePage();
                        }
                      }}
                    />
                    <LiitActionIcon
                      tooltip={t`Öka storlek`}
                      icon={<IconPlus size={16} />}
                      onClick={() => {
                        const newSize = getNewSize(true, element.size);

                        if (isPresent(newSize)) {
                          element.size = newSize;
                          updatePage();
                        }
                      }}
                    />
                  </div>
                )}
                <div className={classes.orderControlls}>
                  <LiitActionIcon
                    tooltip={t`Flytta vänster`}
                    icon={<IconArrowLeft size={16} />}
                    onClick={() => {
                      reorderArray(page.elements, index, index - 1);
                      updatePage();
                    }}
                  />
                  <LiitActionIcon
                    tooltip={t`Flytta höger`}
                    icon={<IconArrowRight size={16} />}
                    onClick={() => {
                      reorderArray(page.elements, index, index + 1);
                      updatePage();
                    }}
                  />
                </div>
              </div>
            </Grid.Col>
          );
        })}
      </Grid>
    );
  };

  const getAvailableInputTypes = (element: FormElement): InputTypesSetting[] | undefined => {
    if (element.type !== FormElementType.TypedTextBox) {
      return undefined;
    }

    const result = toolbox.inputTypes.map((inputType) => {
      const inputSetting = TypedTextBoxTypeItems[inputType];
      const limit = inputSetting.limit ?? 0;

      const elementCount =
          toolbox.counters.inputTypes.find((it) => it.type === inputSetting.type)?.count ?? 0;

      const canAddMore = limit === 0 || elementCount < limit;

      const mapItem: InputTypesSetting = {
        ...inputSetting,
        limitReached: !canAddMore,
        count: elementCount,
      };

      return mapItem;
    });

    return result;
  };

  return (
    <>
      <Stack>
        {renderElements()}
        <FormBuilderToolbox addElement={addElement} toolbox={toolbox} />
        <Space />
      </Stack>

      <LiitDrawer
        opened={propertyEditorOpened}
        onClose={() => setPropertyEditorOpened(false)}
        size={'xl'}>
        {selectedElement && (
          <FormBuilderPropertyView
            selectedElement={selectedElement}
            apply={updateElement}
            close={() => setPropertyEditorOpened(false)}
            availableInputTypes={getAvailableInputTypes(selectedElement)}
          />
        )}
      </LiitDrawer>
    </>
  );
};
