import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons';
import React, { ReactNode } from 'react';

const RADIUS = 'sm';
const AUTO_CLOSE = 3000;

export const showSuccessNotification = (title: string, message: string | ReactNode) => {
  showNotification({
    title: title,
    message: message,
    color: 'green',
    icon: <IconCheck />,
    radius: RADIUS,
    autoClose: AUTO_CLOSE,
  });
};

export const showFailNotification = (title: string, message: string) => {
  showNotification({
    title: title,
    message: message,
    color: 'red',
    icon: <IconX />,
    radius: RADIUS,
    autoClose: AUTO_CLOSE,
  });
};