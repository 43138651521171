import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export const Providers = (props: { children: React.ReactNode }) =>  {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      { props.children }
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};