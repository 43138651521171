import React from 'react';
import { Group, Text, Center, MediaQuery } from '@mantine/core';
import { styles } from './Styles';
import { TableHeaderProperties } from './TableHeaderProperties';
import { getChevron } from './GetChevron';
import { PropertyType } from './PropertyType';
import { HideCondition } from './IDisplayColumn';

export const TableHeader: React.FC<TableHeaderProperties> = ({
  children,
  reversed,
  sorted,
  onSort,
  disableSort = false,
  columnStyles = {},
  propertyType,
  column,
}) => {
  const { classes, cx } = styles();

  const col = column;

  return (
    <MediaQuery
      key={`x_col_${col.propertyName}_${col.displayName}`}
      largerThan={
        col.hideAt && col.hideAt.type === HideCondition.LargerThan ? col.hideAt.size : undefined
      }
      smallerThan={
        col.hideAt && col.hideAt.type === HideCondition.SmallerThan ? col.hideAt.size : undefined
      }
      styles={{ display: 'none' }}>
      <th
        className={cx(classes.th, classes.paddingOnFirstChild, classes.paddingOnLastChild)}
        onClick={disableSort ? undefined : onSort}
        style={columnStyles}>
        <Group
          position={propertyType === PropertyType.Currency ? 'right' : 'apart'}
          spacing="xs"
          noWrap
          style={{ gap: 0 }}>
          {!disableSort && propertyType === PropertyType.Currency && (
            <Center inline className={classes.icon}>
              {getChevron(sorted, reversed)}
            </Center>
          )}

          <Text weight={700} size="sm">
            {children}
          </Text>

          {!disableSort && propertyType !== PropertyType.Currency && (
            <Center inline className={classes.icon}>
              {getChevron(sorted, reversed)}
            </Center>
          )}
        </Group>
      </th>
    </MediaQuery>
  );
};
