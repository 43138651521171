import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Title, Group, Divider, createStyles, Flex } from '@mantine/core';
import { useSession } from '../../../hooks/useSession';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useApi } from '../../../hooks/useApi';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { IconMoodEmpty } from '@tabler/icons';
import { ApplicationsListColumns } from './ApplicationsListColumns';
import { LiitDropdown, LiitDropdownMenuItem } from '../../../components/LiitDropdown/LiitDropdown';
import { t } from 'ttag';
import { ApplicationItem } from '../../../models/ApplicationItem';
import { PagedResult, PaginationModel } from '../../../models/PagedResult';
import { setColorsByDistinctId } from '../../../components/LiitGrid/SetColorsByDistinctId';
import { TableState } from '../../../components/LiitGrid/TableSortProps';
import { Tag } from '../../../models/Tag';
import { DebouncedTextInput } from '../../../components/DebouncedTextInput';
import { useApplicationQueryParameters } from '../../../hooks/useApplicationQueryParameters';

export const BORDER_COLOR = '#cbd0dd'; //gray[3]

export const useStyles = createStyles(() => ({
  filterGroup: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
}));

export const ApplicationsList: React.FC = ({ }) => {

  const navigate = useNavigate();
  const { classes, theme } = useStyles();
  const { selectedOrganisation } = useSession();
  const api = useApi();

  const [applications, setApplications] = useState<PagedResult<ApplicationItem> | undefined>(undefined);
  const [applicationPeriods, setApplicationPeriods] = useState<LiitDropdownMenuItem[] | null>(null);
  const [tags, setTags] = useState<Tag[]>();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [parameters, setParameters, defaultParameters] = useApplicationQueryParameters();

  const keyword = new URLSearchParams(useLocation().search).get('keyword');
  
  const setColorsbyFormId = (items: ApplicationItem[], periods: LiitDropdownMenuItem[]) => {
    items.forEach((application) => {
      const { applicationPeriodId } = application;
      const colorItem = periods.find((p) => p.id === applicationPeriodId);
      application.categoryColor = {
        color: colorItem?.color ?? 'white',
        inverted: colorItem?.inverted ?? 'black',
      };
    });
  };

  const initialize = async () => {
    const applicationPeriodsResponse = await api.getApplicationPeriods(selectedOrganisation!.id);
    const colors = setColorsByDistinctId(theme, applicationPeriodsResponse.map(x => x.id));
    const preiodsInList = applicationPeriodsResponse.map((x) => (
      {
        id: x.id,
        text: x.title,
        color: colors[x.id].color,
        inverted: colors[x.id].inverted,
      }));

    setApplicationPeriods(preiodsInList);

    const tagsResponse = await api.getTags(selectedOrganisation!.id);
    setTags(tagsResponse);

    setIsInitialized(true);
  };

  const fetchApplications = async () => {
    const response = await api.queryApplications(
      selectedOrganisation!.id,
      parameters,
    );

    const applicationList = response.data;
    const p = {} as PagedResult<any>;
    p.data = applicationList;
    p.pagination = {
      currentPage: parameters.page,
      totalCount: response.count,
      totalPages: Math.ceil(response.count / parameters.pageSize),
    } as PaginationModel;

    setApplications(p);    
  };

  useEffect(() => {
    const init = async () => {
      setIsFetching(true);

      await initialize();

      setIsFetching(false);
    };

    if (keyword) {
      const p = { ...defaultParameters, keyword: keyword };
      setParameters(p);
    }

    init();
  }, []);  

  useEffect(() => {

    if (applications && applications.data && applicationPeriods) {
      setColorsbyFormId(applications.data, applicationPeriods!);
    }
  }, [applications, applicationPeriods]);    

  useEffect(() => {

    const init = async () => {
      
      setIsFetching(true);
      fetchApplications();
      setIsFetching(false);
    };

    if (applicationPeriods && parameters) {
      init();
    }

  }, [isInitialized, parameters]);  

  return (
    <GrantMeContainer>
      <Stack spacing={0} style={{ flex: 1, overflow: 'hidden' }}>
        <GrantMeTitleContainer>
          <Title order={3}>{t`Ansökningar`}</Title>
        </GrantMeTitleContainer>
        <Group pl={42} className={classes.filterGroup} spacing={'xl'} h={48}>
          {/* <Text size={'sm'} weight={600}>{t`Period:`}</Text> */}
          <LiitDropdown
            emptyText={t`Alla perioder`}
            menuItems={applicationPeriods}
            onChange={(periodId) => {
              const p = { ...parameters, applicationPeriodId: periodId, page: 1 };
              setParameters(p);
            }}
            selectedId={parameters.applicationPeriodId}
            showColorBox={true}
          />
          <Divider orientation="vertical" />
          {/* <Text size={'sm'} weight={600}>{t`Status:`}</Text> */}
          <LiitDropdown
            emptyText={t`Alla statusar`}
            menuItems={[
              { id: '1', text: t`Inlämnade` },
              { id: '2', text: t`Nekade` },
              { id: '3', text: t`Beviljade` },
              { id: '4', text: t`Borttagna` },
            ]}
            onChange={(id) => {
              const p = { ...parameters, status: id ? parseInt(id) : null, page: 1 };
              setParameters(p);
            }}
            selectedId={parameters.status?.toString()}
            showColorBox={false}
          />

          {tags && tags.length > 0 && (
            <>
              <Divider orientation="vertical" />
              {/* <Text size={'sm'} weight={600}>{t`Kategorier:`}</Text> */}
              <LiitDropdown
                emptyText={t`Alla kategorier`}
                menuItems={tags.map(x => ({ id: x.id, text: x.name }))}
                onChange={(id) => {
                  const p = { ...parameters, tagId: id, page: 1 };
                  setParameters(p);
                }}
                selectedId={parameters.tagId}
                showColorBox={false}
              />
            </>
          )}
        <Divider orientation="vertical" />          
        <DebouncedTextInput
          delay={300}
          placeholder='Sök ...'
          defaultValue={parameters?.keyword}
          onChange={(text) => {
            const p = { ...parameters, keyword: text, page: 1 };
            setParameters(p);
          }}
        />       
        </Group>

        <Flex style={{ flex: 1, overflow: 'auto' }}>
          <LiitGrid
            data={applications}
            columnInfo={ApplicationsListColumns(tags && tags.length > 0 ? true : false)}
            emptyText={t`Vi kunde inte hitta några ansökningar.`}
            emptyIcon={IconMoodEmpty}
            onPageChange={(ts: TableState) => { 
              const p = { ...parameters, page: ts.page };
              setParameters(p);
            }}
            onSortChange={(ts: TableState) => {
              const p = { ...parameters, orderBy: ts.orderBy, isAscending: ts.isAscending };
              setParameters(p);
            }}
            onRowClick={(id: string) => {
              navigate(`/applications/${id}`);
            }}
            fetchingData={isFetching}
            isPrintable={true}
          />
        </Flex>
      </Stack>
    </GrantMeContainer>
  );
};
