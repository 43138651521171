import { LanguageCode } from '../models/LanguageCode';
import { TranslationItem } from '../models/TranslationItem';

export default class LanguageHandler {
  static getTextByLanguage = (texts: TranslationItem[] | undefined | null, languageCode: LanguageCode): string => {
    if (!texts || texts.length === 0) {
      return '';
    }

    return texts.find(x => x.language === languageCode)?.text ?? '';
  };
}