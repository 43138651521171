import {
  IconCalendar,
  IconCash,
  IconCheckbox,
  IconCircle,
  IconFile,
  IconFlag,
  IconRectangle,
  IconRowInsertBottom,
  IconSelect,
  IconTable,
} from '@tabler/icons';
import { t } from 'ttag';
import { ToolboxItem } from '../../FormBuilderToolbox/TooboxItem';
import { FormElementType } from '../../models/FormElementType';
import { TextBoxInputType } from '../TypedTextBox/TextBoxInputType';

const {
  TypedTextBox,
  TextArea,
  Dropdown,
  FileGroup,
  CheckboxGroup,
  RadioButtonGroup,
  DatePickerElement,
} = FormElementType;

export const repeaterTypedInputTypes: TextBoxInputType[] = [
  TextBoxInputType.Plain,
  TextBoxInputType.Email,
  TextBoxInputType.PhoneNumber,
  TextBoxInputType.Number,
];

export type RepeaterElements =
  FormElementType.TypedTextBox
  | FormElementType.TextArea
  | FormElementType.Dropdown
  | FormElementType.FileGroup
  | FormElementType.RadioButtonGroup
  | FormElementType.CheckboxGroup
  | FormElementType.DatePickerElement;

export const RepeaterToolBoxItems = [
  FormElementType.TypedTextBox,
  FormElementType.TextArea,
  FormElementType.Dropdown,
  FormElementType.FileGroup,
  FormElementType.RadioButtonGroup,
  FormElementType.CheckboxGroup,
  FormElementType.DatePickerElement,
];

export const RepeaterToolboxItemInformation: Record<RepeaterElements, ToolboxItem> = {
  [FormElementType.TypedTextBox]: {
    label: t`Textbox`,
    type: TypedTextBox,
    icon: <IconRectangle size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.TextArea]: {
    label: t`Textarea`,
    type: TextArea,
    icon: <IconRectangle size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.Dropdown]: {
    label: t`Dropdown`,
    type: Dropdown,
    icon: <IconSelect size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.FileGroup]: {
    label: t`Filuppladdning`,
    type: FileGroup,
    icon: <IconFile size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.RadioButtonGroup]: {
    label: t`Radioknappar`,
    type: RadioButtonGroup,
    icon: <IconCircle size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.CheckboxGroup]: {
    label: t`Checkbox`,
    type: CheckboxGroup,
    icon: <IconCheckbox size={14} />,
    mandatory: false,
    canResize: true,
  },
  [FormElementType.DatePickerElement]: {
    label: t`Datumväljare`,
    type: DatePickerElement,
    icon: <IconCalendar size={14} />,
    mandatory: false,
    canResize: true,
  },
};
