import { ValidationResult } from '../../models/ValidationResult';
import { validateAmountsTable } from './FormElements/AmountsTable/ValidateAmountsTable';
import { validateCheckboxGroup } from './FormElements/CheckboxGroup/ValidateCheckboxGroup';
import { validateContactPerson } from './FormElements/ContactPerson/ValidateContactPerson';
import { validateContactPersonOrganization } from './FormElements/ContactPersonOragnization/ValidateContactPersonOrganization';
import { validateDrowdown } from './FormElements/Dropdown/ValidateDropdown';
import { validateFilebox } from './FormElements/FileBox/ValidateFilebox';
import { validateIdentityNumberOrganization } from './FormElements/IdentityNumberOrganization/ValidateIdentityNumberOrganization';
import { validateIdentityNumberPerson } from './FormElements/IdentityNumberPerson/ValidateIdentityNumberPerson';
import { validateRadioButtonGroup } from './FormElements/RadioButtonGroup/ValidateRadioButtonGroup';
import { validateTextBox } from './FormElements/TextArea/ValidateTextArea';
import { validateTypedTextBox } from './FormElements/TypedTextBox/ValidateTypedTextBox';
import { validateWayOfPaymentElement } from './FormElements/WayOfPaymentElement/ValidateWayOfPaymentElement';
import { FormElement } from './models/FormElement';
import { FormElementType } from './models/FormElementType';

export const elementValidators: { [key in Exclude<FormElementType, FormElementType.Repeater | FormElementType.SectionTitle>]: (element: FormElement) => ValidationResult } = {
  [FormElementType.TextArea]: validateTextBox,
  [FormElementType.AmountsTable]: validateAmountsTable,
  [FormElementType.Dropdown]: validateDrowdown,
  [FormElementType.RadioButtonGroup]: validateRadioButtonGroup,
  [FormElementType.CheckboxGroup]: validateCheckboxGroup,
  [FormElementType.FileGroup]: validateFilebox,
  [FormElementType.TypedTextBox]: validateTypedTextBox,
  [FormElementType.DatePickerElement]: validateTextBox,
  [FormElementType.ContactPerson]: validateContactPerson,
  [FormElementType.ContactPersonOrganization]: validateContactPersonOrganization,
  [FormElementType.IdentityNumberPerson]: validateIdentityNumberPerson,
  [FormElementType.IdentityNumberOrganization]: validateIdentityNumberOrganization,
  [FormElementType.WayOfPayment]: validateWayOfPaymentElement,
};