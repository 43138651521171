import { ActionIcon, Group, Table, Text, Popover, Indicator } from '@mantine/core';
import { IconInfoCircle, IconReport } from '@tabler/icons';
import React from 'react';
import { Dashboard } from '../../../models/Dashboard';
import { DashboardCard } from './DashboardCard';
import Formatter from '../../../utils/formatter';
import { dashboardViewStyles } from '../DashboardViewStyles';
import { useDisclosure } from '@mantine/hooks';
import { ApplicationPeriodStatus } from '../../../models/ApplicationPeriodStatus';
import { HoverTooltip } from '../../../components/HoverTooltip';
import App from '../../../App';

interface ApplicationPeriodsCardProps {
  data: Dashboard;
}

export const ApplicationPeriodsCard: React.FC<ApplicationPeriodsCardProps> = ({ data }) => {
  const { classes } = dashboardViewStyles();
  const [opened, { close, open }] = useDisclosure(false);

  const getApplicationPeriods = () => {
    return data.applicationPeriods.filter(x => x.status === ApplicationPeriodStatus.Ongoing || x.status === ApplicationPeriodStatus.Upcoming);
  };

  const getContent = () => {
    if (getApplicationPeriods().length === 0) {
      return (
        <Text color="dimmed">
          Just nu finns det inga pågående eller kommande ansökningsperioder.
        </Text>
      );
    }

    return (
      <Table verticalSpacing={'xs'} className={classes.dashboardTable}>
        <thead>
          <tr>
            <th>Period</th>
            <th>Status</th>
            <th>Slutdatum</th>
            <th>Inlämnade</th>
            <th>
              <HoverTooltip content="Avser antalet ansökningar som är påbörjade av sökanden men ännu ej inlämnade.">
                <Group>
                  Påbörjade{' '}
                  <ActionIcon
                    color="blue.6"
                    variant="transparent"
                    size={22}
                    onMouseEnter={open}
                    onMouseLeave={close}>
                    <IconInfoCircle />
                  </ActionIcon>
                </Group>
              </HoverTooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          {getApplicationPeriods().map((period) => {
            return (
              <tr key={'ongoingperiodscard_' + period.id}>
                <td>
                  <Text weight={500}>{period.title}</Text>
                </td>

                <td>
                  {period.status === ApplicationPeriodStatus.Ongoing && (
                    <Indicator offset={-12} color={'green'} position="middle-start">
                      <Text color="gray.7">Pågående</Text>
                    </Indicator>
                  )}
                  {period.status === ApplicationPeriodStatus.Upcoming && (
                    <Indicator offset={-12} color={'gray'} position="middle-start">
                      <Text color="gray.7">Kommande</Text>
                    </Indicator>
                  )}
                </td>
                <td>{Formatter.formatDateString(period.endDate)}</td>
                <td>
                  {period.numberOfSubmittedApplications +
                    period.numberOfApprovedApplications +
                    period.numberOfDeclinedApplications}{' '}
                  st
                </td>
                <td>{period.numberOfDraftApplications} st</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <DashboardCard title="Aktuella ansökningsperioder" icon={IconReport} content={getContent()} />
  );
};
