import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Group,
  Loader,
  Select,
  Space,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { v4 as uuidv4 } from 'uuid';
import { IconPlus, IconSettings, IconTrash } from '@tabler/icons';
import { useForm } from '@mantine/form';
import { FormPageBuilder } from './FormPageBuilder';
import { FormPage } from './models/FormPage';
import { FormModel } from './models/FormModel';
import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { countAndValidateElements } from './CountElements';
import { Counters } from './ElementCount';
import { t } from 'ttag';
import { isMissing } from 'utilitype';
import { reorderArray } from '../../utils/reorderArray';
import { LiitActionIcon } from '../LiitActionIcon/LiitActionIcon';
import { ApplicantType } from '../../models/ApplicantType';
import { TranslationItem } from '../../models/TranslationItem';
import { LanguageCode } from '../../models/LanguageCode';
import LanguageHandler from '../../utils/languageHandler';
import { FormElementType } from './models/FormElementType';
import { ToolboxSetting, ToolBoxSetup } from './FormBuilderToolbox/ToolboxItemSettings';
import { TextBoxInputType } from './FormElements/TypedTextBox/TextBoxInputType';
import { LiitDrawer } from '../LiitDrawer/LiitDrawer';
import { INPUT_MAX_LENGTH } from '../../App';
import { PaymentOptions } from '../../models/PaymentOptions';

interface FormBuilderProps {
  applicationPeriod: ApplicationPeriod;
  json: string;
  onChanged: (json: string) => void;
  setFormStatus: React.Dispatch<React.SetStateAction<string[]>>;
}

interface FormValues {
  id: string;
  label: TranslationItem[];
}

const getToolBox = (applicantType: ApplicantType, paymentOption: PaymentOptions): ToolBoxSetup => {
  let inputTypes: TextBoxInputType[] = [
    TextBoxInputType.ContactPersonEmail,
    TextBoxInputType.ContactPersonPhoneNumber,
    TextBoxInputType.Plain,
    TextBoxInputType.Email,
    TextBoxInputType.PhoneNumber,
    TextBoxInputType.Title,
    TextBoxInputType.Number,
  ];

  if (paymentOption !== PaymentOptions.NoPayment) {
    inputTypes.push(TextBoxInputType.RequestedAmount);
  }

  const elementTypes: FormElementType[] = [
    FormElementType.TypedTextBox,
    FormElementType.TextArea,
    FormElementType.Dropdown,
    FormElementType.FileGroup,
    FormElementType.CheckboxGroup,
    FormElementType.RadioButtonGroup,
    FormElementType.DatePickerElement,
    FormElementType.AmountsTable,
    FormElementType.SectionTitle,
    FormElementType.Repeater,
  ];

  if (paymentOption === PaymentOptions.Default) {
    elementTypes.push(FormElementType.WayOfPayment);
  }

  if (applicantType === ApplicantType.Person) {
    elementTypes.push(FormElementType.ContactPerson);
    elementTypes.push(FormElementType.IdentityNumberPerson);

    const extraInput: TextBoxInputType[] = [
      TextBoxInputType.ApplicantFirstName,
      TextBoxInputType.ApplicantLastName,
    ];

    inputTypes = extraInput.concat(inputTypes);
  }

  if (applicantType === ApplicantType.Organization) {
    elementTypes.push(FormElementType.ContactPersonOrganization);
    elementTypes.push(FormElementType.IdentityNumberOrganization);

    const extraInput: TextBoxInputType[] = [TextBoxInputType.ApplicantOrganizationName];

    inputTypes = extraInput.concat(inputTypes);
  }

  return { elementTypes: elementTypes, inputTypes: inputTypes };
};

export const FormBuilder: React.FC<FormBuilderProps> = ({
  json,
  onChanged,
  applicationPeriod,
  setFormStatus,
}) => {
  const [editForm, setEditForm] = useState<FormModel | null>(null);
  const [propertyEditorOpened, setPropertyEditorOpened] = useState(false);
  const [counters, setCounters] = useState<Counters>({
    elements: [],
    inputTypes: [],
    missingElements: [],
  });
  const [toolBox, setToolBox] = useState<ToolBoxSetup>();

  const form = useForm<FormValues>({
    initialValues: {
      id: '',
      label: [{ language: LanguageCode.sv, text: '' }],
    },
  });

  useEffect(() => {
    setToolBox(getToolBox(applicationPeriod.applicantType, applicationPeriod.paymentOption));
  }, []);

  useEffect(() => {
    if (json && toolBox) {
      const result: FormModel = JSON.parse(json);
      const countAndValidation = countAndValidateElements(result.pages, toolBox);
      setCounters(countAndValidateElements(result.pages, toolBox));

      const missingElements = countAndValidation.missingElements;
      setFormStatus(missingElements);

      setEditForm(result);
    }
  }, [json, toolBox]);

  if (isMissing(editForm)) {
    return <Loader />;
  }

  const onPageUpdated = () => {
    onChanged(JSON.stringify(editForm, null, 4));
  };

  const addPage = () => {
    editForm.pages.push({
      id: uuidv4(),
      label: [{ language: LanguageCode.sv, text: '' }],
      elements: [],
    } as FormPage);
    onChanged(JSON.stringify(editForm, null, 4));
  };

  const removePage = (page: FormPage) => {
    editForm.pages = editForm.pages.filter((p) => p.id !== page.id);
    onChanged(JSON.stringify(editForm, null, 4));
  };

  const editPage = (page: FormPage) => {
    form.setValues(page);
    setPropertyEditorOpened(true);
  };

  const updatePage = () => {
    const index = editForm.pages.findIndex((x) => x.id === form.values.id);
    editForm.pages[index].label = form.values.label; //KOLLA OM DETTA FUNKAR
    setPropertyEditorOpened(false);
    onChanged(JSON.stringify(editForm, null, 4));
  };

  const renderPage = (page: FormPage, pageIndex: number, questionNumber: number): JSX.Element => {
    let label = LanguageHandler.getTextByLanguage(page.label, LanguageCode.sv);

    if (!label) {
      const pageNumber = pageIndex + 1;
      label = t`Sida ${pageNumber}`;
    }

    const elements = (
      <Stack key={`page_${pageIndex}}`}>
        <Group>
          <Title order={4}>{label}</Title>
          <Select
            onChange={(val) => {
              reorderArray(editForm.pages, pageIndex, Number(val));
              onChanged(JSON.stringify(editForm, null, 4));
            }}
            style={{ width: 50 }}
            size={'xs'}
            data={editForm.pages.map((_, index) => ({ value: `${index}`, label: `${index + 1}` }))}
            value={pageIndex.toString()}
          />
          <LiitActionIcon
            tooltip={t`Inställningar`}
            icon={<IconSettings size={16} />}
            variant="subtle"
            onClick={() => editPage(page)}
          />
          <LiitActionIcon
            tooltip={t`Ta bort`}
            icon={<IconTrash size={16} />}
            variant="subtle"
            onClick={() => removePage(page)}
          />
        </Group>
        <FormPageBuilder
          page={page}
          questionNumber={questionNumber}
          onPageUpdated={onPageUpdated}
          toolbox={{ counters, ...toolBox } as ToolboxSetting}
        />
      </Stack>
    );

    questionNumber += page.elements.length;

    return elements;
  };

  const renderPages = (): JSX.Element[] => {
    const questionNumber = 1;

    return editForm.pages.map((page, pageIndex) => renderPage(page, pageIndex, questionNumber));
  };

  return (
    <>
      <Stack>
        {renderPages()}
        <Space />
        <Button onClick={() => addPage()} leftIcon={<IconPlus />}>
          {t`Lägg till sida`}
        </Button>
      </Stack>

      <LiitDrawer
        opened={propertyEditorOpened}
        onClose={() => setPropertyEditorOpened(false)}
        size={'xl'}>
        <Flex direction={'column'} w={'100%'}>
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            <form>
              <TextInput  label={t`Sidnamn`} {...form.getInputProps('label.0.text')} maxLength={INPUT_MAX_LENGTH}/>
            </form>
          </Box>
          <Group position={'right'} pt={'xl'}>
              <Button onClick={() => updatePage()}>{t`Spara`}</Button>
              <Button onClick={() => setPropertyEditorOpened(false)}>{t`Avbryt`}</Button>
            </Group>
        </Flex>
      </LiitDrawer>
    </>
  );
};
