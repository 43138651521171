import { Stack, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { UnclaimedListColumns } from './UnclaimedListColumns';

export const UnclaimedList: React.FC = () => {
  const [payments, setPayments] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { selectedOrganisation } = useSession();
  const navigate = useNavigate();
  const api = useApi();

  const refreshPayments = async () => {
    const result = await api.getUnclaimedPayments(selectedOrganisation!.id);
    setPayments(result);
  };

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);

      await refreshPayments();

      setIsFetching(false);
    };

    if (selectedOrganisation) {
      fetchData();
    }
  }, [selectedOrganisation]);

  return (
    <>
      <GrantMeContainer>
        <Stack spacing={0}>
          <GrantMeTitleContainer>
            <Title order={3}>Ej rekvirerade</Title>
          </GrantMeTitleContainer>


          <LiitGrid
            data={payments.filter((x) => x.claimStatus === 1)}
            columnInfo={UnclaimedListColumns()}
            onRowClick={(id: string) => {
              const payment = payments.find((x) => x.id === id);
              navigate(`/applications/${payment.applicationId}`);
            }}
            highligthOnHoover
            fetchingData={isFetching}
          />

        </Stack>
      </GrantMeContainer>
    </>
  );
};
