import { ReactNode } from 'react';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { Text } from '@mantine/core';
import { formatValue } from '../../../components/LiitGrid/FormatValue';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';

export const RenderAmountCell: RenderCell = (rowInformation: CellRenderInformation): ReactNode => {
  const { cellValue, rowValues } = rowInformation;

  return (
    <>
      <Text size="sm">{formatValue(cellValue, PropertyType.Currency)}</Text>
      {rowValues.suggestedAmount ? (
        <Text size="sm" color="dimmed">
          Ditt förslag: {formatValue(rowValues.suggestedAmount, PropertyType.Currency)}
        </Text>
      ) : (
        ''
      )}
    </>
  );
};
