import { isMissing } from 'utilitype';
import { Counters, ElementCount, InputTypeCount } from './ElementCount';
import { ToolboxItems } from './FormBuilderToolbox/ToolBoxItems';
import { ToolBoxSetup } from './FormBuilderToolbox/ToolboxItemSettings';
import { TextBoxInputType } from './FormElements/TypedTextBox/TextBoxInputType';
import { TypedTextBoxTypeItems } from './FormElements/TypedTextBox/TypedTextBoxTypeItems';
import { FormElementType } from './models/FormElementType';
import { FormElementTypedTextBox } from './models/FormElementTypedTextBox';
import { FormPage } from './models/FormPage';

export const countAndValidateElements = (pages: FormPage[], toolBox: ToolBoxSetup):Counters => {
  const elements = pages.flatMap(page => page.elements.map(element => element) );

  const allTypedTextBoxes = pages.flatMap(page => page.elements.filter(element => element.type === FormElementType.TypedTextBox) as FormElementTypedTextBox[] );
  const inputTypes = toolBox.inputTypes.map(type => {
    const count = allTypedTextBoxes.filter(textBox => textBox.inputType === type).length;
    const textBoxInfo = TypedTextBoxTypeItems[type];

    const isValid = !textBoxInfo.mandatory || count > 0;

    return { type: type, count: count, isValid: isValid } as InputTypeCount;
  });


  const formElements = toolBox.elementTypes.map(type => {
    const count = elements.filter(element => element.type === type).length;
    const elementInfo = ToolboxItems[type];

    const isValid = !elementInfo.mandatory || count > 0;

    return { type: type, count: count, isValid: isValid } as ElementCount;
  });

  const missingTypes = inputTypes.filter(x => x.isValid === false).map(x => { 
    const label = TypedTextBoxTypeItems[x.type].label;
    return `Textbox - ${label}`;
  });

  const missingElements = formElements.filter(x => x.isValid === false).map(x => ToolboxItems[x.type].label);

  const result: Counters = { elements: formElements, inputTypes: inputTypes, missingElements: [...missingTypes, ...missingElements] };

  return result;
};
