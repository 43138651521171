import { isMissing, isPresent } from 'utilitype';
import { ValidationResult } from '../../../../models/ValidationResult';
import { ErrorMessages } from '../../../../utils/errorMessages';
import { FormElement } from '../../models/FormElement';

export const validateFilebox = (element: FormElement): ValidationResult => { 
  const { required } = element;
  if (required && isMissing(element.value)) {
    return { isValid: false, errorMessage: ErrorMessages.IS_REQUIRED };
  }

  return { isValid: true, errorMessage: '' };
};