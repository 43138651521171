export const dashSpaceAndNumber = (value: string) => {
  return value.replace(/[^\d\s-]/g, '').replace(/\s{2,}/, ' ').replace(/\-{2,}/, '-').replace(/\-\s/, '-').replace(/\ \-/, ' ');
};

export const spaceAndNumber = (value: string) => {
  return value.replace(/[^\d\s]/g, '').replace(/\s{2,}/, ' ');
};

export const formatClearingNumber = (value: string) => {
  return value.replace(/[^0-9]+/g, '').substring(0, 5);
};

export const dashSpaceAndNumberCleanUp = (value: string) => {
  const trimmed = value.trim();

  const removeLastChar = trimmed && isNaN(Number(trimmed[trimmed.length - 1]));
  return removeLastChar ? trimmed.slice(0, -1) : trimmed;
};

export const formatIdentityNumberSwedish = (value: string) => {
  const val =  value.replace(new RegExp('[-]*', 'g'), '');
  if (!val) {
    return '';
  }          

  let checkVal = val;
  const mostRecent = Number(val[val.length - 1]);

  if (Number.isNaN(mostRecent) || checkVal.length > 12) {
    checkVal = checkVal.substring(0, checkVal.length - 1);
  }

  const num = Number(checkVal);

  if (Number.isNaN(num) || !checkVal) {
    return '';
  }

  return checkVal;
};

export const formatOrganizationNumberSwedish = (value: string) => {
  const val =  value.replace(new RegExp('[-]*', 'g'), '');
  if (!val) {
    return '';
  }          

  let checkVal = val;
  const mostRecent = Number(val[val.length - 1]);

  if (Number.isNaN(mostRecent) || checkVal.length > 10) {
    checkVal = checkVal.substring(0, checkVal.length - 1);
  }

  const num = Number(checkVal);

  if (Number.isNaN(num) || !checkVal) {
    return '';
  }

  return checkVal;
};