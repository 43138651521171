import React from 'react';
import { Button } from '@mantine/core';
import { Application } from '../../../models/Application';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { ApplicationStatus } from '../../../models/ApplicationStatus';

interface ApplicationToolbarItemReportProps {
  application: Application;
  onChanged: () => void;
}

export const ApplicationToolbarItemReport: React.FC<ApplicationToolbarItemReportProps> = ({
  application,
  onChanged,
}) => {
  const { selectedOrganisation } = useSession();
  const api = useApi();

  const approveApplicationReport = async () => {
    await api.updateApplicationDecision(selectedOrganisation!.id, application.id, {
      decision: ApplicationStatus.Approved,
    });

    await onChanged();
  };

  return (
    <>
      {application.status === ApplicationStatus.Submitted && (
        <Button onClick={() => approveApplicationReport()}>Godkänn återrapport</Button>
      )}
    </>
  );
};
