import { Input, TextInput } from '@mantine/core';
import { BaseSyntheticEvent, FC } from 'react';
import { t } from 'ttag';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { formatOrganizationNumberSwedish } from '../../../../utils/textFormatters';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';
import { FormLabel } from '../Common/FormLabel';
import { ViewerIdentityNumberPerson } from './ViewerIdentityNumberOrganization';

type IdentityNumberOrganizationProps = {
  element: FormElement;
} & FormElementComponentProps;

export const IdentityNumberOrganization: FC<IdentityNumberOrganizationProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerIdentityNumberPerson number={number} element={element} />;
  }

  const { description, required, hideLabel } = element;

  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);

  return (
    <TextInput
      label={hideLabel ? undefined : <FormLabel element={element} mode={mode} position={'apart'} />}
      description={descriptionText}
      error={error}
      required={required}
      value={element.value as string}
      onInput={(event: BaseSyntheticEvent<InputEvent>) => {
        const { value } = event.target;

        event.target.value = formatOrganizationNumberSwedish(value);
      }}
      onChange={(event) => {
        if (onChange) {
          onChange(event.currentTarget.value);
        }
      }}
    />
  );
};
