import { MantineTheme, useMantineTheme } from '@mantine/core';
import { ApplicationReviewStatus as Status } from '../../../models/ApplicationReviewStatus';
import { StatusInformation } from './StatusInformation';

export const ReviewStatuses = (theme: MantineTheme): Record<Status, StatusInformation> => {
  return {
    [Status.Completed]: {
      title: 'Klarmarkerad',
      color: theme.colors.green[5],
      colorKey: 'green.5',
    },
    [Status.NotStarted]: {
      title: 'Ej påbörjad',
      color: theme.colors.gray[5],
      colorKey: 'gray.5',
    },
    [Status.Ongoing]: {
      title: 'Pågående',
      color: theme.colors.yellow[5],
      colorKey: 'yellow.5',
    },
    [Status.Biased]: {
      title: 'Jävig',
      color: theme.colors.red[3],
      colorKey: 'red.3',
    },
  };
};
