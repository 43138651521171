import { useMsal } from '@azure/msal-react';
import React from 'react';

export const LogoutView: React.FC = () => {
  const { instance } = useMsal();

  instance.logoutRedirect();

  return <></>;
};
