export const getPrintStyle = (landscape: boolean | undefined) => {
  return `

  @page {
    margin: ${landscape ? '1cm 2cm 1cm 2cm !important' : '2cm !important'} ;
    padding: 0cm;
    size: ${landscape ? 'A4 landscape' : '210mm 297mm'};
  }

  html, body {
    margin: 0 !important;
    margin-top: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  body {
    font-family: Arial;
    font-size: 10pt;
    line-height: 1.3;
    background: #fff !important;
    color: #000;    
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    text-align: left;
    padding: 4px !important;
    border-bottom: 1px solid #000000;
    font-weight: bold;
  }

  td {
    padding: 4px !important;
    text-align: left;
  }

  h1, h2 {
    font-size: 18pt;
    padding-bottom: 16px;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  h3 {
    font-size: 15pt;
    padding-bottom: 16px;
    margin-bottom: 0px; 
  }

  h4, h5, h6 {
    font-size: 13pt;
    margin-top: 32px !important;
    margin-bottom: 16px !important;
  }



  button {
    display:none;
  }  

  .nowrap {
    white-space: nowrap;
  }
  
  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }

  .summary-row {
    display: flex;
    justify-content: space-between;
  }


  .woho div:nth-of-type(even) {     
    padding-bottom: 16px !important; 
  }

  .woho div:nth-of-type(odd) {     
      font-weight:bold;    
  }

  .print-hide {
    display:none !important;
  }
`;
};
