/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const UnclaimedListColumns = (): Array<IDisplayColumn> => [
  {
    displayName: 'Diarienr',
    propertyName: 'applicationNumber',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles },
    hideFromPrint: true,
  },
  {
    displayName: 'Mottagare',
    propertyName: 'applicantName',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Titel',
    propertyName: 'applicationTitle',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, width: 'auto' },
  },
  {
    displayName: 'Period',
    propertyName: 'applicationPeriodTitle',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, width: 'auto' },
  },
  {
    displayName: 'Belopp',
    propertyName: 'amount',
    propertyType: PropertyType.Currency,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap', width: '1%' },
  },
];
