import { Text } from '@mantine/core';
import { FC } from 'react';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { FormElement } from '../../models/FormElement';

type ViewerInputWrapperProps = (ViewerFormElementComponentProps | FormElementComponentProps) & {
  element: FormElement;
  children: React.ReactNode;
};

export const ViewerInputWrapper: FC<ViewerInputWrapperProps> = ({ element, children }) => {
  const { label, hideLabel, labelAndTextPosition } = element as FormElement;

  const text = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);
  return (
    <>
      {!hideLabel && (
        <Text align={labelAndTextPosition === 'right' ? 'end' : 'start'} size="sm" weight="bold" pb="0" mb="0">{`${text?.trim() ?? '...'}`}</Text>
      )}
      {children}
    </>
  );
};
