import { Text, Stack, Title, Button, Input, Grid, Badge, Box } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'ttag';
import { isMissing } from 'utilitype';
import { formatValue } from '../../../components/LiitGrid/FormatValue';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import { LiitNumberInput } from '../../../components/LiitNumberInput/LiitNumberInput';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { Application } from '../../../models/Application';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { PaymentOptions } from '../../../models/PaymentOptions';
import Formatter from '../../../utils/formatter';

interface ApplicationToolbarItemDecisionProps {
  application: Application;
  onDecision: (values: any) => void;
}

export const ApplicationToolbarItemDecision: React.FC<ApplicationToolbarItemDecisionProps> = ({
  application,
  onDecision,
}) => {
  const { applicationId } = useParams();
  const { selectedOrganisation } = useSession();
  const api = useApi();

  const form = useForm({
    initialValues: {
      grantedAmount: undefined,
      decision: 0,
    },
  });

  const save = async (values) => {
    if (!selectedOrganisation || !applicationId) {
      return;
    }

    const decision = {
      decision: form.values.decision,
      grantedAmount: form.values.grantedAmount,
    };

    await api.updateApplicationDecision(selectedOrganisation.id, applicationId, decision);
    onDecision(decision);
    form.reset();

  };

  const buttons = () => {
    if (application.status === ApplicationStatus.Submitted) {
      return (
        <Grid gutter={'xs'}>
          <Grid.Col span={6}>
            <Button
              fullWidth
              color={'red.4'}
              type="submit"
              onClick={() => {
                form.values.decision = ApplicationStatus.PreliminaryDeclined;
              }}>
              Neka
            </Button>
          </Grid.Col>
          <Grid.Col span={6}>
            <Button
              fullWidth
              color={'green.5'}
              type="submit"
              disabled={
                application.paymentOption !== PaymentOptions.NoPayment &&
                (isMissing(form.values.grantedAmount) || form.values.grantedAmount === 0)
              }
              onClick={() => {
                form.values.decision = ApplicationStatus.PreliminaryApproved;
              }}>
              Bevilja
            </Button>
          </Grid.Col>
        </Grid>
      );
    } else if (
      application.status === ApplicationStatus.PreliminaryApproved ||
      application.status === ApplicationStatus.PreliminaryDeclined
    ) {
      return (
        <>
          <Button
            fullWidth
            variant={'outline'}
            type="submit"
            onClick={() => {
              form.values.decision = ApplicationStatus.Submitted;
              form.values.grantedAmount = undefined;
            }}>
            Ångra
          </Button>
        </>
      );
    } else if (
      application.status === ApplicationStatus.Approved ||
      application.status === ApplicationStatus.Declined
    ) {
      <>Ett beslut har fattats</>;
    }
  };

  const inputs = () => {
    if (
      application.status === ApplicationStatus.PreliminaryApproved ||
      application.status === ApplicationStatus.Approved
    ) {
      return (
        <>
          <Badge radius={4} h={42} size={'lg'} color={'green.5'}>{t`Beviljad`}</Badge>
          {application.paymentOption !== PaymentOptions.NoPayment && (
            <Input.Wrapper label="Beviljat belopp">
              <Text size={'sm'} weight={600}>
                {Formatter.formatCurrency(application.grantedAmount)}
              </Text>
            </Input.Wrapper>
          )}
        </>
      );
    }

    if (
      application.status === ApplicationStatus.PreliminaryDeclined ||
      application.status === ApplicationStatus.Declined
    ) {
      return <Badge radius={4} h={42} size={'lg'} color={'red.3'}>{t`Nekad`}</Badge>;
    }

    if (application.paymentOption !== PaymentOptions.NoPayment) {
      return (
        <>
          <LiitNumberInput
            placeholder="Belopp"
            label="Belopp:"
            hideControls
            {...form.getInputProps('grantedAmount')}
          />
          <Text size="xs">
            Sökt belopp: <b>{formatValue(application.requestedAmount, PropertyType.Currency)}</b>
          </Text>
        </>
      );
    }
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => save(values))}>
        <Stack>
          {inputs()}
          {buttons()}
        </Stack>
      </form>
    </Box>
  );
};
