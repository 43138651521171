import { Text } from '@mantine/core';
import { ReactNode } from 'react';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';

export const RenderApplicantCell: RenderCell = (
  rowInformation: CellRenderInformation,
): ReactNode => {
  
  const { cellValue, rowValues } = rowInformation;

  return (
  <>
    <Text size="sm">{cellValue}</Text>
    <Text size="sm" color="dimmed" style={{ whiteSpace: 'nowrap' }}>
      {rowValues.applicantEmail}
    </Text>
  </>
  );
};
