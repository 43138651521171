import { Group, Text, useMantineTheme } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons';
import { FC } from 'react';
import { t } from 'ttag';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';

export const ViewerIdentityNumberPerson: FC<ViewerFormElementComponentProps> = ({
  number,
  element,
}) => {
  return (
    <ViewerInputWrapper number={number} element={element}>
      <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
        {element.value}
      </Text>
    </ViewerInputWrapper>
  );
};
