import { t } from 'ttag';
import { ValidationResult } from '../../../../models/ValidationResult';
import { elementValidators } from '../../ElementValidators';
import { FormElement } from '../../models/FormElement';
import { RepeaterValidationResult } from './repeaterValidationResult';

export const validateRepeater = (element: FormElement): RepeaterValidationResult => { 
  const result: RepeaterValidationResult = { validationElements: [], errorMessage: '', isValid: true };

  const groups = element.value as FormElement[][];

  if (element.required && groups.length === 0) {
    result.errorMessage = t`Det måste finnas minst en rad`;
    result.isValid = false;
    return result;
  }

  if (groups.length === 0) {
    result.isValid = true;
    return result;
  }

  groups.forEach((group, groupIndex) => {
    group.forEach((groupElement, elementIndex ) => {
      const validator = elementValidators[groupElement.type];
      const elementResult = validator(groupElement) as ValidationResult;

      result.validationElements.push({ validationResult: elementResult, groupIndex: groupIndex, elementIndex: elementIndex });
    });
  });

  return result;
};