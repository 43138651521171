import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Box, Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'ttag';
import { isPresent } from 'utilitype';
import { FormElementMode } from '../../components/formbuilder/FormElementMode';
import { FormRunner } from '../../components/formbuilder/FormRunner';
import { ApplicantFormFields } from '../../components/formbuilder/models/ApplicantFormFields';
import { FormFileDelete } from '../../components/formbuilder/models/FormFileDelete';
import { FormFileUpload } from '../../components/formbuilder/models/FormFileUpload';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { ApplicationDetails } from '../../models/ApplicationDetails';
import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { UploadedFile } from '../../models/UploadedFile';
import { showFailNotification, showSuccessNotification } from '../../utils/notificationHelper';

interface ManualApplicationProps {
  applicationPeriod: ApplicationPeriod;
  onComplete: () => void;
}

export const ManualApplication: React.FC<ManualApplicationProps> = ({
  applicationPeriod,
  onComplete,
}) => {
  const { applicationPeriodId } = useParams();
  const { applicationId } = useParams();
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [application, setApplication] = useState<ApplicationDetails>();

  useEffect(() => {
    const fetchData = async () => {
      if (selectedOrganisation) {
        if (applicationPeriodId) {
          const response = await api.importSingelApplicationCreate(
            selectedOrganisation.id,
            applicationPeriodId,
          );
          setApplication(response);
        }
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [selectedOrganisation, isAuthenticated]);

  const complete = async (data: string) => {
    if (isPresent(selectedOrganisation) && isPresent(application)) {
      try {
        await api.importSingleApplicationSubmit(
          selectedOrganisation.id,
          application.id,
          JSON.parse(data),
        );

        showSuccessNotification(t`Ansökan skapad`, t`En manuell ansökan har skapats`);
        onComplete();
      } catch {
        showFailNotification(t`Något gick fel vid sparning`, t`Kunde inte spara ansökan`);
      }
    }
  };

  const onFileUpload = async (fileUpload: FormFileUpload): Promise<UploadedFile | undefined> => {
    if (selectedOrganisation && application) {
      const response = await api.uploadFile(selectedOrganisation.id, application.id, fileUpload);

      if (response.ok) {
        return response.json();
      }

      throw new Error('Unable to upload file');
    }
  };

  const onFileDelete = async (fileDelete: FormFileDelete) => {
    const { elementId, deleteComplete } = fileDelete;
    if (selectedOrganisation && application) {
      await api.deleteUploadedFile(selectedOrganisation.id, application.id, elementId);

      deleteComplete();
    } else {
      throw new Error('No organisation or application');
    }
  };

  if (!application) {
    return <Loader />;
  }

  return (
    <Box w={'100%'}>
      <FormRunner
        runnerMode={FormElementMode.Runner}
        application={application}
        json={JSON.stringify(application.json)}
        onCompleted={complete}
        onFileUpload={onFileUpload}
        onFileDelete={onFileDelete}
        applicationPeriod={applicationPeriod}
      />
    </Box>
  );
};
