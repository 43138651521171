import { FC, useEffect, useRef, useState } from 'react';
import { LiitDrawer, SessionStorageKeys } from '../LiitDrawer/LiitDrawer';
import { useSessionStorage } from '@mantine/hooks';
import { PDFViewer } from '../PdfViewer/PDFViewer';
import { ImageViewer } from '../ImageViewer/ImageViewer';
import { useSession } from '../../hooks/useSession';
import { toBlob } from '../../utils/toBlob';
import { useApi } from '../../hooks/useApi';
import { isEmpty, isMissing, isPresent } from 'utilitype';
import { ActionIcon, Group, Text, Tooltip, useMantineTheme, Image, Stack, Title } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconDownload, IconPaint, IconPhoto, IconX } from '@tabler/icons';
import { AttachmentDisplayFormat } from '../../models/AttachmentDisplayFormat';
import { AttachmentListItem } from '../../models/AttachmentListItem';
import { AttachmentType } from '../../models/AttachmentType';
import { motion } from 'framer-motion';
import { t } from 'ttag';
import { download } from '../../utils/download';
import { PDFViewerControls } from '../PdfViewer/PDFViewerControls';
import cls from 'classnames';
import { useStyles } from './Styles';

type LiitAttachmentDrawerProps = {
  attachmentsList: AttachmentListItem[];
};

const PADDING_SPACE = 24;
const DRAWER_WIDTH = 800 + PADDING_SPACE;
const PDF_CONTENT_TYPE = 'application/pdf';

const ZOOM_STEP = 0.2;
const ZOOM_MIN = 1.0;
const ZOOM_MAX = 2.6;
const HIDE_BUTTONS_TIMER = 1500;
const BUTTON_SIZE = 'lg';

export const LiitAttachmentDrawer: FC<LiitAttachmentDrawerProps> = ({ attachmentsList }) => {
  const api = useApi();
  const { selectedOrganisation } = useSession();
  const [activeAttachment, setActiveAttachment] = useSessionStorage<string>({
    key: SessionStorageKeys.ActiveImage,
    defaultValue: '',
  });

  const [activeItem, setActiveItem] = useState<AttachmentListItem | undefined>(undefined);
  const [showButtons, setShowButtons] = useState(true);
  const [mouseOverButton, setMouseOverButton] = useState(false);
  const [scale, setScale] = useState(1.0);
  const [rotation, setRotation] = useState(0);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    if (!!activeAttachment && attachmentsList.some((x) => x)) {
      setActiveItem(attachmentsList.find((x) => x.id == activeAttachment));
      setScale(1);
      setRotation(0);
    }
  }, [activeAttachment]);

  const getDownloadData = async (): Promise<string | null> => {
    if (activeItem?.id && selectedOrganisation) {
      const arrayBuffer = await api.downloadApplicationFile(selectedOrganisation.id, activeItem.id);

      return URL.createObjectURL(toBlob(new Uint8Array(arrayBuffer), PDF_CONTENT_TYPE));
    }

    return null;
  };

  const triggerDownload = async () => {
    if (!activeItem || !activeItem.fileName) {
      return;
    }

    const downloadData = await getDownloadData();

    if (isPresent(downloadData)) {
      await download(downloadData, activeItem.fileName);
    }
  };

  const getNextAttachment = () => {
    if (isMissing(activeItem)) {
      return;
    }

    const activeIndex = attachmentsList.indexOf(activeItem);
    const nextItem =
      attachmentsList.length - 1 > activeIndex
        ? attachmentsList[activeIndex + 1]
        : attachmentsList[0];

    setActiveAttachment(nextItem.id);
  };

  const getPreviousAttachment = () => {
    if (isMissing(activeItem)) {
      return;
    }

    const activeIndex = attachmentsList.indexOf(activeItem);
    const previousItem =
      activeIndex === 0
        ? attachmentsList[attachmentsList.length - 1]
        : attachmentsList[activeIndex - 1];

    setActiveAttachment(previousItem.id);
  };

  const navigate = (direction: string) => {
    if (direction === 'next') {
      getNextAttachment();
    } else if (direction === 'previous') {
      getPreviousAttachment();
    }
  };

  useEffect(() => {
    timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);
    return () => clearTimeout(timer.current ?? undefined);
  }, []);

  const mouseMove = (e) => {
    clearTimeout(timer.current ?? undefined);
    setShowButtons(true);
    if (!mouseOverButton) {
      timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);
    }
  };

  const touchMe = (e) => {
    clearTimeout(timer.current ?? undefined);
    setShowButtons(true);
    timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);
  };

  const getImage = (attachment: AttachmentListItem) => {
    const url = attachment.urls.find((x) => x.format === AttachmentDisplayFormat.Compressed)?.url;
    return <Image withPlaceholder={!url} src={url} placeholder={<Stack align="center">
      <IconPhoto stroke={1} size={128} />
      <Title color="dimmed" order={4}>Vi kunde tyvärr inte hitta bilden</Title>
      </Stack>} p={24}/>;
  };

  return (
    <LiitDrawer
      opened={isPresent(activeAttachment)}
      onClose={() => setActiveAttachment('')}
      padding={0}
      size={DRAWER_WIDTH}>
      <div onMouseMove={mouseMove} onTouchStart={touchMe} className={classes.pdfWrapper}>
        {activeItem?.type === AttachmentType.Pdf && (
          <PDFViewer
            scale={scale}
            rotation={rotation}
            navigate={attachmentsList.length > 1 ? navigate : undefined}
            getData={getDownloadData}
            filename={activeItem.fileName}
            pdfId={activeItem.id}
          />
        )}
        {activeItem?.type === AttachmentType.Image && (
          <Stack h={'100%'} align='center' justify='center'>
            {getImage(activeItem)}
          </Stack>
        )}
        <motion.div
          animate={showButtons ? 'show' : 'hidden'}
          variants={{
            show: { opacity: 1, transition: { duration: 0.1 } },
            hidden: { opacity: 0, transition: { duration: 1 } },
          }}>
          <div
            className={classes.controllsClose}
            onMouseEnter={() => setMouseOverButton(true)}
            onMouseLeave={() => setMouseOverButton(false)}>
            <ActionIcon
              color={'gray.7'}
              variant={'subtle'}
              size={BUTTON_SIZE}
              onClick={() => setActiveAttachment('')}>
              <IconX />
            </ActionIcon>
          </div>
          <div
            className={classes.controllsDownload}
            onMouseEnter={() => setMouseOverButton(true)}
            onMouseLeave={() => setMouseOverButton(false)}>
            <ActionIcon
              color={theme.primaryColor}
              variant={'light'}
              size={BUTTON_SIZE}
              onClick={() => triggerDownload()}>
              <IconDownload />
            </ActionIcon>
          </div>
          {isPresent(navigate) && (
            <>
              <div
                className={cls(classes.navigationButton, 'left')}
                onMouseEnter={() => setMouseOverButton(true)}
                onMouseLeave={() => setMouseOverButton(false)}>
                <Tooltip label={t`Föregående bilaga`}>
                  <ActionIcon
                    color={theme.primaryColor}
                    variant={'light'}
                    radius={100}
                    onClick={() => navigate('previous')}
                    size={48}>
                    <IconChevronLeft />
                  </ActionIcon>
                </Tooltip>
              </div>
              <div
                className={cls(classes.navigationButton, 'right')}
                onMouseEnter={() => setMouseOverButton(true)}
                onMouseLeave={() => setMouseOverButton(false)}>
                <Tooltip label={t`Nästa bilaga`}>
                  <ActionIcon
                    color={theme.primaryColor}
                    variant={'light'}
                    radius={100}
                    onClick={() => navigate('next')}
                    size={48}>
                    <IconChevronRight />
                  </ActionIcon>
                </Tooltip>
              </div>
            </>
          )}

          {activeItem?.type === AttachmentType.Pdf && (
            <div
              className={cls(classes.controlls)}
              onMouseEnter={() => setMouseOverButton(true)}
              onMouseLeave={() => setMouseOverButton(false)}>
              <Group position={'center'}>
                <div className={classes.controllPanel}>
                  <PDFViewerControls
                    buttonSize={BUTTON_SIZE}
                    onRotateLeft={() => setRotation((prev) => (prev % 360) - 90)}
                    onRotateRight={() => setRotation((prev) => (prev % 360) + 90)}
                    onZoomOut={() =>
                      setScale((prev) => (prev > ZOOM_MIN ? prev - ZOOM_STEP : prev))
                    }
                    onZoomIn={() =>
                      setScale((prev) => (prev <= ZOOM_MAX ? prev + ZOOM_STEP : prev))
                    }
                    onZoomReset={() => setScale(1.0)}
                    disableZoomOut={scale <= ZOOM_MIN}
                    disableZoomIn={scale >= ZOOM_MAX}
                    disableZoomReset={scale === 1.0}
                  />
                </div>
              </Group>
            </div>
          )}
        </motion.div>
      </div>
    </LiitDrawer>
  );
};
