import {
  Stack,
  Title,
  Button,
  TextInput,
  Input,
  Group,
  FileButton,
  ColorInput,
  Anchor,
  Box,
  Image,
  useMantineTheme,
  Select,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Link, RichTextEditor } from '@mantine/tiptap';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import {
  CONTAINER_PADDING_WIDTH,
  MAX_FORM_WIDTH,
  CONTAINER_PADDING_TOP,
} from '../../layout/Layout';
import { FileUpload } from '../../models/FileUpload';
import { Organisation } from '../../models/Organisation';
import { getSettings } from '../../Settings';
import { ErrorMessages } from '../../utils/errorMessages';
import { showSuccessNotification, showFailNotification } from '../../utils/notificationHelper';
import { LiitDropdown } from '../../components/LiitDropdown/LiitDropdown';
import { LanguageCode } from '../../models/LanguageCode';
import { isMissing, isPresent } from 'utilitype';

export const SettingsGeneralView: React.FC = () => {
  const api = useApi();
  const prevLanguage = useRef<LanguageCode>(LanguageCode.sv);
  const { selectedOrganisation } = useSession();
  const [file, setFile] = useState<File | null>(null);
  const [language, setLanguage] = useState<LanguageCode>(LanguageCode.sv);
  const [preview, setPreview] = useState<string | null>(null);

  const form = useForm<Organisation>({
    initialValues: new Organisation(),
    validate: {
      name: (value) => (value.trim() && value.trim().length > 0 ? null : ErrorMessages.IS_REQUIRED),
      primaryColor: (value) =>
        value.trim() && value.trim().length > 0 ? null : ErrorMessages.IS_REQUIRED,
    },
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      if (selectedOrganisation && editor) {
        const result = await api.getOrganisation(selectedOrganisation.id);

        form.setValues(result);
        
        if (!result.descriptions.some(x => x.language === language)) {
          form.setFieldValue('descriptions', [...form.values.descriptions, { language: language, text: '' }]);   
        }

        setPreview(result.logotypeUrl ?? null);

        if (editor && !editor.isDestroyed) {
          const text = result.descriptions.find(x => x.language === language)?.text ?? '';
          editor.commands.setContent(text);
        }
      }
    };

    fetchData();
  }, [selectedOrganisation, editor]);

  useEffect(() => {
    if (!file) {
      setPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  useEffect(() => {
    if (prevLanguage.current !== language) {
      const descriptions = [...form.values.descriptions].map(x => x);
      if (!descriptions.some(x => x.language === prevLanguage.current)) {
        descriptions.push({ language: prevLanguage.current, text: '' });
      }

      form.setFieldValue(
        'descriptions',
        descriptions.map((x) => {
          if (x.language === prevLanguage.current) {
            x.text = (editor && editor.isEmpty === false) ? editor.getHTML() : '';
          }

          return x;
        }),
      );
    }

    if (isPresent(language) && editor) {
      let languageItem = form.values.descriptions.find((x) => x.language === language);

      if (!languageItem) {
        languageItem = { language: language, text: '' };
        const items = [...form.values.descriptions, languageItem];
        form.setFieldValue('descriptions', items);
      }

      editor?.commands.setContent(languageItem.text);

      prevLanguage.current = language;
    }
  }, [language, editor]);

  const getUrl = () => `${getSettings().CLIENT_URL}/${selectedOrganisation?.slug}`;

  const save = async (values) => {
    
    form.values.descriptions = [...form.values.descriptions].map(x => {
      if (x.language === language) {
        x.text = (editor && editor.isEmpty === false) ? editor.getHTML() : '';
      }

      return x;
    });
    try {
      await api.updateOrganisation(values as Organisation);
      showSuccessNotification(t`Inställningar upppdaterade`, t`Allmäna inställningar har sparats`);
    } catch {
      showFailNotification(
        t`Något gick fel`,
        t`Något gick fel vid sparning av allmäna inställningar`,
      );
    }

    if (file) {
      const { name, type, size } = file;
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const { result } = reader;

        await api.uploadLogotype(
          selectedOrganisation!.id,
          new FileUpload(name, result as ArrayBuffer, type, size, () => {}),
        );
      };
    }
  };

  if (!selectedOrganisation) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <GrantMeContainer>
      <Stack spacing={0}>
        <GrantMeTitleContainer>
          <Title order={3}>{t`Allmänt`}</Title>
        </GrantMeTitleContainer>
        <Box
          pl={CONTAINER_PADDING_WIDTH}
          pr={CONTAINER_PADDING_WIDTH}
          maw={MAX_FORM_WIDTH}
          pt={CONTAINER_PADDING_TOP}>
          <form onSubmit={form.onSubmit(async (values) => save(values))}>
            <Stack>
              <TextInput label="Namn" {...form.getInputProps('name')} maxLength={400} />

              {/* <Input.Wrapper label={t`Beskrivning`}> */}
              <Input.Wrapper
                label={
                  <Group position={'apart'} w={'100%'}>
                    <Text>{t`Beskrivning`}</Text>
                    <LiitDropdown
        menuItems={[
          { id: 'sv', text: 'Svenska' },
          { id: 'en', text: 'Engelska' },
        ]}
        onChange={(selectedLanguage) => {
          setLanguage(selectedLanguage ? LanguageCode[selectedLanguage] : LanguageCode.sv);
        }}
        selectedId={language === LanguageCode.en ? 'en' : 'sv'}
        showColorBox={false}
      />
                  </Group>
                }>
                <RichTextEditor editor={editor}>
                  <RichTextEditor.Toolbar sticky stickyOffset={60}>
                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Bold />
                      <RichTextEditor.Italic />
                      <RichTextEditor.Underline />
                      <RichTextEditor.Strikethrough />
                      <RichTextEditor.ClearFormatting />
                      <RichTextEditor.Code />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.H1 />
                      <RichTextEditor.H2 />
                      <RichTextEditor.H3 />
                      <RichTextEditor.H4 />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Blockquote />
                      <RichTextEditor.Hr />
                      <RichTextEditor.BulletList />
                      <RichTextEditor.OrderedList />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.Link />
                      <RichTextEditor.Unlink />
                    </RichTextEditor.ControlsGroup>

                    <RichTextEditor.ControlsGroup>
                      <RichTextEditor.AlignLeft />
                      <RichTextEditor.AlignCenter />
                      <RichTextEditor.AlignJustify />
                      <RichTextEditor.AlignRight />
                    </RichTextEditor.ControlsGroup>
                  </RichTextEditor.Toolbar>

                  <RichTextEditor.Content />
                </RichTextEditor>
              </Input.Wrapper>

              <Input.Wrapper label="Logotype" description="Minst 55px hög">
                <Image p={'md'} height={55} width={'auto'} src={preview} withPlaceholder />
                <Group>
                  <FileButton onChange={setFile} accept="image/png,image/jpeg">
                    {(props) => (
                      <Button variant={'subtle'} {...props}>
                        Välj fil
                      </Button>
                    )}
                  </FileButton>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setFile(null);
                      setPreview(null);
                      form.values.logotypeUrl = undefined;
                    }}
                    disabled={!preview}>
                    Ta bort
                  </Button>
                </Group>
              </Input.Wrapper>

              <Group>
                <ColorInput label="Färgtema" {...form.getInputProps('primaryColor')} />
              </Group>

              <Input.Wrapper label="Länk">
                <br />
                <Anchor href={getUrl()} target="_blank" size="sm">
                  {getUrl()}
                </Anchor>
              </Input.Wrapper>

              <Group position={'left'} pt={24}>
                <Button type="submit">Spara</Button>
              </Group>
            </Stack>
          </form>
        </Box>
      </Stack>
    </GrantMeContainer>
  );
};
