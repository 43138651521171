import { IDisplayColumn } from '../../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import { RenderAmountCell } from '../../common/grid-columns/RenderAmountCell';
import { RenderApplicantCell } from '../../common/grid-columns/RenderApplicantCell';
import { RenderApplicationStatusCell } from '../../common/grid-columns/RenderApplicationStatusCell';
import { RenderCategoryCell } from '../../common/grid-columns/RenderCategoryCell';
import { RenderReviewStatusCell } from '../../common/grid-columns/RenderReviewStatusCell';
import { RenderTagsCell } from '../../common/grid-columns/RenderTagsCell';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const ApplicationsListColumns = (includeTagsColumn: boolean): Array<IDisplayColumn> => {

  const columns = new Array<IDisplayColumn>();
  columns.push({
    displayName: '',
    propertyName: 'categoryColor',
    propertyType: PropertyType.Text,
    renderCell: RenderCategoryCell,
    columnStyles: { ...columnStyles, padding: 0 },
  });

  columns.push({
    displayName: 'Diarienr',
    propertyName: 'number',
    propertyType: PropertyType.Text,

    columnStyles: { ...columnStyles, paddingLeft: '40px', paddingRight: 0 },
  });

  columns.push({
    displayName: 'Inkom',
    propertyName: 'submittedUtc',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  });
  
  columns.push({
    displayName: 'Sökande',
    propertyName: 'applicantName',
    propertyType: PropertyType.Text,
    renderCell: RenderApplicantCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
    hideFromPrint: true,
  });

  columns.push({
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    // columnStyles: { cursor: 'pointer', userSelect: 'none' },
    columnStyles: { width: '40%', cursor: 'pointer', userSelect: 'none' },    
    maxLength: 100,
  });

  columns.push({
    displayName: 'Status',
    propertyName: 'status',
    renderCell: RenderApplicationStatusCell,
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  });
  
  if (includeTagsColumn) {
    columns.push({
      displayName: 'Kategorier',
      propertyName: 'tags',
      renderCell: RenderTagsCell,
      propertyType: PropertyType.Text,
      //columnStyles: { ...columnStyles, whiteSpace: 'normal' },
      columnStyles: { width: '15%', cursor: 'pointer', userSelect: 'none', whiteSpace: 'normal' },    
    });
  }

  columns.push({
    displayName: 'Granskningar',
    propertyName: 'numberOfReviews',
    propertyType: PropertyType.Number,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  });
  
  columns.push({
    displayName: 'Sökt belopp',
    propertyName: 'requestedAmount',
    propertyType: PropertyType.Currency,
    renderCell: RenderAmountCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  });

  return columns;

};
