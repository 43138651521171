import { Flex, Textarea, Text } from '@mantine/core';
import { FC } from 'react';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElementTextArea } from '../../models/FormElementTextArea';
import { FormLabel } from '../Common/FormLabel';
import { ViewerTextArea } from './ViewerTextArea';

type TextAreaProps = {
  element: FormElementTextArea;
} & FormElementComponentProps;

export const TextArea: FC<TextAreaProps> = ({ number, element, mode, error, onChange }) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerTextArea number={number} element={element} />;
  }

  const {
    value,
    description,
    label,
    required,
    maxRows,
    minRows,
    hideLabel,
    maxCharCount,
    labelAndTextPosition,
  } = element as FormElementTextArea;

  const labelText = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);
  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);
  const text = (value as string) ?? '';

  const rowsMinimum = minRows ?? 2;
  const rowsMaximum = maxRows ?? 2;

  return (
    <Flex direction={'column'} align={'end'}>
      <Textarea
        styles={{
          input: {
            textAlign: labelAndTextPosition ?? 'left',
          },
        }}
        w={'100%'}
        disabled={mode === FormElementMode.Editor}
        description={descriptionText}
        label={
          hideLabel ? undefined : <FormLabel element={element} mode={mode} position={'apart'} />
        }
        placeholder={hideLabel === undefined ? undefined : labelText}
        required={required}
        withAsterisk={false}
        value={text}
        error={error}
        minRows={rowsMinimum}
        maxRows={rowsMaximum}
        autosize
        maxLength={maxCharCount ?? 10000}
        onChange={(event) => {
          if (onChange) {
            onChange(event.currentTarget.value ?? '');
          }
        }}
      />
      {maxCharCount && (
        <Text size={'xs'} weight={500} color={'dimmed'}>{`${text.length} / ${maxCharCount}`}</Text>
      )}
    </Flex>
  );
};
