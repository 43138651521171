import { createStyles, Flex } from '@mantine/core';
import { BORDER_COLOR } from '../../../App';

export const applicationAttachmentsStyles = createStyles((theme) => ({
  attachmentsContainer: {
    display: 'flex',
    gap: '8px',
    cursor: 'pointer',
    borderBottom: `2px solid ${theme.colors.gray[2]}`,
    borderTop: `2px solid ${theme.colors.gray[2]}`,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: '16px',
    backgroundColor: theme.colors.gray[1],
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));