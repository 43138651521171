import { createStyles } from '@mantine/core';

export const dashboardViewStyles = createStyles((theme) => ({
  dashboardTable: {
    thead: {
      tr: {
        th: {
          background: theme.white,
        },
      },
    },
  },
}));
