import { Text } from '@mantine/core';
import { FC } from 'react';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { FormElement } from '../../models/FormElement';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';

export const ViewerCheckboxGroup: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const values = element.value && Array.isArray(element.value) ? (element.value as string[]) : [];
  const optionValues = values.map((x: string) => LanguageHandler.getTextByLanguage(element.options.find((y) => y.id === x)?.label, LanguageCode.sv));
  const { labelAndTextPosition } = element as FormElement;


  return (
    <ViewerInputWrapper number={number} element={element}>
      <Text align={labelAndTextPosition ?? 'left'} size="sm">{optionValues.join(', ')}</Text>
    </ViewerInputWrapper>
  );
};
