export default class QueryStringBuilder {
  
  private baseUrl: string;

  private queryString: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.queryString = '';
  }

  addQueryString(key: string, value: any): void {
    if (this.queryString !== '') {
      this.queryString += '&';
    }
    this.queryString += `${key}=${encodeURIComponent(value.toString())}`;
  }

  build(): string {
    return this.baseUrl + '?' + this.queryString;
  }
}