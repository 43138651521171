import React from 'react';
import { createStyles } from '@mantine/core';
import { Footer } from './Footer';

const useStyles = createStyles(() => ({
  contentContainer: {
    flex: 1,
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    position: 'relative',
  },
  flexThis: {
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
  },
}));

type Props = {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
};

export const ContentContainer: React.FC<Props> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.contentContainer}>
      <div className={classes.flexThis}>{children}</div>
      {/* <Footer /> */}
    </div>
  );
};
