export const filterData = (data: Record<string, string>[], search: string) => {
  if (!data || data.length === 0) {
    return [];
  }
  const keys = Object.keys(data[0]);
  const query = search.toLowerCase().trim();

  return data.filter((item) =>
    keys.some((key) => item[key]?.toString()?.toLowerCase()?.includes(query)),
  );
};
