import { isPresent } from 'utilitype';
import { AmountsTableValue } from './AmountsTableValue';

export const amountsTableUpdateValue = (value: AmountsTableValue, event: number | undefined, id: string)
: AmountsTableValue => {
  if (isPresent(event)) {
    value[id] = event;
  } else {
    if (isPresent(value[id])) {
      delete value[id];
    }  
  }

  return value;
};