/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum ApplicationStatus {
  Draft = 0,
  Submitted = 1,
  Declined = 2,
  Approved = 3,
  Deleted = 4,
  PreliminaryApproved = 5,
  PreliminaryDeclined = 6,
}

export interface ApplicationStatusInfo {
  title: string;
  color: string;
}

export const ApplicationStatusNames: Record<ApplicationStatus, string> = {
  [ApplicationStatus.Approved]: 'Godkänd',
  [ApplicationStatus.PreliminaryApproved]: 'Godkänd',
  [ApplicationStatus.Declined]: 'Nekad',
  [ApplicationStatus.PreliminaryDeclined]: 'Nekad',
  [ApplicationStatus.Draft]: 'Ingen',
  [ApplicationStatus.Submitted]: 'Inlämnad',
  [ApplicationStatus.Deleted]: 'Borttagen',
  // [ApplicationStatus.SubmittedCompletionRequested]: 'Komplettering begärd',
  // [ApplicationStatus.SubmittedCompletionResponded]: 'Inlämnad med komplettering',
};

export const ApplicationStatusInformation: Record<ApplicationStatus, ApplicationStatusInfo> = {
  [ApplicationStatus.PreliminaryApproved]: { title: 'Godkänd', color: '' },
  [ApplicationStatus.Approved]: { title: 'Godkänd', color: '' },
  [ApplicationStatus.PreliminaryDeclined]: { title: 'Nekad', color: '' },
  [ApplicationStatus.Declined]: { title: 'Nekad', color: '' },
  [ApplicationStatus.Draft]: { title: 'Ingen', color: '' },
  [ApplicationStatus.Submitted]: { title: 'Inlämnad', color: '' },
  [ApplicationStatus.Deleted]: { title: 'Borttagen', color: '' },
  // [ApplicationStatus.SubmittedCompletionRequested]: { title: 'Komplettering begärd', color: '' },
  // [ApplicationStatus.SubmittedCompletionResponded]: {
  //   title: 'Inlämnad med komplettering',
  //   color: '',
  // },
};
