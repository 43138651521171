import { Box, Text, createStyles } from '@mantine/core';
import React, { ReactNode } from 'react';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';

const animateTextStyles = createStyles(() => ({
  toolTipBox: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    maxWidth: '24px',
    minWidth: '24px',
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'max-width 0.3s',
    '&:hover': {
      maxWidth: '900px',
      paddingRight: '40px',
      boxShadow: '1px 0 4px -1px #888',
      '& > div': {
        opacity: 1,
      },
    },
  },
}));

const CellAnimation: React.FC<{ applicationPeriodTitle: string; colorItem: any }> = ({
  applicationPeriodTitle,
  colorItem,
}) => {
  const { classes } = animateTextStyles();

  return (
    <Box
      className={classes.toolTipBox}
      style={{
        backgroundColor: colorItem.color,
      }}>
      <Text
        pl={40}
        style={{

          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: colorItem.inverted,
          fontWeight: 500,
        }}>
        {applicationPeriodTitle}
      </Text>
    </Box>
  );
};

export const RenderCategoryCell: RenderCell = (
  renderInformation: CellRenderInformation,
): ReactNode => {
  const { rowId, cellValue, rowValues } = renderInformation;
  const { applicationPeriodTitle } = rowValues;

  return (
    <Box
      w={24}
      style={{
        position: 'relative',
        backgroundColor: cellValue ? cellValue.color : 'white',
        height: '100%',
      }}>
      <CellAnimation colorItem={cellValue} applicationPeriodTitle={applicationPeriodTitle} />
    </Box>
  );
};
