import { isPresent } from 'utilitype';
import { LanguageCode } from '../../../../models/LanguageCode';
import { ValidationResult } from '../../../../models/ValidationResult';
import { ErrorMessages } from '../../../../utils/errorMessages';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElement } from '../../models/FormElement';
import { FormElementWithControlQuestions } from '../../models/FormElementWithControlQuestion';

export const validateRadioButtonGroup = (element: FormElement): ValidationResult => { 
  const typedElement = element as FormElementWithControlQuestions;
  const { required, options } = typedElement;

  if (required && !isPresent(element.value)) {
    return { isValid: false, errorMessage: ErrorMessages.IS_REQUIRED };
  }

  if (required) {
    const optionIds = options.map(o => o.id);

    if (!optionIds.includes(element.value as string)) {
      return { isValid: false, errorMessage: ErrorMessages.IS_REQUIRED };
    }
  }

  const isControlQuestion = options.some(x => x.isMandatory === true);
  if (isControlQuestion) {
    const selectedOption = options.find(x => x.id === element.value);
    if (!selectedOption?.isMandatory) {
      const errorText = LanguageHandler.getTextByLanguage(typedElement.controlQuestionErrorText, LanguageCode.sv);
      return { isValid: false, errorMessage: errorText };
    }
  }

  return { isValid: true, errorMessage: '' };
};