import { Center, Tooltip } from '@mantine/core';
import { ReactNode } from 'react';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { ApplicationStatuses } from '../../application/common/ApplicationStatuses';

export const RenderApplicationStatusCell: RenderCell = (
  rowInformation: CellRenderInformation,
): ReactNode => {
  const { cellValue, theme } = rowInformation;
  const applicationStatuses = ApplicationStatuses(theme);

  return (
    <Center>
      <Tooltip
        position={'left'}
        label={applicationStatuses[cellValue].title}
        color={applicationStatuses[cellValue].color}
        withArrow
        arrowSize={7}
        pl={16}
        pr={16}
        pt={8}
        pb={8}>
        <div
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundColor: applicationStatuses[cellValue].color,
          }}
        />
      </Tooltip>
    </Center>
  );
};
