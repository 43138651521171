export class FormFileUpload {
  constructor(
    public name: string,
    public data: ArrayBuffer,
    public type: string,
    public size: number,
    public elementId: string,
    public uploadComplete: () => void) {
  }
}
