import { NumberInput, NumberInputProps } from '@mantine/core';
import React from 'react';

interface LocaleData {
  thousandMatcher: string,
}

export const LocaleInformation: Record<string, LocaleData> = {
  ['sv-SE']: { thousandMatcher: '\\s' },
  ['en-GB']: { thousandMatcher: ',' },
  ['es']: { thousandMatcher: '.' },
};

type LiitNumberInputProps = {
  textAlign?: 'right' | 'left',
} & NumberInputProps & React.RefAttributes<HTMLInputElement>;

const LOCAL = 'sv-SE';
const regExp = new RegExp(`[${LocaleInformation[LOCAL].thousandMatcher}]+`, 'g');

export const LiitNumberInput: React.FC<LiitNumberInputProps> = ({ textAlign, ...props  }) => {
  return (
    <NumberInput
      styles={{
        input: {
          textAlign: textAlign ?? 'left',
        },

      }}
      {...props}
      parser={(val) => {
        const result = (!val ? '' : val.replace(regExp, '')); 
        return result;
      }}
      formatter={(val) => {
        if (!val) {
          return '';
        }

        let checkVal = val;
        const mostRecent = Number(val[val.length - 1]);

        if (Number.isNaN(mostRecent)) {
          checkVal = checkVal.substring(0, checkVal.length - 1);
        }

        const num = Number(checkVal);

        if (Number.isNaN(num) || !checkVal) {
          return '';
        }

        const formatted = num.toLocaleString(LOCAL);
        return formatted;
      }}
    />
  );
};
