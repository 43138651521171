import { t } from 'ttag';
import { InputTypesSetting } from '../../FormBuilderToolbox/ToolboxItemSettings';
import { TextBoxInputType } from './TextBoxInputType';

export const TypedTextBoxTypeItems: Record<TextBoxInputType, InputTypesSetting> = {
  [TextBoxInputType.ApplicantFirstName]: {
    label: t`Sökande förnamn`,
    type: TextBoxInputType.ApplicantFirstName,
    mandatory: true,
    limit: 1,
  },
  [TextBoxInputType.ApplicantLastName]: {
    label: t`Sökande efternamn`,
    type: TextBoxInputType.ApplicantLastName,
    mandatory: true,
    limit: 1,
  },
  [TextBoxInputType.ApplicantOrganizationName]: {
    label: t`Företagsnamn`,
    type: TextBoxInputType.ApplicantOrganizationName,
    mandatory: true,
    limit: 1,
  },
  [TextBoxInputType.ContactPersonEmail]: {
    label: t`Kontaktperson E-Post`,
    type: TextBoxInputType.ContactPersonEmail,
    mandatory: true,
    limit: 1,
  },
  [TextBoxInputType.ContactPersonPhoneNumber]: {
    label: t`Kontaktperson telefon`,
    type: TextBoxInputType.ContactPersonPhoneNumber,
    mandatory: true,
    limit: 1,
  },
  [TextBoxInputType.Plain]: {
    label: t`Fritext`,
    type: TextBoxInputType.Plain,
    mandatory: false,
  },
  [TextBoxInputType.Email]: {
    label: t`E-post`,
    type: TextBoxInputType.Email,
    mandatory: false,
  },
  [TextBoxInputType.PhoneNumber]: {
    label: t`Telefonnummer`,
    type: TextBoxInputType.PhoneNumber,
    mandatory: false,
  },
  [TextBoxInputType.RequestedAmount]: {
    label: t`Sökt belopp`,
    type: TextBoxInputType.RequestedAmount,
    mandatory: false,
    limit: 1,
  },
  [TextBoxInputType.Title]: {
    label: t`Ändamål`,
    type: TextBoxInputType.Title,
    mandatory: false,
    limit: 1,
  },
  [TextBoxInputType.Number]: {
    label: t`Nummer`,
    type: TextBoxInputType.Number,
    mandatory: false,
  },
  
};
