import { t } from 'ttag';

export const csvPropertyMap = [
  { header: t`Förnamn`, key: 'firstname' },
  { header: t`Efternamn`, key: 'lastname' },
  { header: t`Telefonnummer`, key: 'phone' },
  { header: t`E-post`, key: 'email' },
  { header: t`Personnummer`, key: 'identityNumber' },
  { header: t`Clearingnummer`, key: 'clearingNumber' },
  { header: t`Kontonummer`, key: 'accountNumber' },
  { header: t`Ändamål`, key: 'title' },
  { header: t`Sökt belopp`, key: 'requestedAmount' },
];
