/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const ApplicationReportSubmittedListColumns = (): Array<IDisplayColumn> => [
  {
    displayName: 'Diarienr',
    propertyName: 'number',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, paddingRight: 0 },
    hideFromPrint: true,
  },
  {
    displayName: 'Inlämnad',
    propertyName: 'submittedDate',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Mottagare',
    propertyName: 'applicantName',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, width: '50%' },
  },
  {
    displayName: 'Period',
    propertyName: 'periodTitle',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, width: '50%' },
    hideFromPrint: true,
    disableClick: true,
  },
  {
    displayName: 'Diarienr ansökan',
    propertyName: 'parentNumber',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
    hideFromPrint: true,
  },
];

export const ApplicationReportBaseColumns = (): Array<IDisplayColumn> => [
  {
    displayName: 'Rapportdatum',
    propertyName: 'dueDate',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles },
    hideFromPrint: true,
    disableClick: true,
  },
  {
    displayName: 'Sökande',
    propertyName: 'applicantName',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
    disableClick: true,
  },
  {
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, width: '50%' },
    disableClick: true,
  },
  {
    displayName: 'Period',
    propertyName: 'periodTitle',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, width: '50%' },
    hideFromPrint: true,
    disableClick: true,
  },
  {
    displayName: 'Diarienr ansökan',
    propertyName: 'parentNumber',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
    hideFromPrint: true,
    disableClick: true,
  },
];
