import { createStyles } from '@mantine/core';

export const LiitFormContainerStyles = createStyles((theme) => ({
  wrapper: {
    flexGrow: 1,
    overflow: 'hidden',
  },

  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  footer: {
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },
}));
