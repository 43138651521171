import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { ReactNode } from 'react';

export const getChevron = (sorted: boolean, reversed: boolean): ReactNode | null => {
  if (sorted) {
    if (reversed) {
      return <IconChevronDown size={14} />;
    }

    return <IconChevronUp size={14} />;
  }

  return null;
};
