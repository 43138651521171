import React, { useState } from 'react';
import { Button, Stack, Textarea, Text, Title } from '@mantine/core';
import { Application } from '../../../models/Application';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { openConfirmModal } from '@mantine/modals';
import { t } from 'ttag';

interface ApplicationToolbarItemDeleteProps {
  application: Application;
  onChanged: () => void;
}

export const ApplicationToolbarItemDelete: React.FC<ApplicationToolbarItemDeleteProps> = ({
  application,
  onChanged,
}) => {
  const { selectedOrganisation, refreshCounters } = useSession();
  const api = useApi();
  const [comment, setComment] = useState<string>();

  const deleteApplication = async () => {
    await api.updateApplicationDecision(selectedOrganisation!.id, application.id, {
      decision: ApplicationStatus.Deleted,
      comment: comment,
    });

    await onChanged();
    if (refreshCounters) {
      await refreshCounters();
    }
  };

  const undeleteApplication = async () => {
    await api.updateApplicationDecision(selectedOrganisation!.id, application.id, {
      decision: ApplicationStatus.Submitted,
      comment: '',
    });

    await onChanged();
    if (refreshCounters) {
      await refreshCounters();
    }
  };

  const openConfirmDeleteApplicationModal = () => {
    return openConfirmModal({
      title: <Title order={3}>{t`Ta bort ansökan`}</Title>,
      children: (
        <Stack>
          <Text size={'sm'}>{t`Vill du verkligen ta bort ansökan?`}</Text>
        </Stack>
      ),
      labels: { confirm: 'Ta bort', cancel: 'Avbryt' },
      onCancel: () => {},
      onConfirm: async () => {
        await deleteApplication();
      },
    });
  };

  if (application.status !== ApplicationStatus.Deleted) {
    return (
      <Stack>
        <Textarea
          placeholder="Ange en orsak till borttagning"
          onChange={(event) => setComment(event.target.value)}
        />
        <Button onClick={() => openConfirmDeleteApplicationModal()}>Ta bort ansökan</Button>
      </Stack>
    );
  } else {
    return (
      <Stack>
        <Text>{application.statusComment}</Text>
        <Button onClick={() => undeleteApplication()}>Ångra borttagning</Button>
      </Stack>
    );
  }
};
