import { Group, ScrollArea, Stack, Text } from '@mantine/core';
import React, { useEffect, useRef } from 'react';
import cls from 'classnames';
import { CompletionCommentType } from '../../../components/Completion/CompletionCommentType';
import { CompletionComment } from '../../../components/Completion/CompletionComment';
import { completionCommentStyles } from './completionCommentsStyles';
import Formatter from '../../../utils/formatter';

interface CompletionCommentsProps {
  comments: CompletionComment[];
}

export const CompletionComments: React.FC<CompletionCommentsProps> = ({ comments }) => {
  const { classes, theme } = completionCommentStyles();

  const viewport = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (viewport.current) {
      viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  const getUserComment = (c: CompletionComment) => {
    return (
      <div
        className={classes.commentItem}
        style={{ alignItems: c.position }}
        key={c.createdUtc.toString()}>
        <Text size={'xs'} weight={700}>
          {c.name}
        </Text>
        <Text size={'xs'} color={'dimmed'}>
          {Formatter.formatDateTimeString(c.createdUtc)}
        </Text>
        <div
          className={cls(
            classes.chatBubble,
            c.position === 'start' ? classes.chatLeftSide : classes.chatRightSide,
          )}>
          <Text size={'xs'}>{c.comment}</Text>
        </div>
      </div>
    );
  };

  const getSystemComment = (c: CompletionComment) => {
    return (
      <Group key={`system_${c.createdUtc}`} position={'center'}>
        <div className={classes.systemMessage}>
          <Text color={theme.colors[theme.primaryColor][7]} size={'xs'} weight={'bolder'}>
            {c.comment}
          </Text>
          <Text size={'xs'} color={'dimmed'}>
            {Formatter.formatDateTimeString(c.createdUtc)}
          </Text>
        </div>
      </Group>
    );
  };

  return (
    <ScrollArea
      offsetScrollbars
      type={'auto'}
      className={classes.scrollArea}
      viewportRef={viewport}>
      <Stack spacing={'xs'}>
        {comments.map((c) => {
          return c.type === CompletionCommentType.User ? getUserComment(c) : getSystemComment(c);
        })}
      </Stack>
    </ScrollArea>
  );
};
