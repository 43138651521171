import { MantineTheme } from '@mantine/core';
import { ApplicationStatus as Status } from '../../../models/ApplicationStatus';
import { StatusInformation } from './StatusInformation';

export const ApplicationStatuses = (theme: MantineTheme): Record<Status, StatusInformation> => {
  return {
    [Status.Approved]: {
      title: 'Beviljad',
      color: theme.colors.green[5],
      colorKey: 'green.5',
    },
    [Status.PreliminaryApproved]: {
      title: 'Beviljad',
      color: theme.colors.green[5],
      colorKey: 'green.5',
    },
    [Status.PreliminaryDeclined]: {
      title: 'Nekad',
      color: theme.colors.red[3],
      colorKey: 'red.3',
    },
    [Status.Declined]: {
      title: 'Nekad',
      color: theme.colors.red[3],
      colorKey: 'red.3',
    },
    [Status.Draft]: {
      title: 'Pågående',
      color: theme.colors.yellow[5],
      colorKey: 'yellow.5',
    },
    [Status.Submitted]: {
      title: 'Inlämnad',
      color: theme.colors.gray[6],
      colorKey: 'gray.6',
    },
    [Status.Deleted]: {
      title: 'Borttagen',
      color: theme.colors.gray[7],
      colorKey: 'gray.7',
    },
    // [Status.SubmittedCompletionRequested]: {
    //   title: 'Komplettering begärd',
    //   color: theme.colors.gray[6],
    //   colorKey: 'gray.6',
    // },
    // [Status.SubmittedCompletionResponded]: {
    //   title: 'Inskickad med komplettering',
    //   color: theme.colors.yellow[5],
    //   colorKey: 'yellow.5',
    // },
  };
};
