export const download = async (base64: string, filename: string): Promise<void> => {
  if (!base64) {
    return;
  }

  const link = document.createElement('a');
  link.href = base64;
  link.setAttribute('download', filename);

  document.body.appendChild(link);

  link.click();

  link.parentNode?.removeChild(link);
};