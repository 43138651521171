import { ReactNode } from 'react';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { ApplicationStatuses } from '../../application/common/ApplicationStatuses';
import { Badge, Center } from '@mantine/core';
import { CompletionStatus } from '../../../models/CompletionStatus';
import { completionCommentStyles } from '../../application/common/completionCommentsStyles';
import Formatter from '../../../utils/formatter';

export const RenderCompletionStatusCell: RenderCell = (
  rowInformation: CellRenderInformation,
): ReactNode => {
  const { cellValue, theme } = rowInformation;

  const getCompletionStatusBadge = (status: CompletionStatus) => {

    const modifiedUtc = rowInformation.rowValues.completionStatusModifiedUtc;

    if (status === CompletionStatus.Requested) {
      return <Badge color='orange'>Begärd {modifiedUtc ? Formatter.formatDateString(modifiedUtc) : ''}</Badge>;
    } else if (status === CompletionStatus.Completed) {
      return <Badge color='green' >Inlämnad {modifiedUtc ? Formatter.formatDateString(modifiedUtc) : ''}</Badge>;
    } else {
      return <></>;
    }

  };

  return <Center>{getCompletionStatusBadge(cellValue)}</Center>;
};
