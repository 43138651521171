import { TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React, { BaseSyntheticEvent } from 'react';
import { spaceAndNumber } from '../../../../utils/textFormatters';

interface InputAccountNumberProps {
  label: string;
  value: string;
  error: boolean;
  onChange: (newAccountNumber: string) => void
}

export const InputAccountNumber: React.FC<InputAccountNumberProps> = ({ label, value, error, onChange }) => {
  return (
    <TextInput
      label={label}
      type={'text'}
      maxLength={16}
      value={value}
      required
      error={error}
      onInput={(event: BaseSyntheticEvent<InputEvent>) => {
        const { value: newValue } = event.target;
        event.target.value = spaceAndNumber(newValue);
      }}
      onChange={(event) => {
        const { value: newValue } = event.target;
        onChange(newValue);
      }}
      onBlur={(event) => {
        const { value: newValue } = event.target;
        onChange(newValue.trim());
      }}
    />
  );
};
