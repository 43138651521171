import { useIsAuthenticated } from '@azure/msal-react';
import {
  Stack,
  Title,
  Button,
  Text,
  FileButton,
  Group,
  Table,
  Select,
  useMantineTheme,
  Space,
  Box,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import { isEmpty, isMissing, isPresent } from 'utilitype';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { ApplicationPeriod } from '../../../models/ApplicationPeriod';
import { csvPropertyMap } from './CsvPropertyMap';
import { ImportApplication } from '../../../models/ImportApplication';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { ApplicationStatuses } from '../../application/common/ApplicationStatuses';
import { showFailNotification, showSuccessNotification } from '../../../utils/notificationHelper';
import { openConfirmModal } from '@mantine/modals';
import Validator from '../../../utils/validators';
import readXlsxFile, { Row } from 'read-excel-file';
import writeXlsxFile from 'write-excel-file';

const requiredFields = [
  'firstname',
  'lastname',
  'identityNumber',
  'clearingNumber',
  'accountNumber',
  'title',
];

interface ImportApplicationsProps {
  applicationPeriod: ApplicationPeriod;
  onComplete: () => void;
}

export const ImportApplications: React.FC<ImportApplicationsProps> = ({
  applicationPeriod,
  onComplete,
}) => {
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const isAuthenticated = useIsAuthenticated();
  const [file, setFile] = useState<File | null>(null);
  const [applications, setApplications] = useState<ImportApplication[]>([]);
  const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus>(ApplicationStatus.Submitted);
  const theme = useMantineTheme();
  const [errorReadingFile, setErrorReadingFile] = useState<boolean>(false);

  const statusInformation = ApplicationStatuses(theme);
  const statusName = isPresent(applicationStatus)
    ? statusInformation[applicationStatus as ApplicationStatus].title
    : '';

  useEffect(() => {}, [selectedOrganisation, isAuthenticated]);

  const readField = (row: Row, index: number) => {
    if (row.length > index) {
      return row[index];
    }
    return null;
  };

  useEffect(() => {
    if (isMissing(applicationStatus)) {
      return;
    }

    if (file) {

      setErrorReadingFile(false);
      const apps = new Array<ImportApplication>();

      readXlsxFile(file).then((rows) => {
        rows.map((row, index) => {

          if (index > 0) {
            const app = {
              firstname: readField(row, 0)?.toString(),
              lastname: readField(row, 1)?.toString(),
              phone: readField(row, 2)?.toString(),
              email: readField(row, 3)?.toString(),
              identityNumber: readField(row, 4)?.toString(),
              clearingNumber: readField(row, 5)?.toString(),
              accountNumber: readField(row, 6)?.toString(),
              title: readField(row, 7)?.toString(),
              requestedAmount: readField(row, 8),
            } as ImportApplication;
                    
            apps.push(app);
          }
        });

        setApplications(apps);

      }).catch(() => {
        setErrorReadingFile(true);
      }); 
    }



  }, [file]);

  interface ExcelRow {
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    identityNumber: number | undefined,
    clearingNumber: string,
    accountNumber: string,
    title: string,
    requestedAmount: number | undefined,
  }

  const getTemplateImport = async () => {
   
    const objects = new Array<ExcelRow>();
    objects.push({
      firstName: 'John',
      lastName: 'Smith',
      phone: '060-3943213',
      email: 'john@email.com',
      identityNumber: 199611067910,
      clearingNumber: '5355',
      accountNumber: '1234567',
      title: 'Studieresa',
      requestedAmount: 1500,
    });

    // Add 1000 empty rows to keep formatting on each row
    for (let i = 0; i < 1000; i++) {
      objects.push({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        identityNumber: undefined,
        clearingNumber: '',
        accountNumber: '',
        title: '',
        requestedAmount: undefined,
      });
    }

    const schema = [
      {
        column: t`Förnamn`,
        type: String,
        value: row => row.firstName,        
      },
      {
        column: t`Efternamn`,
        type: String,
        value: row => row.lastName,
        width: 10,
      },
      {
        column: t`Telefon`,
        type: String,
        value: row => row.phone,
        width: 10,
      },
      {
        column: t`E-post`,
        type: String,
        value: row => row.email,
        width: 20,
      },
      {
        column: t`Personnummer`,
        type: Number,
        value: row => row.identityNumber,
        format: '0',
        width: 15,
      },
      {
        column: t`Clearingnummer`,
        type: String,
        value: row => row.clearingNumber,
        width: 15,
      },
      {
        column: t`Kontonummer`,
        type: String,
        value: row => row.accountNumber,
        width: 15,
      },
      {
        column: t`Ändamål`,
        type: String,
        value: row => row.title,
        width: 20,
      },
      {
        column: t`Sökt belopp`,
        type: Number,
        format: '#,##0.00',
        value: row => row.requestedAmount,
        width: 10,
      },   
    ];    

    await writeXlsxFile(objects, { schema: schema, fileName: 'import.xlsx' } );
  };

  const importApplications = async () => {
    if (selectedOrganisation && isPresent(applications)) {
      const { id } = selectedOrganisation;
      try {
        const response = await api.importApplications(id, {
          applicationPeriodId: applicationPeriod.id,
          applications,
          applicationStatus: applicationStatus as ApplicationStatus,
        });

        if (response.ok) {
          showSuccessNotification(
            t`Lyckad import`,
            t`${applications.length} ansökningar har importerats i systemet med statusen '${statusName}'`,
          );
          onComplete();
        }

      } catch {
        showFailNotification(
          t`Fel vid import`,
          t`Något gick fel vid filimport, se över så att filen är i rätt format och försök igen.`,
        );
      }
    }
  };

  const openModal = () => {
    return openConfirmModal({
      title: <Title order={3}>{t`Import av ansökningar`}</Title>,
      children: (
        <Stack>
          <Text size={'sm'}>
            {t`Du är på väg att importera ${applications.length} ansökningar i perioden `}
            <Text color={theme.primaryColor} span>
              {applicationPeriod.title}
            </Text>
            {t` som kommer få status ${statusName}.`}
          </Text>
          <Text
            size={
              'sm'
            }>{t`Detta går inte att ångra. Är du helt säker på att du vill göra denna import?`}</Text>
        </Stack>
      ),
      labels: { confirm: 'Importera', cancel: 'Avbryt' },
      onCancel: () => {},
      onConfirm: () => importApplications(),
    });
  };

  const validateFields = csvPropertyMap.map((c) => {
    const missingValue = applications.some((a) => !isPresent(a[c.key]));
    return { key: c.key, field: c.header, hasMissing: missingValue };
  });

  const errors = validateFields.filter(
    (vf) => vf.hasMissing && requiredFields.some((rf) => rf === vf.key),
  );

  const validateAndGetCellValue = (application, mapInfo) => {
    let valueValid = true;
    const value = application[mapInfo.key];

    if (requiredFields.some(x => x === mapInfo.key)) {
      valueValid = isPresent(value);
    }

    if (mapInfo.key === 'email') {
      valueValid = Validator.validateEmail(value);
    }

    if (mapInfo.key === 'identityNumber') {
      if (isPresent(value)) {
        const validFormat = Validator.validateIdentityNumberPerson(value);
        const validControlNumber = Validator.validateIdentityNumberSwedish(value);

        valueValid = validFormat && validControlNumber;
      } else {
        valueValid = false;
      }
    }

    if (mapInfo.key === 'requestedAmount') {
      valueValid = Validator.validateAmount(value);
    }

    return (
      <td style={{ background: valueValid ? 'unset' : theme.colors.red[1] }} key={`${mapInfo.key}`}>
        {application[mapInfo.key]}
      </td>
    );
  };

  const hasInvalidCells = () => {
    return applications.some((a) => {
      return csvPropertyMap.some((map) => {
        const value = a[map.key];
        if (map.key === 'email') {
          if (!Validator.validateEmail(a[map.key])) {
            return true;
          }
        }

        if (map.key === 'identityNumber') {
          const validFormat = Validator.validateIdentityNumberPerson(value as string);
          const validControlNumber = Validator.validateIdentityNumberSwedish(value as string);

          if (!validFormat || !validControlNumber) {
            return true;
          }
        }

        if (map.key === 'requestedAmount') {
          if (!Validator.validateAmount(a[map.key])) {
            return true;
          }
        }

      });
    });
  };

  let invalidCells = false;
  if (applications && applications.length > 0 && applicationStatus) {
    invalidCells = hasInvalidCells();
  }

  return (
    <Box w={'100%'}>
      <Stack>
        <Title order={3}>{t`Importera från Excel`}</Title>
        <Text color={'dimmed'} size={'sm'}>
          En mall finns att ladda ner{' '}
          <Text
            color={'blue'}
            style={{ cursor: 'pointer' }}
            component={'a'}
            onClick={() => getTemplateImport()}>
            här
          </Text>
          . När du fyllt i filen med informationen om ansökarna klickar du på <i>Ladda upp fil</i>.
          Du får nu se en lista av vad som kommer importeras och kan nu också välja vilken status du vill att ansökningarna ska få.
        </Text>
        <Group position={'apart'} align={'end'}>
              <FileButton onChange={setFile} accept=".xlsx">
                {(props) => <Button {...props}>{t`Ladda upp fil`}</Button>}
              </FileButton>

            {(errors.length === 0 && !invalidCells) && 
              <Group align={'end'}>
            
              <Select
                  label={t`Status`}
                  value={applicationStatus?.toString()}
                  onChange={(value) => {
                    setApplicationStatus(Number(value));
                  }}
                  disabled={
                    invalidCells ||
                    isEmpty(applications) ||
                    isMissing(applicationStatus) ||
                    errors.length > 0
                  }
                  data={[
                    {
                      value: ApplicationStatus.Approved.toString(),
                      label: statusInformation[ApplicationStatus.PreliminaryApproved].title,
                    },
                    {
                      value: ApplicationStatus.Declined.toString(),
                      label: statusInformation[ApplicationStatus.PreliminaryDeclined].title,
                    },
                    {
                      value: ApplicationStatus.Submitted.toString(),
                      label: statusInformation[ApplicationStatus.Submitted].title,
                    },
                  ]}
                />             
              <Button
                fullWidth={false}
                onClick={openModal}
                disabled={
                  invalidCells ||
                  isEmpty(applications) ||
                  isMissing(applicationStatus) ||
                  errors.length > 0
                }>
                Importera
              </Button>
            </Group>
          }
        </Group>

        {(errors.length > 0 || invalidCells || errorReadingFile) && (          
          <Stack spacing={0}>                          
            <Text
              size={'xs'}
              color={theme.colors.red[5]}
              weight={'bold'}>{t`Fel hittades i filen`}</Text>
            <Space h={'sm'} />      
          </Stack>
        )}
          {applications && applications.length > 0 && (
          <Table fontSize={'xs'} pb={120}>
            <thead>
              <tr>
                {csvPropertyMap.map((map) => (
                  <th key={`${map.key}`}>{map.header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {applications.map((application, index) => (
                <tr key={`${application.identityNumber}_${index}`}>
                  {csvPropertyMap.map((map) => validateAndGetCellValue(application, map))}
                </tr>
              ))}
            </tbody>
          </Table>
          )}        
        
      </Stack>
    </Box>
  );
};