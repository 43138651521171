import React, { useEffect, useRef, useState } from 'react';
import {
  Text,
  Table,
  Group,
  ActionIcon,
  Paper,
  Transition,
  useMantineTheme,
  Pagination,
  Stack,
  LoadingOverlay,
  Flex,
  createStyles,
  Checkbox,
  MediaQuery,
} from '@mantine/core';
import { TableSortProps, TableState } from './TableSortProps';
import { styles } from './Styles';
import { sortData } from './SortData';
import { TableHeader } from './TableHeader';
import { formatValue } from './FormatValue';
import { CellRenderInformation } from './RenderCell';
import { isMissing, isPresent } from 'utilitype';
import { PaginationModel } from '../../models/PagedResult';
import { isPagedResult } from './IsPagedResult';
import { getGridData } from './getGridData';
import { t } from 'ttag';
import { IconChevronLeft, IconInfoCircle, IconPrinter } from '@tabler/icons';
import { useReactToPrint } from 'react-to-print';
import { PropertyType } from './PropertyType';
import { HideCondition } from './IDisplayColumn';
import { CONTAINER_PADDING_WIDTH } from '../../layout/Layout';

export const printStyles = createStyles((theme) => ({
  wrapper: {
    display: 'none',
    position: 'absolute',
    padding: 16,
    ['@media print']: {
      display: 'block',
      height: '100% !important',
      width: '100%',
      overflow: 'visible !important',
    },
  },
}));

export const LiitGrid: React.FC<TableSortProps<Record<string, any>>> = ({
  data,
  columnInfo,
  displayButtons,
  onRowClick,
  searchFilter,
  fetchingData = false,
  expanded,
  highligthOnHoover = true,
  onPageChange,
  setData,
  onCellValueChanged,
  emptyText = t`Inga rader funna`,
  emptyIcon: EmptyIcon,
  isPrintable,
  checkedRows,
  onRowChecked,
  canCheckRow,
  startSort = '',
  onSortChange,
}) => {
  const { classes, cx } = styles();
  const [sortedData, setSortedData] = useState<Record<string, any> | undefined>(undefined);
  const [paginationState, setPaginationState] = useState<PaginationModel | undefined>(undefined);
  const [sortBy, setSortBy] = useState<string>(startSort);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [expandedRow, setExpandedRow] = useState<string>('');
  const [isMounted, setIsMounted] = useState(false);
  const theme = useMantineTheme();
  const { classes: print } = printStyles();
  const [tableState, setTableState] = useState<TableState>({ page: 1, isAscending: true, orderBy: '' });
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderButtons = displayButtons && displayButtons.length > 0;
  useEffect(() => {
    if (data) {
      const newData = getGridData(data);

      if (isPagedResult(data)) {
        const { pagination } = data;
        setPaginationState(pagination);
      }

      if (isPagedResult(data)) {
        setSortedData(newData);
      } else {
        setSortedData(
          sortData(newData, { sortBy, reversed: reverseSortDirection, search: searchFilter ?? '' }),
        );
      }
    }
  }, [data, searchFilter]);

  useEffect(() => {
    setIsMounted(!!expandedRow);
  }, [expandedRow]);

  const setSorting = (field: string) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;

    setReverseSortDirection(reversed);
    setSortBy(field);

    if (!data) {
      return;
    }

    if (isPresent(onSortChange)) {
      const newTableState = { ...tableState, orderBy: field, isAscending: reversed };
      setTableState(newTableState);
      onSortChange(newTableState);
    } else {
  
      setSortedData(
        sortData(isPagedResult(data) ? data.data : data, {
          sortBy: field,
          reversed,
          search: searchFilter ?? '',
        }),
      );
    }
  };

  const hasDisplayButtons = displayButtons && displayButtons.length > 0;
  const hasCheckbox = !isMissing(checkedRows) && onRowChecked;

  const getRows = (isPrint: boolean) => {
    if (!sortedData) {
      return;
    }
    return sortedData.map((row) => {
      return (
        <React.Fragment key={row.id}>
          <tr
            className={`${isPresent(onRowClick) ? classes.clickableTr : ''} ${
              row.id === expandedRow ? classes.tdNoBorder : ''
            }`}>
            {hasCheckbox && (
              <td>
                <Checkbox
                  disabled={canCheckRow ? !canCheckRow(row) : false}
                  checked={checkedRows.some((x) => x === row.id)}
                  onChange={() => {
                    onRowChecked((prev) => {
                      const isRemove = prev.includes(row.id);
                      return isRemove ? [...prev.filter((x) => x !== row.id)] : [...prev, row.id];
                    });
                  }}
                />
              </td>
            )}
            {columnInfo.map((col) => {
              if (isPrint && col.hideFromPrint) {
                return null;
              }
              return (
                <MediaQuery
                  key={`column_${col.propertyName}_${row.id}_${col.displayName}`}
                  largerThan={
                    col.hideAt && col.hideAt.type === HideCondition.LargerThan
                      ? col.hideAt.size
                      : undefined
                  }
                  smallerThan={
                    col.hideAt && col.hideAt.type === HideCondition.SmallerThan
                      ? col.hideAt.size
                      : undefined
                  }
                  styles={{ display: 'none' }}>
                  <td
                    // key={`column_${col.propertyName}_${row.id}_${col.displayName}`}
                    onClick={() => !col.disableClick && isPresent(onRowClick) && onRowClick(row.id)}
                    valign="middle"
                    align={col.propertyType === PropertyType.Currency ? 'right' : 'left'}
                    style={col.columnStyles ? col.columnStyles : {}}
                    className={cx({
                      [classes.paddingOnFirstChild]: !hasCheckbox,
                      [classes.paddingOnLastChild]: true,
                    })}>
                    {col.renderCell
                      ? col.renderCell(
                        new CellRenderInformation(
                          row.id,
                          row[col.propertyName],
                          row,
                          theme,
                          setData,
                          onCellValueChanged,
                        ),
                      )
                      : formatValue(row[col.propertyName], col.propertyType, col.maxLength)}
                  </td>
                </MediaQuery>
              );
            })}
            {renderButtons && (
              <td style={{ width: '1%', paddingRight: CONTAINER_PADDING_WIDTH }}>
                <Group noWrap>
                  {displayButtons.map((button) => (
                    <React.Fragment key={'gridbutton_' + button.name}>
                      {button.isDisabled && button.isDisabled(row) && <></>}
                      {(!button.isDisabled || (button.isDisabled && !button.isDisabled(row))) && (
                        <ActionIcon
                          disabled={button.isDisabled && button.isDisabled(row)}
                          size={'md'}
                          key={`${row.id}_actionbutton_${button.name}`}
                          onClick={() => button.onClick(row.id)}>
                          {button.icon}
                        </ActionIcon>
                      )}
                    </React.Fragment>
                  ))}
                </Group>
              </td>
            )}
            {expanded && (
              <td>
                <IconChevronLeft
                  style={{ transform: `rotate(${row.id === expandedRow ? 90 : -90}deg)` }}
                  onClick={() => {
                    setIsMounted(false);
                    setExpandedRow((prev) => (prev != row.id ? row.id : ''));
                  }}
                />
              </td>
            )}
          </tr>
          {expanded && expandedRow === row.id && (
            <tr>
              <td
                className={classes.expandedTd}
                colSpan={columnInfo.length + (hasDisplayButtons ? 2 : 1)}>
                <Transition
                  mounted={isMounted}
                  transition={'fade'}
                  duration={400}
                  timingFunction={'ease'}>
                  {(transitionStyles) => <div style={transitionStyles}>{expanded(row.id)}</div>}
                </Transition>
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });
  };

  if (!data) {
    return <div />;
  }

  const numberOfCols =
    columnInfo.length + (hasCheckbox ? 1 : 0) + (hasDisplayButtons ? 1 : 0) + (expanded ? 1 : 0);
  const iconForEmpty = EmptyIcon ? (
    <EmptyIcon color={theme.colors[theme.primaryColor][7]} size={72} />
  ) : (
    <IconInfoCircle color={theme.colors[theme.primaryColor][7]} size={72} />
  );

  const getTable = (isPrint: boolean) => {
    const rows = getRows(isPrint);
    return (
      <Table
        highlightOnHover={highligthOnHoover}
        horizontalSpacing="md"
        verticalSpacing="md"
        className={classes.table}
        style={{ flex: 1 }}>
        <thead>
          <tr>
            {hasCheckbox && sortedData && (
              <th style={{ width: '1%' }} className={classes.th}>
                <Checkbox
                  checked={
                    sortedData.length > 0 && sortedData.every((row) => checkedRows.includes(row.id))
                  }
                  indeterminate={
                    sortedData.some((row) => checkedRows.includes(row.id)) &&
                    !sortedData.every((row) => checkedRows.includes(row.id))
                  }
                  onChange={() => {
                    if (
                      sortedData.some((row) => checkedRows.includes(row.id)) ||
                      sortedData.every((row) => checkedRows.includes(row.id))
                    ) {
                      onRowChecked([]);
                    } else {
                      onRowChecked((prev) => {
                        return [
                          ...prev,
                          ...sortedData
                            .filter(
                              (x) => !prev.includes(x.id) && (canCheckRow ? canCheckRow(x) : true),
                            )
                            .map((x) => x.id),
                        ];
                      });
                    }
                  }}
                />
              </th>
            )}
            {columnInfo.map((col) => {
              if (isPrint && col.hideFromPrint === true) {
                return null;
              }

              return (
                <TableHeader
                  key={`col_${col.propertyName}_${col.displayName}`}
                  sorted={sortBy === col.propertyName}
                  reversed={reverseSortDirection}
                  onSort={() => (col.disableSort ? undefined : setSorting(col.propertyName))}
                  disableSort={col.disableSort}
                  propertyType={col.propertyType}
                  columnStyles={col.columnStyles}
                  column={col}>
                  {col.displayName}
                </TableHeader>
              );
            })}
            {hasDisplayButtons && (
              <th style={{ width: '1%', paddingRight: CONTAINER_PADDING_WIDTH }} />
            )}
            {expanded && <th style={{ width: '5%' }} />}
          </tr>
        </thead>
        <tbody>
          {sortedData && rows.length > 0
            ? rows
            : rows &&
              rows.length === 0 && (
                <tr>
                  <td colSpan={numberOfCols} align="center">
                    <Flex
                      pt={'xl'}
                      pb={'xl'}
                      direction={'column'}
                      align={'center'}
                      justify={'center'}>
                      {iconForEmpty}
                      <Text pt={'md'} size={'xl'} color={theme.colors[theme.primaryColor][7]}>
                        {emptyText}
                      </Text>
                    </Flex>
                  </td>
                </tr>
            )}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <Stack style={{ flex: 1 }}>
        <Paper pt={0} style={{ position: 'relative' }}>
          <LoadingOverlay visible={fetchingData} transitionDuration={500} />
          {getTable(false)}
        </Paper>
        {paginationState && paginationState.totalPages > 1 && isPresent(onPageChange) && (
          <Group position={'center'} pb={'sm'}>
            <Pagination
              total={paginationState.totalPages}
              page={paginationState.currentPage}
              siblings={2}
              size={'sm'}
              onChange={(page) => {
                const newTableState = { ...tableState, page: page };
                setTableState(newTableState);
                onPageChange(newTableState);
              }}
            />
          </Group>
        )}
      </Stack>
      {false && isPresent(isPrintable) && (
        <>
          <Group position={'right'}>
            <ActionIcon
              mt={'sm'}
              onClick={handlePrint}
              color={theme.colors[theme.primaryColor][7]}
              variant={'outline'}>
              <IconPrinter size={18} />
            </ActionIcon>
          </Group>
          <div className={print.wrapper} ref={componentRef}>
            {getTable(true)}
          </div>
        </>
      )}
    </>
  );
};
