import { Center, Tooltip } from '@mantine/core';
import { ReactNode } from 'react';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { ReviewStatuses } from '../../application/common/ReviewStatuses';

export const RenderReviewStatusCell: RenderCell = (
  rowInformation: CellRenderInformation,
): ReactNode => {
  const { cellValue, theme } = rowInformation;
  const reviewStatuses = ReviewStatuses(theme);

  return (
    <Center>
      <Tooltip
        label={reviewStatuses[cellValue].title}
        color={reviewStatuses[cellValue].color}
        withArrow
        arrowSize={7}
        position={'left'}
        pl={16}
        pr={16}
        pt={8}
        pb={8}>
        <div
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundColor: reviewStatuses[cellValue].color,
          }}
        />
      </Tooltip>
    </Center>
  );
};
