import { ProductVariant } from './ProductVariant';
import { Role } from './Role';
import { TranslationItem } from './TranslationItem';

export class Organisation {
  public id = '';

  public name = '';

  public descriptions: Array<TranslationItem> = [];

  public address1 = '';

  public address2 = '';

  public zipCode = '';

  public city = '';

  public country = '';

  public slug = '';

  public primaryColor = '';

  public logotypeUrl? = '';

  public productVariant = ProductVariant.Large;

  public role = Role.Reviewer;
}
