import { addLocale, useLocale } from 'ttag';
import * as cookie from './cookie';

const LOCALE_COOKIE = '__locale';

function getLocale() {
  return cookie.get(LOCALE_COOKIE) || 'sv-SE';
}

export function saveLocale(locale) {
  cookie.set(LOCALE_COOKIE, locale);
}

export const setLocale = async (forced?: string) => {
  if (forced) {
    saveLocale(forced);
  }
  const locale = getLocale();

  if (locale !== 'sv-SE') {
    (async () => {
      const translationsObj = await require(`../i18n/${locale}.po.json`);
      addLocale(locale, translationsObj);
      useLocale(locale);
    })();
  }
};

