import {
  Progress,
  Grid,
  Indicator,
  Badge,
  Text,
  Table,
  useMantineTheme,
  Group,
} from '@mantine/core';
import { IconReport, IconSearch } from '@tabler/icons';
import React from 'react';
import { Dashboard } from '../../../models/Dashboard';
import { DashboardCard } from './DashboardCard';
import { dashboardViewStyles } from '../DashboardViewStyles';
import { ReviewStatuses } from '../../application/common/ReviewStatuses';
import Formatter from '../../../utils/formatter';
import { useNavigate } from 'react-router-dom';

interface RecentReviewsCardProps {
  data: Dashboard;
}

export const RecentReviewsCard: React.FC<RecentReviewsCardProps> = ({ data }) => {
  const { classes } = dashboardViewStyles();
  const theme = useMantineTheme();
  const reviewStatuses = ReviewStatuses(theme);
  const navigate = useNavigate();

  const getContent = () => {
    if (data.mostRecentReviewedApplications.length === 0) {
      return <Text color="dimmed">Du har inte granskat några ansökningar ännu.</Text>;
    }

    return (
      <Table verticalSpacing={'xs'} className={classes.dashboardTable}>
        <thead>
          <tr>
            <th>Nr</th>
            <th>Titel</th>
            <th>Status</th>
            <th>
              <Group position="right">Föreslaget belopp</Group>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.mostRecentReviewedApplications?.map((x) => {
            return (
              <React.Fragment key={'mrrapp_' + x.id}>
                <tr
                  onClick={() => {
                    navigate(`/applications/${x.id}`);
                  }}>
                  <td>
                    <Text fw={500}>{x.number}</Text>
                  </td>
                  <td>
                    <Text color="gray.7">{x.title}</Text>
                  </td>
                  <td>
                    <Indicator
                      offset={-12}
                      color={reviewStatuses[x.reviewStatus].color}
                      position="middle-start">
                      <Text color="gray.7">{reviewStatuses[x.reviewStatus].title}</Text>
                    </Indicator>
                  </td>
                  <td>
                    <Group position="right">{Formatter.formatCurrency(x.suggestedAmount)}</Group>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <DashboardCard title="Dina senaste granskningar" icon={IconSearch} content={getContent()} />
  );
};
