import { isPresent } from 'utilitype';
import { ValidationResult } from '../../../../models/ValidationResult';
import { ErrorMessages } from '../../../../utils/errorMessages';
import Validator from '../../../../utils/validators';
import { FormElement } from '../../models/FormElement';

export const validateIdentityNumberOrganization = (element: FormElement): ValidationResult => { 
  const identityNumber = element.value as string;
  
  if (!isPresent(identityNumber)) {
    return { isValid: false, errorMessage: ErrorMessages.IS_REQUIRED };
  }

  const regExp = new RegExp('^[0-9]{10}$', 'g');

  if (!identityNumber.match(regExp)) {
    return { isValid: false, errorMessage: ErrorMessages.FAULTY_IDENTIFIER_FORMAT_ORGANIZATION };
  }

  const isValid = Validator.validateIdentityNumberSwedish(identityNumber);
  if (!isValid) {
    return { isValid: false, errorMessage: ErrorMessages.FAULTY_IDENTIFIER_ORGANIZATION };
  }

  return { isValid: true, errorMessage: '' };
};