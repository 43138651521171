import {
  Button,
  Group,
  Stack,
  Title,
  Text,
  createStyles,
  Anchor,
  Flex,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { t } from 'ttag';
import { BORDER_COLOR } from '../../../App';
import { BackButton } from '../../../components/BackButton';
import { LiitDropdown, LiitDropdownMenuItem } from '../../../components/LiitDropdown/LiitDropdown';
import { LiitGrid } from '../../../components/LiitGrid/LiitGrid';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { GrantMeContainer } from '../../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../../layout/GrantMeTitleHeader';
import { Routes } from '../../../layout/Routes';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { ApprovalApplicationListColumns } from './ApprovalApplicationListColumns';
import { ApprovalApplicationPeriodCompletionModal } from './ApprovalApplicationPeriodCompletionModal';
import { ApprovalInformation } from './ApprovalInformation';
import { PeriodApprovalSummary } from './PeriodApprovalSummary';
import { DraftListPrintButton } from '../DraftListPrintButton';

export const styles = createStyles((theme) => ({
  bottomBorder: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
  topRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.red[2],
    position: 'sticky',
    top: 0,
    zIndex: 50,
    alignItems: 'stretch',
    alignSelf: 'flex-start',
    width: '100%',
  },
}));

export interface ApplicationFilter {
  id: string;
  isActive: boolean;
  title: string;
  value: number;
  color: string;
}

export const ApprovalPeriodApplicationList: React.FC = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodApprovalSummary>();
  const [applications, setApplications] = useState<any[] | undefined>(undefined);
  const [reviewers, setReviewers] = useState<any[]>([]);
  const [selectedReviewerId, setSelectedReviewerId] = useState<string | null>();
  const [isFetching, setIsFetching] = useState(false);
  const { selectedOrganisation } = useSession();
  const { applicationPeriodId } = useParams();
  const navigate = useNavigate();
  const api = useApi();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const { classes } = styles();

  const refreshApprovalPeriod = async () => {
    if (!selectedOrganisation || !applicationPeriodId) {
      return null;
    }

    const periodResult = await api.getApprovalPeriod(selectedOrganisation.id, applicationPeriodId);
    setSelectedPeriod(periodResult);
  };

  const refreshApprovalPeriodReviewers = async () => {
    if (!selectedOrganisation || !applicationPeriodId) {
      return null;
    }

    const result = await api.getApprovalPeriodReviewers(
      selectedOrganisation.id,
      applicationPeriodId,
    );
    setReviewers(result);
  };

  const refreshApprovalApplications = async () => {
    if (!selectedOrganisation || !applicationPeriodId) {
      return null;
    }

    const applicationsResult = await api.getApplicationsForApproval(
      selectedOrganisation.id,
      applicationPeriodId,
      selectedReviewerId,
    );

    // setApplications(sortData(applicationsResult, { sortBy: 'ranking', reversed: false, search: '' }));
    setApplications(applicationsResult);
  };

  const refresh = async () => {
    await refreshApprovalApplications();
    await refreshApprovalPeriod();
    await refreshApprovalPeriodReviewers();
  };

  const isArchived = () => {
    return selectedPeriod && selectedPeriod.archivedUtc;
  };

  const canMakeDecision = () => {
    return (
      applications &&
      applications.filter(
        (x) =>
          x.status === ApplicationStatus.PreliminaryApproved ||
          x.status === ApplicationStatus.PreliminaryDeclined,
      ).length > 0
    );
  };

  useEffect(() => {
    if (!selectedOrganisation) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);

      await refresh();

      setIsFetching(false);
    };

    if (selectedOrganisation && applicationPeriodId) {
      fetchData();
    }
  }, [selectedOrganisation]);

  useEffect(() => {
    if (!selectedOrganisation || selectedReviewerId === undefined) {
      return;
    }

    const fetchData = async () => {
      setIsFetching(true);

      await refreshApprovalApplications();

      setIsFetching(false);
    };

    if (selectedOrganisation && applicationPeriodId) {
      fetchData();
    }
  }, [selectedReviewerId]);

  if (!selectedPeriod) {
    return null;
  }

  if (isArchived()) {
    return (
      <GrantMeContainer>
        <Group spacing="xs" pb="xl">
          <BackButton route={Routes.Approvals} />
        </Group>

        <Text align="center" pt="xl">
          Denna ansökningsperioden är arkiverad. Du hittar den under{' '}
          <Anchor component={Link} to={`/archive/applicationperiods/${selectedPeriod.id}`}>
            <b>Arkiv</b>
          </Anchor>
          .
        </Text>
      </GrantMeContainer>
    );
  }

  return (
    <>
      <GrantMeContainer takeAllSpace={true} scrollable={false}>
        <Stack spacing={0} style={{ flex: 1, overflow: 'hidden' }}>
          <GrantMeTitleContainer>
            <Group spacing="xs" noWrap style={{ flex: '1 1 auto', minWidth: 0 }}>
              <BackButton route={Routes.Approvals} />
              <Title
                pl={4}
                order={3}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {selectedPeriod?.title}
              </Title>
            </Group>

            {applications && selectedPeriod && (
              <DraftListPrintButton
                decision={null}
                disabled={false}
                period={selectedPeriod}
                applications={applications}
              />
            )}

            {selectedPeriod && (
              <Button
                disabled={!canMakeDecision()}
                size={'sm'}
                onClick={() => {
                  setIsModalOpened(true);
                }}>
                {t`Ta beslut`}
              </Button>
            )}
          </GrantMeTitleContainer>
          <ApprovalInformation period={selectedPeriod} />
          {selectedPeriod.isReviewable && (
            <Group align={'center'} pl={28} pr={16} spacing={0} className={classes.bottomBorder}>
              <LiitDropdown
                emptyText={'Föreslaget belopp (genomsnitt)'}
                menuItems={reviewers.map((r) => {
                  const menuItem: LiitDropdownMenuItem = {
                    id: r.id,
                    text: `${r.firstname} ${r.lastname}`,
                  };
                  return menuItem;
                })}
                selectedId={selectedReviewerId}
                onChange={setSelectedReviewerId}
              />
            </Group>
          )}
          <Flex style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <LiitGrid
              data={applications}
              setData={setApplications}
              onCellValueChanged={async () => refreshApprovalPeriod()}
              columnInfo={ApprovalApplicationListColumns(selectedPeriod)}
              onRowClick={(id: string) => {
                navigate(`/approvals/${applicationPeriodId}/${id}`);
              }}
              highligthOnHoover
              fetchingData={isFetching}
              startSort={'number'}
            />
          </Flex>
        </Stack>
      </GrantMeContainer>

      <ApprovalApplicationPeriodCompletionModal
        applicationPeriod={selectedPeriod}
        isOpened={isModalOpened}
        onClose={async (doRefresh: boolean) => {
          setIsModalOpened(false);
          if (doRefresh) {
            await refresh();
          }
        }}
      />
    </>
  );
};
