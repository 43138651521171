import { ActionIcon, Group, Menu, Tooltip, useMantineTheme, Text, Badge } from '@mantine/core';
import { IconArrowLeft, IconArrowRight, IconList, IconPrinter, IconTags } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'ttag';
import { isMissing } from 'utilitype';
import { ApplicationStatusBadge } from '../../common/application/ApplicationStatusBadge';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { Application } from '../../../models/Application';
import { Tag } from '../../../models/Tag';
import { NextAndPreviousApplication } from '../../../models/NextAndPreviousApplication';
import { useApplicationQueryParameters } from '../../../hooks/useApplicationQueryParameters';
import { ApplicationQueryType } from '../../../models/ApplicationItem';

interface ViewNavigationProps {
  application: Application;
  tags: Tag[] | undefined;
  addTag: (tag: Tag) => void;
}

export const ViewNavigation: React.FC<ViewNavigationProps> = ({
  application,
  tags,
  addTag,
}) => {
  const theme = useMantineTheme();
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const [tooltipPrevious, setTooltipPrevious] = useState(false);
  const [tooltipNext, setTooltipNext] = useState(false);
  const [tooltipList, setTooltipList] = useState(false);
  const [tooltipPrint, setTooltipPrint] = useState(false);
  const [tooltipTags, setTooltipTags] = useState(false);
  const [nextAndPrevious, setNextAndPrevious] = useState<NextAndPreviousApplication>({} as NextAndPreviousApplication);
  const navigate = useNavigate();
  const [triggerFetch, setTriggerFetch] = useState(true);
  const [parameters, setParameters] = useApplicationQueryParameters();

  useEffect(() => {
    const fetchData = async () => {

      if (parameters && triggerFetch) {       
        if (parameters.type === ApplicationQueryType.All || parameters.type === ApplicationQueryType.Reviewed || parameters.type === ApplicationQueryType.ToReview) {
          // TODO: implement next and previous for all query types. Until then, next and previous button will be disabled.
          const nextPreviousResponse = await api.getNextAndPreviousApplication(selectedOrganisation!.id, application.id, parameters);
          setNextAndPrevious(nextPreviousResponse);
        }
      }     

      if (!triggerFetch) {
        setTriggerFetch(true);
      }

    };

    fetchData();
  }, [application, parameters]);  

  const downloadPdf = async () => {
    if (selectedOrganisation && application && application.id) {
      const result = await api.downloadApplicationPdf(selectedOrganisation.id, application.id);

      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', application?.number + '.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }
  };

  const getTooltipTextPrevious = () => {
    return t`Föregående ansökan ${nextAndPrevious?.previousApplicationNumber}`;
  };

  const getTooltipTextNext = () => {
    return t`Nästa ansökan ${nextAndPrevious?.nextApplicationNumber}`;
  };

  const getRoute = (): string => {
    switch (parameters?.type) {

      case ApplicationQueryType.ToReview:
        return '/reviews/todo';
      case ApplicationQueryType.Reviewed:
        return '/reviews/completed';
      case ApplicationQueryType.Reports:
        return '/applicationreports';
      case ApplicationQueryType.Unpaid:
        return '/payments/unpaid';
      case ApplicationQueryType.Paid:
        return '/paymentbatches';
      case ApplicationQueryType.Archive:
        return `/archive/applicationperiods/${application.periodId}`;       
      case ApplicationQueryType.Approvals:
        return `/approvals/${application.periodId}`;
      case ApplicationQueryType.All:
        return '/applications';
      default:
        throw new Error('Unknown type: ' + parameters?.type);
    }
  };

  const backToList = () => {
    navigate(getRoute());
  };

  const getUnusedTags = () => {
    if (tags) {
      return tags.filter(a => application.tags.findIndex(at => at.id === a.id) === -1);
    }

    return [];
  };

  return (
    <Group position={'right'} spacing={'md'} noWrap>
      <ApplicationStatusBadge status={application.status} />

      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Tooltip
            label={t`Kategorier`}
            withArrow
            onMouseEnter={() => setTooltipTags(true)}
            onMouseLeave={() => setTooltipTags(false)}
            opened={tooltipTags}
            color={theme.primaryColor}>
            <ActionIcon size={'md'} variant={'light'} color={'blue'}>
              <IconTags />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Kategorier</Menu.Label>
          <Menu.Divider/>
          {getUnusedTags().length === 0 &&
          <Menu.Item onClick={() => navigate('/settings/categories')}>
          <Text>Du har inga kategorier. Gå till Inställningar/Kategorier eller klicka här för att skapa några.</Text>
          </Menu.Item>
          }
          {getUnusedTags().map((tag) =>
            <Menu.Item onClick={async () => addTag(tag)} key={tag.id}>
              <Badge key={tag.id} size="md" color={tag.color} radius="md" variant="dot">{tag.name}</Badge>
            </Menu.Item>,
          )}

        </Menu.Dropdown>
      </Menu>

      <Tooltip
        label={'Visa PDF'}
        withArrow
        onMouseEnter={() => setTooltipPrint(true)}
        onMouseLeave={() => setTooltipPrint(false)}
        opened={isMissing(print) ? false : tooltipPrint}
        color={theme.primaryColor}>
        <ActionIcon
          data-testid={'print-button'}
          size={'md'}
          variant="light"
          color={'blue'}
          onClick={async () => {
            await downloadPdf();
          }}>
          <IconPrinter />
        </ActionIcon>
      </Tooltip>

      <Tooltip
        label={t`Tillbaka till listan`}
        withArrow
        onMouseEnter={() => setTooltipList(true)}
        onMouseLeave={() => setTooltipList(false)}
        opened={tooltipList}
        color={theme.primaryColor}>
        <ActionIcon
          data-testid={'next-button'}
          size={'md'}
          variant="light"
          color={'blue'}
          onClick={() => {
            backToList();
          }}>
          <IconList />
        </ActionIcon>
      </Tooltip>

      <Tooltip
        label={getTooltipTextPrevious()}
        withArrow
        onMouseEnter={() => setTooltipPrevious(true)}
        onMouseLeave={() => setTooltipPrevious(false)}
        opened={tooltipPrevious}
        color={theme.primaryColor}>
        <ActionIcon
          data-testid={'previous-button'}
          size={'md'}
          variant={'light'}
          color={'blue'}
          disabled={isMissing(nextAndPrevious?.previousApplicationId)}
          onClick={() => {
            if (parameters.page !== nextAndPrevious.previousPage) {
              setTriggerFetch(false);
              setParameters({ ...parameters, page: nextAndPrevious.previousPage });
            }            
            navigate(`${getRoute()}/${nextAndPrevious?.previousApplicationId}`);
          }}>
          <IconArrowLeft />
        </ActionIcon>
      </Tooltip>

      <Tooltip
        label={getTooltipTextNext()}
        withArrow
        onMouseEnter={() => setTooltipNext(true)}
        onMouseLeave={() => setTooltipNext(false)}
        opened={tooltipNext}
        color={theme.primaryColor}>
        <ActionIcon
          data-testid={'next-button'}
          size={'md'}
          variant={'light'}
          color={'blue'}
          disabled={isMissing(nextAndPrevious.nextApplicationId)}
          onClick={() => {
            if (parameters.page !== nextAndPrevious.nextPage) {
              setTriggerFetch(false);
              setParameters({ ...parameters, page: nextAndPrevious.nextPage });
            }
            navigate(`${getRoute()}/${nextAndPrevious.nextApplicationId}`);
          }}>
          <IconArrowRight />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};
