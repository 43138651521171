import React, { useEffect, useState } from 'react';
import { ActionIcon, TextInput } from '@mantine/core';
import { IconArrowLeft, IconSquareRoundedChevronLeft } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { Navigation } from '../layout/Navigation';
import { Routes } from '../layout/Routes';
import { useDebouncedState } from '@mantine/hooks';

type DebouncedTextInputProps = {
  delay: number;
  placeholder: string;
  defaultValue: string | undefined | null;
  onChange: (string) => void;
};

export const DebouncedTextInput: React.FC<DebouncedTextInputProps> = ({ delay, placeholder, defaultValue, onChange }) => {
  const [value, setValue] = useDebouncedState(defaultValue, delay);
  const [isInitialized, setIsInitialized] = useState(false);
  
  useEffect(() => {
    if (isInitialized) {
      onChange(value);
    } else {
      setIsInitialized(true);
    }


  }, [value]);  

  return (
    <TextInput
      variant="unstyled"
      defaultValue={defaultValue ? defaultValue : undefined}
      placeholder={placeholder}   
      onChange={(event) => setValue(event.currentTarget.value)}
    />
  );
};
