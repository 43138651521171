import { Paper, Stack, Title, Grid, Drawer } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import { FormModel } from './models/FormModel';
import { FormPage } from './models/FormPage';
import { FormElement } from './models/FormElement';
import { FormElementComponent } from './FormElementComponent';
import { FormElementMode } from './FormElementMode';
import LanguageHandler from '../../utils/languageHandler';
import { LanguageCode } from '../../models/LanguageCode';
import { t } from 'ttag';
import { FormElementType } from './models/FormElementType';
import { ContactPersonValue } from './FormElements/ContactPerson/ContactPersonValue';
import React from 'react';
// import { ActiveAttachmentContextProvider } from './ActiveAttachmentProvider';
import { LiitDrawer } from '../LiitDrawer/LiitDrawer';

interface RunnerProps {
  json: string;
  showPageLabels: boolean;
}

export const FormViewer: FC<RunnerProps> = ({ json }) => {
  const [model, setModel] = useState<FormModel>({ pages: [] } as FormModel);
  
  useEffect(() => {
    const result: FormModel = JSON.parse(json);
    setModel(result ?? []);
  }, [json]);

  const renderElements = (page: FormPage, startNumber: number) => {
    const filtered = page.elements.filter(
      (x) =>
        !(x.type === FormElementType.ContactPerson && !(x.value as ContactPersonValue).isByProxy),
    );

    return filtered.map((element: FormElement, index: number) => {
      const key = `element_${element.id}_${index}`;

      return (
        <Grid.Col md={12} lg={element.size} key={key}>
          <FormElementComponent
            number={startNumber + index}
            element={element}
            mode={FormElementMode.Viewer}
          />
        </Grid.Col>
      );
    });
  };

  return (
    <Stack spacing={'xs'}>
      {model.pages.map((page: FormPage, index) => {
        const pageNumber = index + 1;
        const elements = renderElements(page, pageNumber);
        const pageLabel = LanguageHandler.getTextByLanguage(page.label, LanguageCode.sv);

        return (
          <Paper radius={0} p="md" key={page.id + pageLabel + pageNumber + elements.length}>
            <Title order={3} pb={'md'}>
              {pageLabel ?? t`Sida ${pageNumber}`}
            </Title>
            <Grid>{elements}</Grid>
          </Paper>
        );
      })}
    </Stack>
    // <ActiveAttachmentContextProvider>
    
  // </ActiveAttachmentContextProvider>
  );
};
