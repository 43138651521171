import { MantineSize } from '@mantine/core';
import { PropertyType } from './PropertyType';
import { RenderCell } from './RenderCell';

export enum HideCondition {
  LargerThan,
  SmallerThan,
}
export interface IDisplayColumn {
  displayName: string;
  propertyName: string;
  propertyType: PropertyType;
  renderCell?: RenderCell;
  columnStyles?: React.CSSProperties;
  disableClick?: boolean;
  hideFromPrint?: boolean;
  disableSort?: boolean;
  hideAt?: { size: MantineSize; type: HideCondition };
  maxLength?: number;
}
