import { IDisplayColumn } from '../../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import { RenderAmountCell } from '../../common/grid-columns/RenderAmountCell';
import { RenderApplicantCell } from '../../common/grid-columns/RenderApplicantCell';
import { RenderCategoryCell } from '../../common/grid-columns/RenderCategoryCell';
import { RenderCompletionStatusCell } from '../../common/grid-columns/RenderCompletionStatusCell';
import { RenderReviewStatusCell } from '../../common/grid-columns/RenderReviewStatusCell';
import { RenderTagsCell } from '../../common/grid-columns/RenderTagsCell';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const ReviewApplicationViewColumns = (includeTagsColumn: boolean): Array<IDisplayColumn> => {

  const columns = new Array<IDisplayColumn>();
  columns.push(  {
    displayName: '',
    propertyName: 'categoryColor',
    propertyType: PropertyType.Text,
    renderCell: RenderCategoryCell,
    columnStyles: { ...columnStyles, padding: 0 },
  });

  columns.push(  {
    displayName: 'Diarienr',
    propertyName: 'number',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, paddingLeft: '16px', paddingRight: 0 },
  });

  columns.push(  {
    displayName: 'Inkom',
    propertyName: 'submittedUtc',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  });

  columns.push(  {
    displayName: 'Sökande',
    propertyName: 'applicantName',
    propertyType: PropertyType.Text,
    renderCell: RenderApplicantCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
    hideFromPrint: true,
  });

  columns.push(  {
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    columnStyles: { cursor: 'pointer', userSelect: 'none' },
    maxLength: 100,
  });

  if (includeTagsColumn) {
    columns.push(  {
      displayName: 'Kategorier',
      propertyName: 'tags',
      renderCell: RenderTagsCell,
      propertyType: PropertyType.Text,
      columnStyles: { width: '15%', cursor: 'pointer', userSelect: 'none', whiteSpace: 'normal' },    
    });
  }

  columns.push(  {
    displayName: 'Komplettering',
    propertyName: 'completionStatus',
    renderCell: RenderCompletionStatusCell,
    propertyType: PropertyType.Text,    
    columnStyles: { width: '8%', cursor: 'pointer', userSelect: 'none', whiteSpace: 'normal' },    
  });
  
  columns.push(  {
    displayName: 'Sökt belopp',
    propertyName: 'requestedAmount',
    propertyType: PropertyType.Currency,
    renderCell: RenderAmountCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },  
  });

  columns.push(  {
    displayName: 'Bedömning',
    propertyName: 'reviewStatus',
    propertyType: PropertyType.Text,
    disableSort: true,
    renderCell: RenderReviewStatusCell,
    columnStyles: { ...columnStyles, padding: 0, paddingLeft: '16px', paddingRight: '8px' },
  });

  return columns;
};