import React from 'react';
import { Button, Group, Stack, Textarea, Text, Box, Flex, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Application } from '../../../models/Application';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { CompletionComments } from '../common/CompletionComments';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { t } from 'ttag';
import { WayOfCreation } from '../../../models/WayOfCreation';
import { IconInbox, IconInfoCircle, IconInfoSquare } from '@tabler/icons';

interface RequestCompletionProps {
  application: Application;
  onCompletionRequested: () => void;
}

export const RequestCompletion: React.FC<RequestCompletionProps> = ({
  application,
  onCompletionRequested,
}) => {
  const api = useApi();
  const { selectedOrganisation } = useSession();
  const theme = useMantineTheme();

  const form = useForm({
    initialValues: {
      completionComment: '',
    },
    validate: {
      completionComment: (value) => (!value ? 'Du måste ange en kommentar' : null),
    },
  });

  const save = async (values) => {
    const response = await api.requestCompletion(
      selectedOrganisation!.id,
      application.id,
      values.completionComment,
    );

    if (response.ok) {
      onCompletionRequested();
    }

    form.setFieldValue('completionComment', '');
  };

  const canOpenCompletion = application.status === ApplicationStatus.Submitted;

  if (
    application.wayOfCreation !== WayOfCreation.Client &&
    application.wayOfCreation !== WayOfCreation.Automatic
  ) {
    const importTypeText =
      application.wayOfCreation === WayOfCreation.ImportMany ? t`importerad` : t`manuellt införd`;

    return (
      <Flex p={40} align={'center'} justify={'center'}>
        <Text align={'center'} size={'sm'} color={'dimmed'}>
          {t`En ${importTypeText} ansökan går inte att öpppna upp för komplettering.`}
        </Text>
      </Flex>
    );
  }

  return (
    <form onSubmit={form.onSubmit((values) => save(values))}>
      <Stack>
        {application.completionComments.length > 0 && (
          <CompletionComments comments={application.completionComments} />
        )}
        <Textarea
          minRows={3}
          placeholder={
            canOpenCompletion
              ? t`Skriv en föklaring över vad som behöver kompletteras.`
              : t`Skriv ett meddelande.`
          }
          label="Kommentar:"
          {...form.getInputProps('completionComment')}
        />
        <Group position="right">
          <Button type="submit">
            {canOpenCompletion ? t`Begär komplettering` : t`Skicka meddelande`}
          </Button>
        </Group>
      </Stack>
    </form>
  );
};
