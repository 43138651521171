import { Stack } from '@mantine/core';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { PrintButton } from '../../components/Printing/PrintButton';
import Formatter from '../../utils/formatter';

export interface DraftListPrintButtonProps {
  period: any;
  applications: any;
  decision: any | null;
  disabled: boolean | undefined;
}

export const DraftListPrintButton: React.FC<DraftListPrintButtonProps> = ({
  period,
  applications,
  disabled,
  decision,
}) => {
  const getBiasedReviews = () => {
    const list = applications.flatMap((app) => {
      return app.biasedReviewers.map((x) => ({
        id: app.number,
        number: app.number,
        reviewer: x,
      }));
    });
    return list;
  };

  const [biasedReviews, setBiasedReviews] = useState(getBiasedReviews());

  return (
    <PrintButton disabled={disabled} title="Skriv ut" landscape={true}>
      <Stack p={0}>
        <h2>{period.title}</h2>
        <br />
        <table>
          <tr>
            <td>
              <b>Ansökningsperiod</b>
              <br />
              {Formatter.formatDate(DateTime.fromISO(period.startDate).toJSDate())} -{' '}
              {Formatter.formatDate(DateTime.fromISO(period.endDate).toJSDate())}
            </td>
            <td>
              <b>Beviljat belopp</b>
              <br />
              {Formatter.formatCurrency(
                decision ? decision.grantedAmount : period.preliminaryGrantedAmount,
              )}
            </td>
            <td>
              <b>Budget</b>
              <br />
              {Formatter.formatCurrency(period.budget)}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <b>Inkomna ansökningar</b>
              <br />
              {period.numberOfSubmittedApplications}
            </td>
            <td>
              <b>Beviljade ansökningar</b>
              <br />
              {decision
                ? decision.numberOfApprovedApplications
                : period.numberOfPreliminaryApprovedApplications}
            </td>
            <td>
              <b>Nekade ansökningar</b>
              <br />
              {decision
                ? decision.numberOfDeclinedApplications
                : period.numberOfPreliminaryDeclinedApplications}
            </td>
          </tr>
        </table>
        <br />
        <h3>Ansökningar</h3>
        <table>
          <thead>
            <tr>
              <th>
                Diarienr
              </th>
              <th>
                Mottagare
              </th>
              <th>
                Titel
              </th>
              <th>
                Granskningar
              </th>
              <th>
                Rank
              </th>
              <th>
                Medel
              </th>
              <th className='nowrap'>
                Rek. belopp
              </th>
            </tr>
          </thead>
          <tbody>
            {applications
              // .filter(
              //   (application) =>
              //     application.status === ApplicationStatus.PreliminaryApproved ||
              //     application.status === ApplicationStatus.Approved,
              // )
              .map((application) => (
                <React.Fragment key={'app_print_' + application.id}>
                  <tr>
                    <td className='nowrap'>{application.number}</td>
                    <td className='nowrap'>{application.applicantName}</td>
                    <td>{application.title}</td>
                    <td className='nowrap'>{application.reviewCompletedCount + '/' + application.reviewMaxCount}</td>
                    <td className='nowrap'>{application.ranking ?? '-'}</td>
                    <td className='nowrap'>
                      {application.reviewTotalAverage
                        ? Math.round((application.reviewTotalAverage + Number.EPSILON) * 100) / 100
                        : '-'}
                    </td>
                    <td className='nowrap'>{application.grantedAmount}</td>
                  </tr>
                  {application.reviewCompletionStatus &&
                    application.reviewCompletionStatus.length > 0 &&
                    application.reviewCompletionStatus.some((x) => x.comment) && (
                      <tr>
                        <td colSpan={7}>
                          <ul>
                            {application.reviewCompletionStatus
                              .filter((x) => x.comment)
                              .map((review) => (
                                <li key={'app_print_' + application.id + '_comment' + review.name}>
                                  {review.comment}
                                </li>
                              ))}
                          </ul>
                        </td>
                      </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
        {biasedReviews && biasedReviews.length > 0 && (
          <>
            <br />
            <h3>Jävsanmälningar</h3>
            <table>
              <tr>
                <td>
                  <b>Diarienr</b>
                </td>
                <td>
                  <b>Granskare</b>
                </td>
              </tr>
              {biasedReviews.map((review) => (
                <tr key={'app_print_' + review.id}>
                  <td>{review.number}</td>
                  <td>{review.reviewer}</td>
                </tr>
              ))}
            </table>
          </>
        )}
      </Stack>
    </PrintButton>
  );
};
