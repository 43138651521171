import { Button, Menu } from '@mantine/core';
import React from 'react';
import { t } from 'ttag';
import { FormElementType } from '../../models/FormElementType';

import { RepeaterToolboxItemInformation, RepeaterToolBoxItems } from './RepeaterToolBoxItems';

interface RepeaterToolboxProps {
  addElement: (elementType: FormElementType) => void;
}

export const RepeaterToolbox: React.FC<RepeaterToolboxProps> = ({ addElement }) => {
  const mapItems = (itemType: FormElementType) => {
    const item = RepeaterToolboxItemInformation[itemType];
    const { type, icon, label } = item;

    return (
      <Menu.Item key={type} icon={icon} onClick={() => addElement(type)}>
        {label}
      </Menu.Item>
    );
  };

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button variant="light" size={'xs'}>
          {t`Lägg till element`}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>{RepeaterToolBoxItems.map(mapItems)}</Menu.Dropdown>
    </Menu>
  );
};
