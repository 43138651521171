import { Divider, Grid, Space, Stack } from '@mantine/core';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { PrintButton } from '../../components/Printing/PrintButton';
import { ApplicationStatus } from '../../models/ApplicationStatus';
import Formatter from '../../utils/formatter';

export interface DecisionListPrintButtonProps {
  period: any;
  applications: any;
  decision: any | null;
  disabled: boolean | undefined;
}

export const DecisionListPrintButton: React.FC<DecisionListPrintButtonProps> = ({
  period,
  applications,
  disabled,
  decision,
}) => {
  const getBiasedReviews = () => {
    const list = applications.flatMap((app) => {
      return app.biasedReviewers.map((x) => ({
        id: app.number,
        number: app.number,
        reviewer: x,
      }));
    });
    return list;
  };

  const [biasedReviews, setBiasedReviews] = useState(getBiasedReviews());

  return (
    <PrintButton disabled={disabled} title="Skriv ut">
      <Stack>
        <h2>{period.title}</h2>
        <br />
        <table>
          <tr>
            <td>
              <b>Ansökningsperiod</b>
              <br />
              {Formatter.formatDate(DateTime.fromISO(period.startDate).toJSDate())} -{' '}
              {Formatter.formatDate(DateTime.fromISO(period.endDate).toJSDate())}
            </td>
            <td>
              <b>Beviljat belopp</b>
              <br />
              {Formatter.formatCurrency(
                decision ? decision.grantedAmount : period.preliminaryGrantedAmount,
              )}
            </td>
            <td>
              <b>Budget</b>
              <br />
              {Formatter.formatCurrency(period.budget)}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <b>Inkomna ansökningar</b>
              <br />
              {period.numberOfSubmittedApplications}
            </td>
            <td>
              <b>Beviljade ansökningar</b>
              <br />
              {decision
                ? decision.numberOfApprovedApplications
                : period.numberOfPreliminaryApprovedApplications}
            </td>
            <td>
              <b>Nekade ansökningar</b>
              <br />
              {decision
                ? decision.numberOfDeclinedApplications
                : period.numberOfPreliminaryDeclinedApplications}
            </td>
          </tr>
        </table>

        <br />
        <h3>Ansökningar</h3>
        <table>
          <thead>
            <tr>
              <th>
                Diarienr
              </th>
              <th>
                Titel
              </th>
              <th>
                Beviljat belopp
              </th>
            </tr>
          </thead>
          <tbody>
            {applications
              .filter(
                (application) =>
                  application.status === ApplicationStatus.PreliminaryApproved ||
                  application.status === ApplicationStatus.Approved,
              )
              .map((application) => (
                <tr key={'app_print_' + application.id}>
                  <td className='nowrap'>{application.number}</td>
                  <td>{application.title}</td>
                  <td className='nowrap'>{application.grantedAmount}</td>
                </tr>
              ))}
          </tbody>
        </table>
        {biasedReviews && biasedReviews.length > 0 && (
          <>
            <br />
            <h3>Jävsanmälningar</h3>
            <table>
              <tr>
                <td>
                  <b>Diarienr</b>
                </td>
                <td>
                  <b>Granskare</b>
                </td>
              </tr>
              {biasedReviews.map((review) => (
                <tr key={'app_print_' + review.id}>
                  <td>{review.number}</td>
                  <td>{review.reviewer}</td>
                </tr>
              ))}
            </table>
          </>
        )}
      </Stack>
    </PrintButton>
  );
};
