import { useIsAuthenticated } from '@azure/msal-react';
import { useSessionStorage } from '@mantine/hooks';
import React, { useState, useEffect, createContext, useMemo } from 'react';
import { isPresent } from 'utilitype';
import { useApi } from '../hooks/useApi';
import { Organisation } from '../models/Organisation';
import { UserSession } from '../models/UserSession';
import { SessionStorageKeys } from '../components/LiitDrawer/LiitDrawer';
import { AttachmentListItem } from '../models/AttachmentListItem';

export interface Counters {
  deletedApplications: number;
  reviewedApplications: number;
  submittedApplications: number;
}

export type SessionContextType = {
  sessionFetched: boolean;
  agreementsToApprove: boolean;
  organisations: Organisation[];
  selectedOrganisation: Organisation | undefined;
  // eslint-disable-next-line no-unused-vars
  selectOrganisation: (organisation: Organisation) => void;
  refreshSession: () => void;
  counters: Counters;
  refreshCounters: () => void;
};

export const SessionContext = createContext<Partial<SessionContextType>>({});

type Props = {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
};

export const SessionProvider: React.FC<Props> = ({ children }) => {
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [sessionObject, setSessionObject] = useState<UserSession>();
  const organisationKey = 'organisationId';
  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation>();
  const isAuthenticated = useIsAuthenticated();
  const [counters, setCounters] = useState<Counters>();

  const api = useApi();
  
  const [, setActiveAttachment] = useSessionStorage<string>({
    key: SessionStorageKeys.ActiveImage,
    defaultValue: '',
  });

  const [, setAttachments] = useSessionStorage<AttachmentListItem[]>({
    key: SessionStorageKeys.ApplicationAttachments,
    defaultValue: [],
  });

  const refreshSession = async () => {
    const userSession = await api.getUserSession();

    setSessionObject(userSession);

    if (userSession.organisations.length === 1) {
      setSelectedOrganisation(userSession.organisations[0]);
    } else {
      const storedOrganisationId = window.sessionStorage.getItem(organisationKey);
      if (storedOrganisationId) {
        const organisation = userSession.organisations.find((x) => x.id === storedOrganisationId);
        if (organisation) {
          setSelectedOrganisation(organisation);
        }
      }
    }
  };

  const refreshCounters = async () => {
    const result = await api.getApplicationCounters(selectedOrganisation!.id);
    setCounters(result);
  };

  const selectOrganisation = async (organisation: Organisation) => {
    window.sessionStorage.setItem(organisationKey, organisation.id);
    setSelectedOrganisation(organisation);
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchData = async () => {
      await refreshCounters();
    };

    fetchData();

  }, [selectedOrganisation]);


  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchData = async () => {
      await refreshSession();
    };

    fetchData().finally(() => {
      setActiveAttachment('');
      setAttachments([]);
    });
  }, [isAuthenticated]);

  return (
    <SessionContext.Provider
      value={useMemo(
        () => ({
          sessionFetched: isPresent(sessionObject),
          ...sessionObject,
          selectedOrganisation,
          selectOrganisation,
          refreshSession,
          refreshCounters,
          counters,
        }),
        [selectedOrganisation, organisations, sessionObject, counters],
      )}>
      {children}
    </SessionContext.Provider>
  );
};
