import { Text, Group } from '@mantine/core';
import { ReactNode } from 'react';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';

export const RenderRankCell: RenderCell = (rowInformation: CellRenderInformation): ReactNode => {
  const { rowValues } = rowInformation;
  const { ranking } = rowValues;

  return (
    <Group spacing={'xs'} position={'right'} align={'center'}>
      <Text>{ranking ?? '-'}</Text>
    </Group>
  );
};
