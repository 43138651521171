import React from 'react';
import { Title } from '@mantine/core';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElement } from '../../models/FormElement';

type SectionTitleProps = {
  element: FormElement;
} & FormElementComponentProps;

export const SectionTitle: React.FC<SectionTitleProps> = ({ element }) => {
  const { label } = element;

  const labelText = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);

  return (
    <Title order={4} pt="xl">
      {labelText}
    </Title>
  );
};
