import { Stack, Group, Button, Input, Text, TextInput, Box, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';
import { isMissing } from 'utilitype';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { OrganisationUser } from '../../models/OrganisationUser';
import { t } from 'ttag';
import { IconAt } from '@tabler/icons';
import { INPUT_MAX_LENGTH } from '../../App';
import { showFailNotification, showSuccessNotification } from '../../utils/notificationHelper';

type FormValues = {
  firstname: string;
  lastname: string;
  email: string;
  role: string;
};

interface OrganisationUserFormProps {
  onSave: () => void;
  user: OrganisationUser | null;
}

export const OrganisationUserForm: React.FC<OrganisationUserFormProps> = ({ onSave, user }) => {
  const form = useForm<FormValues>({
    initialValues: {
      firstname: user?.firstname ?? '',
      lastname: user?.lastname ?? '',
      email: user?.email ?? '',
      role: Number(user?.role).toString() ?? '0',
    },
    validate: {
      firstname: (value) => (value || value.length > 0 ? null : 'Förnamn måste anges'),
      lastname: (value) => (value || value.length > 0 ? null : 'Efternamn måste anges'),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'En giltig e-postadress måste anges'),
    },
  });

  const isNewUser = !isMissing(user) && !user.id;

  const { selectedOrganisation } = useSession();
  const api = useApi();

  const createOrUpdate = async () => {
    if (isMissing(selectedOrganisation) || isMissing(user)) {
      return;
    }

    let response;
    if (isNewUser) {
      response = await api.createOrganisationUser(selectedOrganisation.id, {
        id: '',
        firstname: form.values.firstname,
        lastname: form.values.lastname,
        email: form.values.email,
        role: Number.parseInt(form.values.role),
        isSelf: false,
        activeUtc: '',
      });
    } else {
      response = await api.updateOrganisationUser(selectedOrganisation.id, {
        ...user,
        role: Number.parseInt(form.values.role),
      });
    }

    if (response.ok) {
      onSave();
      const title = isNewUser ? t`Användare inbjuden` : t`Användare uppdaterad`;
      const message = isNewUser
        ? t`${user.firstname} ${user.lastname} har blivit inbjuden som användare`
        : t`${user.firstname} ${user.lastname}s information har uppdaterats`;
      showSuccessNotification(title, message);
      return;
    }

    showFailNotification(t`Något gick fel`, t`Något gick fel vid sparning, var god försök senare`);
  };

  if (isMissing(user)) {
    return null;
  }

  return (
    <Box w={'100%'}>
      <Stack spacing="xl">
        <form
          onSubmit={form.onSubmit(async (values) => {
            await createOrUpdate();
          })}>
          <Stack>
            {isNewUser ? (
              <>
                <Input.Wrapper label={t`Förnamn`}>
                  <TextInput {...form.getInputProps('firstname')} maxLength={INPUT_MAX_LENGTH} />
                </Input.Wrapper>
                <Input.Wrapper label={t`Efternamn`}>
                  <TextInput {...form.getInputProps('lastname')} maxLength={INPUT_MAX_LENGTH} />
                </Input.Wrapper>
              </>
            ) : (
              <Input.Wrapper label={t`Namn`}>
                <Text size="sm">{`${user.firstname} ${user.lastname}`}</Text>
              </Input.Wrapper>
            )}
            <Input.Wrapper label="E-post">
              {isNewUser ? (
                <TextInput
                  {...form.getInputProps('email')}
                  icon={<IconAt size={14} />}
                  maxLength={200}
                />
              ) : (
                <Text size="sm">{user.email}</Text>
              )}
            </Input.Wrapper>
            <Select
              {...form.getInputProps('role')}
              label="Roll"
              data={[
                { value: '0', label: 'Granskare' },
                { value: '1', label: 'Beslutsfattare' },
                { value: '2', label: 'Administratör' },
              ]}
            />
            {form.values.role === '0' && (
              <Text color="dimmed">
                Användare med rollen Granskare kan läsa och bedöma ansökningar.
              </Text>
            )}
            {form.values.role === '1' && (
              <Text color="dimmed">
                Användare med rollen Beslutsfattare har samma rättigheter som Granskare men kan
                dessutom bevilja eller neka ansökningar, se återrapporter och har tillgång till
                arkivet.
              </Text>
            )}
            {form.values.role === '2' && (
              <Text color="dimmed">
                Användare med rollen Administratör har samma rättigheter som Beslutsfattare men kan
                dessutom skapa betalfiler, administrera perioder och ändra inställningar.
              </Text>
            )}
            <Group position="right" mt="md">
              <Button type="submit">Spara</Button>
            </Group>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};
