import { isPresent } from 'utilitype';
import { ValidationResult } from '../../../../models/ValidationResult';
import { ErrorMessages } from '../../../../utils/errorMessages';
import { FormElement } from '../../models/FormElement';
import { ContactPersonOrganizationValue } from './ContactPersonOrganizationValue';

export const validateContactPersonOrganization = (element: FormElement): ValidationResult => { 
  const typedValue = element.value as ContactPersonOrganizationValue;
  
  if (!isPresent(typedValue.contactPersonFirstName.trim()) || !isPresent(typedValue.contactPersonLastName.trim())) {
    return { isValid: false, errorMessage: ErrorMessages.CONTACT_PERSON_REQUIRED };
  }

  return { isValid: true, errorMessage: '' };
};