import React, { useEffect, useState } from 'react';
import {
  Text,
  TextInput,
  Stack,
  Switch,
  Textarea,
  MultiSelect,
  Group,
  Select,
} from '@mantine/core';

import { DatePicker } from '@mantine/dates';
import { DateTime } from 'luxon';
import { useForm } from '@mantine/form';
import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { useApi } from '../../hooks/useApi';
import { useSession } from '../../hooks/useSession';
import { Criteria } from '../../models/Criteria';
import { OrganisationUser } from '../../models/OrganisationUser';
import { isMissing, isPresent } from 'utilitype';
import {
  ApplicationPeriodFormValues,
  applicationPeriodToFormValues,
} from './ApplicationPeriodToFormValues';
import { LiitFormContainer } from '../../components/LiitFormContainer/LiitFormContainer';
import { t } from 'ttag';
import { ApplicantType } from '../../models/ApplicantType';
import { LiitNumberInput } from '../../components/LiitNumberInput/LiitNumberInput';
import { IconCalendar } from '@tabler/icons';
import { PaymentOptions, PaymentOptionItems } from '../../models/PaymentOptions';
import {
  ApplicationPeriodType,
  ApplicationPeriodTypeItems,
} from '../../models/ApplicationPeriodType';
import { ProductVariant } from '../../models/ProductVariant';
import { Organisation } from '../../models/Organisation';
import { ApplicationPeriodStatus } from '../../models/ApplicationPeriodStatus';

interface ApplicationPeriodEditFormProps {
  applicationPeriod: ApplicationPeriod | null;
  selectedOrganisation: Organisation;
  onClose: () => void;
  onSave: () => void;
}

export const ApplicationPeriodEditForm: React.FC<ApplicationPeriodEditFormProps> = ({
  applicationPeriod,
  selectedOrganisation,
  onClose,
  onSave,
}) => {
  const api = useApi();
  const [criterias, setCriterias] = useState<Criteria[]>([]);
  const [users, setUsers] = useState<OrganisationUser[]>([]);
  const [nextApplicationPeriodStartDate, setNextApplicationPeriodStartDate] = useState<string>('');
  const [nextApplicationPeriodEndDate, setNextApplicationPeriodEndDate] = useState<string | null>(
    '',
  );

  const validateIsPublished = (value, values) => {
    if (value && !values.hasJsonData) {
      return 'Perioden har inget formulär';
    }

    return null;
  };

  const validateStartDate = (value, values) => {
    const startDate = DateTime.fromJSDate(value);
    const endDate = DateTime.fromJSDate(values.endDate);
    if (startDate > endDate) return 'Periodens startdatum får inte vara senare än dess slutdatum.';

    return null;
  };

  const validateEndDate = (value, values) => {
    if (values.typeSelectValue === ApplicationPeriodType.Default.toString()) {
      if (!value) return 'Slutdatum måste anges';

      const endDate = DateTime.fromJSDate(value);
      const startDate = DateTime.fromJSDate(values.startDate);
      const diffInDays = endDate.diff(startDate, 'days').as('days');

      if (diffInDays < 1) {
        return 'Periodens längd måste vara minst en dag.';
      }
    } else if (values.typeSelectValue === ApplicationPeriodType.Ongoing.toString()) {
    } else if (values.typeSelectValue === ApplicationPeriodType.Recurring.toString()) {
      if (!value) return 'Slutdatum måste anges';

      const endDate = DateTime.fromJSDate(value);
      const startDate = DateTime.fromJSDate(values.startDate);
      const diffInDays = endDate.diff(startDate, 'days').as('days');

      if (diffInDays < 1) {
        return 'Periodens längd måste vara minst en dag.';
      }

      if (diffInDays > 365) {
        return 'Periodens längd får vara max ett år.';
      }
    }

    return null;
  };

  const form = useForm<ApplicationPeriodFormValues>({
    validateInputOnBlur: true,
    initialValues: applicationPeriodToFormValues(
      applicationPeriod,
      selectedOrganisation!.productVariant,
    ),
    validate: {
      title: (value) => (!value ? 'Ange en titel' : null),
      applicantTypeSelectValue: (value) => (value === undefined ? 'Ange en målgrupp' : null),
      paymentOptionSelectValue: (value) => (isMissing(value) ? t`Ange utbetalning` : null),
      startDate: (value, values) => validateStartDate(value, values),
      endDate: (value, values) => validateEndDate(value, values),
      isPublished: (value, values) => validateIsPublished(value, values),
      reviewerIds: (value, values) =>
        values.isReviewable && (isMissing(value) || value.length) === 0
          ? 'Ange minst en granskare'
          : null,
    },
  });

  useEffect(() => {
    if (form.values.typeSelectValue === ApplicationPeriodType.Ongoing.toString()) {
      form.values.endDate = null;
    }

    setNextApplicationPeriodStartDate(
      DateTime.fromJSDate(form.values.startDate)
        .plus({ years: 1 })
        .toLocaleString(DateTime.DATE_SHORT),
    );
    setNextApplicationPeriodEndDate(
      form.values.endDate
        ? DateTime.fromJSDate(form.values.endDate)
          .plus({ years: 1 })
          .toLocaleString(DateTime.DATE_SHORT)
        : null,
    );
  }, [form.values.typeSelectValue, form.values.startDate, form.values.endDate]);

  useEffect(() => {
    if (isMissing(selectedOrganisation)) {
      setCriterias([]);
      setUsers([]);
      return;
    }

    const fetchData = async () => {
      if (selectedOrganisation) {
        setCriterias(await api.getCriterias(selectedOrganisation.id));
        setUsers(await api.getOrganisationUsers(selectedOrganisation.id));
      }
    };

    fetchData();
  }, [selectedOrganisation]);

  const save = async (values: ApplicationPeriodFormValues) => {
    if (isMissing(selectedOrganisation)) {
      return;
    }

    const { typeSelectValue, paymentOptionSelectValue, applicantTypeSelectValue, ...rest } = values;

    const saveApplicationPeriod: ApplicationPeriod = {
      ...rest,
      startDate: DateTime.fromJSDate(values.startDate).toFormat('yyyy-MM-dd'),
      endDate: values.endDate ? DateTime.fromJSDate(values.endDate).toFormat('yyyy-MM-dd') : null,
      paymentOption: Number(paymentOptionSelectValue) as PaymentOptions,
      applicantType: Number(applicantTypeSelectValue) as ApplicantType,
      type: Number(typeSelectValue) as ApplicationPeriodType,
      status: ApplicationPeriodStatus.Ongoing, // TODO: Remove this, should be included in update ...       
    };

    if (isPresent(saveApplicationPeriod.id)) {
      const response = await api.updateApplicationPeriod(
        selectedOrganisation.id,
        saveApplicationPeriod,
      );
      if (response.ok) {
        onSave();
      }
    } else {
      const response = await api.createApplicationPeriod(
        selectedOrganisation.id,
        saveApplicationPeriod,
      );

      if (response.ok) {
        onSave();
      }
    }
  };

  return (
    <form
      onSubmit={form.onSubmit(async (values) => save(values as any))}
      style={{ width: '100%', display: 'flex' }}>
      <LiitFormContainer confirmText={'Spara'} onCancel={onClose}>
        <Stack>
          <TextInput
            label="Titel"
            placeholder=""
            {...form.getInputProps('title')}
            maxLength={150}
          />
          <Textarea
            label="Beskrivning"
            autosize
            maxRows={6}
            {...form.getInputProps('description')}
          />

          {!applicationPeriod && (
            <>
              <Select
                label={t`Målgrupp`}
                withAsterisk
                placeholder={t`Välj målgrupp`}
                description={t`Observera att detta val inte går att ändra i efterhand.`}
                {...form.getInputProps('applicantTypeSelectValue')}
                data={[
                  { value: ApplicantType.Person.toString(), label: 'Privatperson' },
                  { value: ApplicantType.Organization.toString(), label: 'Organisation' },
                ]}
              />

              <Select
                label={t`Utbetalning`}
                withAsterisk
                description={t`Väl hur/om beviljade ansökningar ska utbetalas. Observera att detta val inte går att ändra i efterhand.`}
                {...form.getInputProps('paymentOptionSelectValue')}
                data={[
                  {
                    value: PaymentOptions.Default.toString(),
                    label: PaymentOptionItems[PaymentOptions.Default],
                  },
                  {
                    value: PaymentOptions.Requisition.toString(),
                    label: PaymentOptionItems[PaymentOptions.Requisition],
                  },
                  {
                    value: PaymentOptions.NoPayment.toString(),
                    label: PaymentOptionItems[PaymentOptions.NoPayment],
                  },
                ]}
              />
            </>
          )}

          <Select
            label={t`Period`}
            {...form.getInputProps('typeSelectValue')}
            data={[
              {
                value: ApplicationPeriodType.Default.toString(),
                label: ApplicationPeriodTypeItems[ApplicationPeriodType.Default],
              },
              {
                value: ApplicationPeriodType.Ongoing.toString(),
                label: ApplicationPeriodTypeItems[ApplicationPeriodType.Ongoing],
              },
              {
                value: ApplicationPeriodType.Recurring.toString(),
                label: ApplicationPeriodTypeItems[ApplicationPeriodType.Recurring],
              },
            ]}
          />

          <Group>
            <DatePicker
              allowFreeInput
              placeholder="Välj datum"
              label="Startdatum"
              {...form.getInputProps('startDate')}
              icon={<IconCalendar size={16} />}
              required
            />

            {form.values.typeSelectValue != ApplicationPeriodType.Ongoing.toString() && (
              <DatePicker
                allowFreeInput
                placeholder="Välj datum"
                label="Slutdatum"
                {...form.getInputProps('endDate')}
                icon={<IconCalendar size={16} />}
              />
            )}
          </Group>

          {form.values.typeSelectValue === ApplicationPeriodType.Recurring.toString() &&
            form.values.endDate && (
              <Text size={'sm'}>
                {t`En ny period kommer automatiskt att skapas ${nextApplicationPeriodStartDate} med slutdatum ${nextApplicationPeriodEndDate}`}
              </Text>
          )}

          <LiitNumberInput label="Budget" hideControls {...form.getInputProps('budget')} />

          {selectedOrganisation.productVariant !== ProductVariant.Small && (
            <MultiSelect
              disabled={!form.values.isReviewable}
              data={users.map((x) => ({
                label: `${x.firstname} ${x.lastname}`,
                value: x.id,
              }))}
              label="Granskare"
              placeholder="Välj vem eller vilka som ska granska ansökningar för denna perioden"
              {...form.getInputProps('reviewerIds')}
            />
          )}

          <MultiSelect
            data={criterias.map((x) => ({ label: x.title, value: x.id }))}
            label="Bedömningskriterier"
            placeholder="Välj bedömningskriterier"
            {...form.getInputProps('criteriaIds')}
          />

          {!applicationPeriod && (
            <LiitNumberInput
              label={t`Maxpoäng på bedömningskriterier`}
              placeholder=""
              {...form.getInputProps('maxCriteriaScore')}
              min={1}
              max={100}
            />
          )}

          <Switch
            error={form.errors.isPublished}
            label="Publik"
            description="Anger om perioden ska visas för ansökare eller inte.   "
            {...form.getInputProps('isPublished', {
              type: 'checkbox',
            })}
          />
          {selectedOrganisation?.productVariant === ProductVariant.Large && (
            <Switch
              error={form.errors.isReviewable}
              label="Granskningsbar"
              description="Anger om ansökningar i denna perioden ska kunna granskas eller inte."
              {...form.getInputProps('isReviewable', {
                type: 'checkbox',
              })}
            />
          )}
        </Stack>
      </LiitFormContainer>
    </form>
  );
};
