import { createStyles } from '@mantine/core';
import { BORDER_COLOR } from '../App';

export const navbarStyles = createStyles((theme) => ({
  mainLinks: {
    flex: 1,
    marginRight: -theme.spacing.sm,
  },

  mainLink: {
    textTransform: 'uppercase',
    fontSize: 14,
    color: theme.colors.gray[8],
    padding: `7px ${theme.spacing.sm}px`,
    fontWeight: 700,
    borderBottom: '2px solid transparent',
    transition: 'border-color 100ms ease, color 100ms ease',

    '&:hover': {
      color: theme.colors.gray[6],
      textDecoration: 'none',
    },
  },

  mainLinkActive: {
    background: theme.colors.gray[3],
  },

  ourNavBar: {
    border: 0,
    flexShrink: 0,
    borderRight: `1px solid ${BORDER_COLOR}`,
    background: theme.colors.gray[1],
    transition: 'width 0.3s',
  },

  linksInner: {
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xl,
    opacity: 1,
  },

  hideLinks: {
    opacity: 0,
    transition: 'opacity 0.2s',
    transitionDelay: '0s',
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));
