import { Accordion, Badge, Box } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { BORDER_COLOR } from '../../../App';
import { Application } from '../../../models/Application';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { ApplicationType } from '../../../models/ApplicationType';
import { ApplicationReviewForm } from './ApplicationReviewForm';
import { ApplicationToolbarItemDecision } from './ApplicationToolbarItemDecision';
import { ApplicationToolbarItemDelete } from './ApplicationToolbarItemDelete';
import { ApplicationToolbarItemNotification } from './ApplicationToolbarItemNotification';
import { ApplicationToolbarItemReport } from './ApplicationToolbarItemReport';
import { applicationViewStyles } from './applicationViewStyles';
import { RequestCompletion } from './RequestCompletion';
import { ApplicationToolbarItemHistory } from './ApplicationToolbarItemHistory';
import { useSession } from '../../../hooks/useSession';
import { Role } from '../../../models/Role';
import { ApplicationToolbarItemReviews } from './ApplicationToolbarItemReviews';
import { CompletionStatus } from '../../../models/CompletionStatus';
import Formatter from '../../../utils/formatter';

interface ApplicationToolbarProps {
  application: Application;
  onChanged: () => void;
}

enum ApplicationToolbarItemType {
  ReviewItem,
  ReviewList,
  CompletionItem,
  ReportItem,
  DecisionItem,
  NotificationItem,
  HistoryItem,
  DeleteItem,
  //PaymentsItem,
}

interface ApplicationToolbarItemRequirements {
  roles: Role[];
  statuses: ApplicationStatus[];
  types: ApplicationType[];
}

export const ApplicationToolBar: React.FC<ApplicationToolbarProps> = ({
  application,
  onChanged,
}) => {
  const { classes, theme } = applicationViewStyles();
  const [toolbarItems, setToolbarItems] = useState<Array<string | undefined>>([]);
  const { selectedOrganisation } = useSession();

  const toolbarItemRequirements: Record<
  ApplicationToolbarItemType,
  ApplicationToolbarItemRequirements
  > = {
    [ApplicationToolbarItemType.CompletionItem]: {
      types: [ApplicationType.Application],
      roles: [Role.Reviewer, Role.Approver, Role.Administrator],
      statuses: [
        ApplicationStatus.Submitted,
        ApplicationStatus.PreliminaryApproved,
        ApplicationStatus.PreliminaryDeclined,
      ],
    },
    [ApplicationToolbarItemType.DecisionItem]: {
      types: [ApplicationType.Application],
      roles: [Role.Approver, Role.Administrator],
      statuses: [
        ApplicationStatus.Submitted,
        ApplicationStatus.PreliminaryApproved,
        ApplicationStatus.PreliminaryDeclined,
        ApplicationStatus.Approved,
        ApplicationStatus.Declined,
      ],
    },
    [ApplicationToolbarItemType.DeleteItem]: {
      types: [ApplicationType.Application],
      roles: [Role.Approver, Role.Administrator],
      statuses: [
        ApplicationStatus.Submitted,
        ApplicationStatus.PreliminaryApproved,
        ApplicationStatus.PreliminaryDeclined,
        ApplicationStatus.Deleted,
      ],
    },
    [ApplicationToolbarItemType.ReportItem]: {
      types: [ApplicationType.Report],
      roles: [Role.Approver, Role.Administrator],
      statuses: [
        ApplicationStatus.Submitted,
        ApplicationStatus.PreliminaryApproved,
        ApplicationStatus.PreliminaryDeclined,
        ApplicationStatus.Approved,
        ApplicationStatus.Declined,
        ApplicationStatus.Deleted,
      ],
    },
    [ApplicationToolbarItemType.ReviewItem]: {
      types: [ApplicationType.Application],
      roles: [Role.Reviewer, Role.Approver, Role.Administrator],
      statuses: [
        ApplicationStatus.Submitted,
        ApplicationStatus.PreliminaryApproved,
        ApplicationStatus.PreliminaryDeclined,
      ],
    },
    [ApplicationToolbarItemType.ReviewList]: {
      types: [ApplicationType.Application],
      roles: [Role.Approver, Role.Administrator],
      statuses: [],
    },
    [ApplicationToolbarItemType.NotificationItem]: {
      types: [ApplicationType.Application],
      roles: [Role.Approver, Role.Administrator],
      statuses: [ApplicationStatus.Approved, ApplicationStatus.Declined],
    },
    [ApplicationToolbarItemType.HistoryItem]: {
      types: [ApplicationType.Application],
      roles: [Role.Reviewer, Role.Approver, Role.Administrator],
      statuses: [],
    },
    // [ApplicationToolbarItemType.PaymentsItem]: {
    //   types: [ApplicationType.Application],
    //   roles: [Role.Approver, Role.Administrator],
    //   statuses: [ApplicationStatus.Approved, ApplicationStatus.Declined],
    // },
  };

  useEffect(() => {
    const items = Object.entries(toolbarItemRequirements)
      .filter(([type, requirement]) => {
        if (requirement.roles.some((x) => x === selectedOrganisation?.role)) {
          if (requirement.types.some((x) => x === application.type)) {
            if (
              requirement.statuses.length === 0 ||
              requirement.statuses.some((x) => x === application.status)
            ) {
              return true;
            }
          }
        }
      })
      .map(([type]) => type);

    setToolbarItems(items);
  }, [application]);

  const getCompletionBadge = () => {
    if (application.completionStatus == CompletionStatus.Requested) {
      return <Badge color='orange'>Begärd {application.completionStatusModifiedUtc ? Formatter.formatDateString(application.completionStatusModifiedUtc) : ''}</Badge>;
    } else if (application.completionStatus == CompletionStatus.Completed) {
      return <Badge color='green' >Inlämnad {application.completionStatusModifiedUtc ? Formatter.formatDateString(application.completionStatusModifiedUtc) : ''}</Badge>;
    } else {
      return <></>;
    }

  };

  const getApplicationToolbarItem = (type: string | undefined) => {
    if (type == ApplicationToolbarItemType.ReviewItem.toString() && application.isReviewable) {
      return (
        <Accordion.Item key={'toolbar_' + type} value={type.toString()}>
          <Accordion.Control>Bedömning</Accordion.Control>
          <Accordion.Panel>
            <ApplicationReviewForm
              application={application}
              onStatusChanged={async () => {
                await onChanged();
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>
      );
    } else if (type == ApplicationToolbarItemType.CompletionItem.toString()) {
      return (
        <Accordion.Item key={'toolbar_' + type} value={type.toString()}>
          <Accordion.Control>Komplettering {getCompletionBadge()}</Accordion.Control>
          <Accordion.Panel>
            <RequestCompletion
              application={application}
              onCompletionRequested={async () => {
                await onChanged();
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>
      );
    } else if (type == ApplicationToolbarItemType.DecisionItem.toString()) {
      return (
        <Accordion.Item key={'toolbar_' + type} value={type.toString()}>
          <Accordion.Control>Beslut</Accordion.Control>
          <Accordion.Panel>
            <ApplicationToolbarItemDecision
              application={application}
              onDecision={async () => {
                await onChanged();
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>
      );
    } else if (type == ApplicationToolbarItemType.DeleteItem.toString()) {
      return (
        <Accordion.Item key={'toolbar_' + type} value={type.toString()}>
          <Accordion.Control>Ta bort</Accordion.Control>
          <Accordion.Panel>
            <ApplicationToolbarItemDelete
              application={application}
              onChanged={async () => {
                await onChanged();
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>
      );
    } else if (type == ApplicationToolbarItemType.ReportItem.toString()) {
      return (
        <Accordion.Item key={'toolbar_' + type} value={type.toString()}>
          <Accordion.Control>Återrapport</Accordion.Control>
          <Accordion.Panel>
            <ApplicationToolbarItemReport
              application={application}
              onChanged={async () => {
                await onChanged();
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>
      );
    } else if (
      type == ApplicationToolbarItemType.ReviewList.toString() &&
      application.isReviewable
    ) {
      return (
        <Accordion.Item key={'toolbar_' + type} value={type.toString()}>
          <Accordion.Control>Granskningar</Accordion.Control>
          <Accordion.Panel>
            <ApplicationToolbarItemReviews applicationId={application.id} />
          </Accordion.Panel>
        </Accordion.Item>
      );
    } else if (type == ApplicationToolbarItemType.NotificationItem.toString()) {
      return (
        <Accordion.Item key={'toolbar_' + type} value={type.toString()}>
          <Accordion.Control>Notifieringar</Accordion.Control>
          <Accordion.Panel>
            <ApplicationToolbarItemNotification
              application={application}
              onChanged={async () => {
                await onChanged();
              }}
            />
          </Accordion.Panel>
        </Accordion.Item>
      );
    } else if (type == ApplicationToolbarItemType.HistoryItem.toString()) {
      return (
        <Accordion.Item key={'toolbar_' + type} value={type.toString()}>
          <Accordion.Control>Historik</Accordion.Control>
          <Accordion.Panel>
            <ApplicationToolbarItemHistory application={application} />
          </Accordion.Panel>
        </Accordion.Item>
      );
      // } else if (type == ApplicationToolbarItemType.PaymentsItem.toString()) {
      //   return (
      //     <Accordion.Item key={'toolbar_' + type} value={type.toString()}>
      //       <Accordion.Control>Utbetalning</Accordion.Control>
      //       <Accordion.Panel>
      //         <Stack>
      //           {application.applicantNotifiedUtc && <>111</>}
      //           {!application.applicantNotifiedUtc && <>Inget beslut har skickats till sökanden.</>}
      //           <Button>
      //             {application.applicantNotifiedUtc ? 'Skicka på nytt' : 'Delge sökanden'}
      //           </Button>
      //         </Stack>
      //       </Accordion.Panel>
      //     </Accordion.Item>
      //   );
    } else {
      return <React.Fragment key={'toolbar_' + type} />;
    }
  };

  if (!toolbarItems || toolbarItems.length === 0) {
    return <></>;
  }

  return (
    <Box w={400} miw={400} maw={400} className={classes.reviewControls}>
      <Accordion
        defaultValue={ApplicationToolbarItemType.ReviewItem.toString()}
        styles={{
          item: {
            borderBottom: `1px solid ${BORDER_COLOR}`,
          },
          panel: { border: 0 },
        }}>
        {toolbarItems.map((type) => getApplicationToolbarItem(type))}
      </Accordion>
    </Box>
  );
};
