import { SizeItems } from './FormElements/ElementSizes';


export const getNewSize = (isSizeUp: boolean, size: number): number | null => {
  const keys = Object.keys(SizeItems).map(k => Number(k));
  const keyIndex = keys.indexOf(size ?? 12);

  if (isSizeUp === false && keyIndex > 0) {
    return keys[keyIndex - 1]; 
  }

  if (isSizeUp && keys.length > keyIndex - 1) { 
    return keys[keyIndex + 1];
  }

  return null;
};