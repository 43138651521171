export enum FormElementType {
  TypedTextBox = 0,
  TextArea = 1,
  Dropdown = 2,
  FileGroup = 3,
  CheckboxGroup = 4,
  RadioButtonGroup = 5,
  AmountsTable = 6,
  Repeater = 7,
  SectionTitle = 8,
  DatePickerElement = 9,
  ContactPerson = 10,
  ContactPersonOrganization = 11,
  IdentityNumberPerson = 12,
  IdentityNumberOrganization = 13,
  WayOfPayment = 14,
}
