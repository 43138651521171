import { t } from 'ttag';

export enum PaymentOptions {
  Default = 0,
  Requisition = 1, 
  NoPayment = 2,
}

export const PaymentOptionItems: Record<PaymentOptions, string> = { 
  [PaymentOptions.Default]: t`Standard`,
  [PaymentOptions.Requisition]: t`Rekvirering`,
  [PaymentOptions.NoPayment]: t`Ingen utbetalning`,
};