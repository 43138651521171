import { createStyles, Group, Table, Text } from '@mantine/core';
import React from 'react';
import {
  ApplicationReviewModel,
  ApplicationReviewScore,
} from '../../../models/ApplicationReviewModel';
import cls from 'classnames';

const useStyles = createStyles((theme) => ({
  scoreLine: {
    display: 'flex',
    flexDirection: 'row',
  },
  scoreContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  criteria: {
    flex: 1,
  },
  scoreDots: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '16px',
  },
  scoreDot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginLeft: 5,
    backgroundColor: 'lightgray',
    '&.reached': {
      backgroundColor: theme.colors[theme.primaryColor][5],
    },
  },
}));

interface ReviewScoreProps {
  review: ApplicationReviewModel;
}

const MAX_SCORE = 5;

export const ReviewScore: React.FC<ReviewScoreProps> = ({ review }) => {
  const { classes } = useStyles();

  const getScoreVisuals = (key: string, score: ApplicationReviewScore) => {
    const scoreDots = Array.from({ length: MAX_SCORE }, (_, index) => {
      return (
        <div
          key={'score_dot_' + key + '_' + score.title + '_' + index}
          className={cls(classes.scoreDot, score.score >= index + 1 ? 'reached' : '')}
        />
      );
    });

    return <div className={classes.scoreDots}>{scoreDots}</div>;
  };

  return (
    <div className={classes.scoreContainer}>
      {review.scores.map((score) => (
        <div key={review.reviewerName + score.title} className={classes.scoreLine}>
          <Text size={'sm'} className={classes.criteria}>
            {score.title}:
          </Text>
          {getScoreVisuals(review.reviewerName, score)}
          <Text size={'sm'}>{score.score}</Text>
        </div>
      ))}
    </div>
  );
};
