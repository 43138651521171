import { Text } from '@mantine/core';
import { FC } from 'react';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { FormElement } from '../../models/FormElement';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';

export const ViewerTextArea: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const text = element.value as string;
  const { labelAndTextPosition } = element as FormElement;
  return (
    <ViewerInputWrapper number={number} element={element}>
      <Text align={labelAndTextPosition ?? 'left'} size="sm" style={{ whiteSpace: 'pre-wrap' }}>{text}</Text>
    </ViewerInputWrapper>
  );
};
