import { ReactNode } from 'react';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { ActionIcon, Group, Text, useMantineTheme, Box, Popover, Flex } from '@mantine/core';
import { formatValue } from '../../../components/LiitGrid/FormatValue';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import { IconChevronRight } from '@tabler/icons';
import { useSession } from '../../../hooks/useSession';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { useApi } from '../../../hooks/useApi';
import { useDisclosure } from '@mantine/hooks';
import { t } from 'ttag';
import Formatter from '../../../utils/formatter';
import { TextWithPopover } from '../../common/grid-columns/TextWithPopover';

export const RenderSuggestedAmountCell: RenderCell = (
  rowInformation: CellRenderInformation,
): ReactNode => {
  const { cellValue, rowValues, theme } = rowInformation;
  //const theme = useMantineTheme();
  const { selectedOrganisation } = useSession();
  const api = useApi();

  const updateDecision = async () => {
    if (!selectedOrganisation) {
      return null;
    }

    const { grantedAmount } = rowValues;

    if (grantedAmount === cellValue) {
      return null;
    }

    const decision = {
      decision:
        cellValue > 0
          ? ApplicationStatus.PreliminaryApproved
          : ApplicationStatus.PreliminaryDeclined,
      grantedAmount: cellValue,
    };

    const response = await api.updateApplicationDecision(
      selectedOrganisation.id,
      rowValues.id,
      decision,
    );

    const newStatus = (await response.json()).status;

    rowInformation.setData((prev) => {
      const app = prev.find((x) => x.id === rowValues.id);
      app.status = newStatus;
      app.grantedAmount = cellValue;
      const newPrev = [...prev];
      return newPrev;
    });

    if (rowInformation.onCellValueChanged) {
      rowInformation.onCellValueChanged();
    }
  };

  const getPopoverContent = () => {
    return (
      <Flex direction={'column'} p={8} align={'start'}>
        <Text size={'xs'} weight={600}>{t`Sökt belopp`}</Text>
        <Text size={'xs'} weight={600}>
          {Formatter.formatCurrency(rowValues.requestedAmount)}
        </Text>
      </Flex>
    );
  };

  return (
    <TextWithPopover
      cellText={formatValue(cellValue, PropertyType.Currency)}
      popoverContent={getPopoverContent()}>
      {cellValue > 0 ? (
        <ActionIcon tabIndex={-1} onClick={() => updateDecision()}>
          <IconChevronRight size={14} color={theme.colors[theme.primaryColor][7]} />
        </ActionIcon>
      ) : (
        <Box w={28} />
      )}
    </TextWithPopover>
  );
};
