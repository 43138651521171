import React from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../../AuthConfig';

export const LoginView: React.FC = () => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect} authenticationRequest={loginRequest} ></MsalAuthenticationTemplate>
  );
};
