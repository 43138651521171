import { Tabs, TabsProps } from '@mantine/core';
import { isMissing } from 'utilitype';
import { BORDER_COLOR } from '../../App';
import { CONTAINER_PADDING_WIDTH } from '../../layout/Layout';

interface LiitTabsProps {
  fixedTabWith?: number;
  backgroundColor?: string;
}

type ExtendedProps = LiitTabsProps & TabsProps;

export const LiitTabs: React.FC<ExtendedProps> = ({ fixedTabWith, backgroundColor, ...props }: ExtendedProps) => {
  return (
    <Tabs
      styles={(theme) => ({
        root: {
          position: 'relative',
        },
        tab: {
          ...theme.fn.focusStyles(),
          padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
          height: 42,
          marginBottom: '0px',
          '&:nth-of-type(1)': {
            marginLeft: CONTAINER_PADDING_WIDTH - theme.spacing.md,
          },
        },
        panel: {
          borderTop: `1px solid ${BORDER_COLOR}`,
          backgroundColor: isMissing(backgroundColor) ? 'transparent' : backgroundColor,
          '&:nth-of-type(2)': {
            borderTopLeftRadius: 0,
          },
        },

        tabIcon: {
          marginRight: theme.spacing.xs,
          display: 'flex',
          alignItems: 'center',
        },

        tabsList: {
          display: 'flex',
        },
      })}
      {...props}
    />
  );
};