import { isMissing } from 'utilitype';
import { filterData } from './FilterData';

export const sortData = (
  data: Record<string, string>[],
  payload: { sortBy: string; reversed: boolean; search: string },
) => {

  if (!payload.sortBy) {
    return filterData(data, payload.search);
  }


  const allNumbers = data.every(x =>  {
    const value = x[payload.sortBy];

    return isMissing(value) || !isNaN(Number(value));
  });

  return filterData(
    [...data].sort((a, b) => {
      
      
      const aVal: string = a[payload.sortBy];
      const bVal: string = b[payload.sortBy];

      if (allNumbers) {
        if (isMissing(aVal)) {
          return payload.reversed ? -1 : 1;
        }

        if (isMissing(bVal)) {
          return payload.reversed ? 1 : -1;
        }

        const aNumber: number = Number(aVal) as number;
        const bNumber: number = Number(bVal) as number;

        if (payload.reversed) {
          return bNumber - aNumber;
        }

        return aNumber - bNumber;
      }


      if (payload.reversed) {
        return bVal.toString().localeCompare(aVal.toString());
      }

      return aVal.toString().localeCompare(bVal.toString());
    }),
    payload.search,
  );
};
