import { Grid, Text } from '@mantine/core';
import { FC } from 'react';
import { t } from 'ttag';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapperWithLabel } from '../Common/FormInputWrapperWithLabel';

export const ViewerContactPersonOrganization: FC<ViewerFormElementComponentProps> = ({
  number,
  element,
}) => {
  const { contactPersonFirstName, contactPersonLastName } = element.value;

  return (
    <Grid gutter={'lg'}>
      <Grid.Col lg={6}>
        <ViewerInputWrapperWithLabel label={t`Förnamn`} number={number} element={element}>
          <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
            {contactPersonFirstName}
          </Text>
        </ViewerInputWrapperWithLabel>
      </Grid.Col>
      <Grid.Col lg={6}>
        <ViewerInputWrapperWithLabel label={t`Efternamn`} number={number} element={element}>
          <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
            {contactPersonLastName}
          </Text>
        </ViewerInputWrapperWithLabel>
      </Grid.Col>
    </Grid>
  );
};
