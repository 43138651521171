import {
  ColorScheme,
  ColorSchemeProvider,
  Global,
  MantineProvider,
  MantineThemeOverride,
} from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { useState } from 'react';
import { Router } from './Router';
import { customColors } from './utils/customColors';
import { setLocale } from './i18nInit';
import { HEADER_HEIGHT } from './layout/MenuHeader';
import { ModalsProvider } from '@mantine/modals';
import { SessionProvider } from './providers/SessionProvider';

export const TOP_PADDING = 32;
export const INPUT_MAX_LENGTH = 200;
export const BORDER_COLOR = '#cbd0dd'; //gray[3]

const App = () => {
  setLocale('sv-SE');

  const fontFamilyInter = `${"'Inter Tight', sans-serif"}`;

  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  const customTheme: MantineThemeOverride = {
    colorScheme,
    colors: customColors,
    primaryColor: 'blue',
    primaryShade: { light: 5, dark: 4 },
    defaultRadius: 'sm',
    defaultGradient: {
      from: 'orange',
      to: 'red',
      deg: 45,
    },
    fontFamily: fontFamilyInter,
    headings: { fontFamily: `${"'Exo 2', sans-serif"}` },
    dateFormat: 'YYYY-MM-DD',
    datesLocale: 'sv',
    components: {
      Accordion: {
        styles: (theme) => ({
          label: {
            fontSize: theme.fontSizes.md,
            color: theme.colors.gray[9],
            fontWeight: 600,
            // '&[data-active]': {
            //   color: theme.colors.gray[4],
            // },
          },
        }),
      },
      Tabs: {
        styles: (theme) => ({
          tab: {
            color: theme.colors[theme.primaryColor][7],
            fontWeight: 600,
            '&[data-active]': {
              color: theme.colors[theme.primaryColor][7],
            },
          },
          tabsList: {
            border: '0px',
          },
        }),
      },
      TextInput: {
        styles: (theme) => ({
          label: {
            color: theme.colors[theme.primaryColor][7],
            fontWeight: 'bold',
            display: 'inline-flex',
          },
        }),
      },
      InputWrapper: {
        styles: (theme) => ({
          label: {
            color: theme.colors[theme.primaryColor][7],
            fontWeight: 'bold',
            display: 'inline-flex',
            gap: '8px',
            width: '100%',
          },
        }),
      },
      Table: {
        styles: (theme) => ({
          root: {
            thead: {
              tr: {
                th: {
                  background: theme.colors.gray[1],
                  color: theme.colors.gray[9],
                  borderBottom: `1px solid ${BORDER_COLOR}`,
                },
              },
            },
            tbody: {
              tr: {
                td: {
                  borderTop: `1px solid ${BORDER_COLOR}`,
                },
              },
            },
          },
        }),
      },

      Divider: {
        styles: (theme) => ({
          root: {
            borderTopColor: `${theme.colors.gray[3]} !important`,
            borderLeftColor: `${theme.colors.gray[3]} !important`,
          },
        }),
      },

      Paper: {
        styles: (theme) => ({
          root: {
            ['@media print']: {
              boxShadow: 'none',
              backgroundColor: 'transparent',
            },
          },
        }),
      },

      Button: {
        styles: {
          label: {
            textTransform: 'uppercase',
          },
          root: {
            ['@media print']: {
              display: 'none',
            },
          },
        },
      },

      ActionIcon: {
        styles: {
          root: {
            ['@media print']: {
              display: 'none',
            },
          },
        },
      },

      Title: {
        styles: (theme) => ({
          root: {
            color: theme.colors.gray[9],
            //textTransform: 'uppercase',
            fontFamily: fontFamilyInter,
            ['@media print']: {
              color: theme.black,
              fontFamily: fontFamilyInter,
            },
          },
        }),
      },

      Text: {
        styles: (theme) => ({
          root: {
            ['@media print']: {
              color: theme.black,
              fontFamily: fontFamilyInter,
            },
          },
        }),
      },
    },
  };

  const GlobalStyles = () => {
    return (
      <Global
        styles={(theme) => ({
          html: {
            boxSizing: 'border-box',
          },
          body: {
            letterSpacing: '0.5px',
            boxSizing: 'border-box',
            fontSize: theme.fontSizes.sm,
          },
        })}
      />
    );
  };

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={customTheme} withGlobalStyles withNormalizeCSS withCSSVariables>
        <ModalsProvider>
          <GlobalStyles />
          <NotificationsProvider position={'top-center'} style={{ top: 4 }}>
            <SessionProvider>
              <Router />
            </SessionProvider>
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;
