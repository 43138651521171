
export enum TextBoxInputType {
  ApplicantFirstName = 0,
  ApplicantLastName = 1,
  ApplicantOrganizationName = 2,
  ContactPersonEmail = 3,
  ContactPersonPhoneNumber = 4,
  Plain = 5,
  Email = 6,
  PhoneNumber = 7,
  RequestedAmount = 8,
  Title = 9,
  Number = 10,
}
