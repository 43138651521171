import React, { useEffect, useState } from 'react';
import { Button, Stack } from '@mantine/core';
import { Application } from '../../../models/Application';
import { useSession } from '../../../hooks/useSession';
import { useApi } from '../../../hooks/useApi';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import Formatter from '../../../utils/formatter';

interface ApplicationToolbarItemNotificationProps {
  application: Application;
  onChanged: () => void;
}

export const ApplicationToolbarItemNotification: React.FC<
  ApplicationToolbarItemNotificationProps
> = ({ application, onChanged }) => {
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(false);
  }, [application]);

  const notify = async () => {
    setIsLoading(true);
    await api.notifyApplicant(selectedOrganisation!.id, application.id);

    await onChanged();
  };

  return (
    <Stack>
      {application.applicantNotifiedUtc && (
        <>
          E-post om att ansökan har{' '}
          {application.status === ApplicationStatus.Approved ? 'beviljats' : 'nekats'} skickades
          till sökanden {Formatter.formatDateString(application.applicantNotifiedUtc)}.
        </>
      )}
      {!application.applicantNotifiedUtc && <>Inget beslut har skickats till sökanden.</>}
      <Button onClick={notify} loading={isLoading}>
        {application.applicantNotifiedUtc ? 'Skicka på nytt' : 'Delge sökanden'}
      </Button>
    </Stack>
  );
};
