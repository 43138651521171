/* eslint-disable @typescript-eslint/no-unused-vars */
import { HideCondition, IDisplayColumn } from '../../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import React from 'react';
import { RenderApplicationStatusCell } from '../../common/grid-columns/RenderApplicationStatusCell';
import { RenderAmountTextBoxCell } from './RenderAmountTextBoxCell';
import { RenderSuggestedAmountCell } from './RenderSuggestedAmountCell';
import { t } from 'ttag';
import { RenderRankCell } from '../../common/grid-columns/RenderRankCell';
import { PaymentOptions } from '../../../models/PaymentOptions';
import { RenderDecisionButtonsCell } from './RenderDecisionButtonsCell';
import { CellRenderInformation } from '../../../components/LiitGrid/RenderCell';
import { PeriodApprovalSummary } from './PeriodApprovalSummary';
import { RenderAverageCell } from '../../common/grid-columns/RenderAverageCell';
import { RenderReviewersCell } from '../../common/grid-columns/RenderReviewersCell';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const ApprovalApplicationListColumns = (
  period: PeriodApprovalSummary,
): Array<IDisplayColumn> => {
  const list: Array<IDisplayColumn> = [];

  list.push({
    displayName: 'Diarienr',
    propertyName: 'number',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, paddingRight: 0 },
  });

  list.push({
    displayName: 'Mottagare',
    propertyName: 'applicantName',
    propertyType: PropertyType.Text,
    columnStyles: { width: '30%', cursor: 'pointer' },
    hideAt: { size: 'lg', type: HideCondition.SmallerThan },
  });

  list.push({
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    columnStyles: { width: '70%', cursor: 'pointer' },
    maxLength: 80,
  });

  if (period.isReviewable) {
    list.push({
      displayName: t`Granskningar`,
      propertyName: 'reviewers',
      propertyType: PropertyType.Text,
      renderCell: RenderReviewersCell,
      columnStyles: { ...columnStyles, cursor: 'default' },
      disableSort: true,
      hideAt: { size: 'md', type: HideCondition.SmallerThan },
    });    

    list.push({
      displayName: t`Rank`,
      propertyName: 'ranking',
      propertyType: PropertyType.Number,
      renderCell: RenderRankCell,
      columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
    });

    list.push({
      displayName: t`Medel`,
      propertyName: 'reviewTotalAverage2',
      propertyType: PropertyType.Number,
      renderCell: RenderAverageCell,
      columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
    });    
  }

  list.push({
    displayName: 'Status',
    propertyName: 'status',
    propertyType: PropertyType.Text,
    renderCell: RenderApplicationStatusCell,
    columnStyles: { ...columnStyles, padding: 0, paddingLeft: '16px', paddingRight: '8px' },
  });

  if (period.paymentOption === PaymentOptions.NoPayment) {
    const noPaymentItem: IDisplayColumn = {
      displayName: 'Beslut',
      propertyName: 'status',
      propertyType: PropertyType.Text,
      renderCell: RenderDecisionButtonsCell,
      disableClick: true,
      disableSort: true,
      columnStyles: { minWidth: 'fit-content', whiteSpace: 'nowrap', cursor: 'default' },
    };
    list.push(noPaymentItem);
  } else {
    if (period.isReviewable) {
      list.push({
        displayName: 'Föreslaget belopp',
        propertyName: 'suggestedAmountAverage',
        propertyType: PropertyType.Currency,
        renderCell: RenderSuggestedAmountCell,
        disableClick: true,
        columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
      });
    }

    list.push({
      displayName: 'Beslut',
      propertyName: 'grantedAmount',
      propertyType: PropertyType.Currency,
      renderCell: (rowInformation: CellRenderInformation) => (
        <RenderAmountTextBoxCell rowInformation={rowInformation} />
      ),
      disableClick: true,
      disableSort: true,
      columnStyles: { ...columnStyles, minWidth: '160px', whiteSpace: 'nowrap', cursor: 'default' },
    });
  }

  return list;
};
