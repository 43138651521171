/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AppShell, Aside, useMantineTheme, Text } from '@mantine/core';
import { ContentContainer } from './ContentContainer';
import { MenuHeader } from './MenuHeader';
import { GrantMeNavbar } from './GrantMeNavbar';

export const MAX_FORM_WIDTH = 900;
export const CONTAINER_PADDING_WIDTH = 40;
export const CONTAINER_PADDING_TOP = 20;

interface LayoutProps {
  loadNavBar: boolean;
}

export const Layout: React.FC<LayoutProps> = ({ loadNavBar }) => {
  const theme = useMantineTheme();

  return (
    <AppShell
      styles={{
        root: {
          overflow: 'hidden',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
        body: {
          overflow: 'hidden',
          flex: 1,
          display: 'flex',
        },
        main: {
          padding: 0,
          width: 'unset',
          background: theme.colors.white, // theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'auto',
          minHeight: 0,
        },
      }}
      header={<MenuHeader simpleMode={!loadNavBar} />}
      navbar={loadNavBar ? <GrantMeNavbar /> : undefined}>
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </AppShell>
  );
};
