import Formatter from '../../utils/formatter';
import { PropertyType } from './PropertyType';

const formatValueAsString = (
  value: string | number | boolean,
  type: PropertyType,
): string | null => {
  switch (type) {
    case PropertyType.Text:
      return value as string;

    case PropertyType.Currency:
      return Formatter.formatCurrency(value as number);

    case PropertyType.Date:
      return Formatter.formatDateString(value as string);

    case PropertyType.DateTimeUtcToLocal:
      return Formatter.formatLocalDateTimeString(value as string);

    case PropertyType.Number:
      return Formatter.formatNumber(value as number);

    case PropertyType.Boolean:
      return Formatter.formatBoolean(value as boolean);
  }
};

export const formatValue = (
  value: string | number | boolean,
  type: PropertyType,
  maxLength?: number | undefined,
): string | null => {
  const stringValue = formatValueAsString(value, type);
  const ellipsis = ' ...';

  if (stringValue && maxLength && stringValue.length > maxLength + ellipsis.length) {
    return stringValue.substring(0, maxLength - ellipsis.length) + ellipsis;
  }

  return stringValue;
};
