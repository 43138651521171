export const reorderArray = <T>(arr: (T | undefined)[], source: number, target: number): (T | undefined)[] => {
  if (target < 0 || target >= arr.length) {
    return arr;
  }
  
  if (target >= arr.length) {
    let k = target - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(target, 0, arr.splice(source, 1)[0]);
  return arr;
};