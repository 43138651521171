import React, { useEffect, useState } from 'react';
import { Group, Grid, Divider, createStyles } from '@mantine/core';
import { useSession } from '../../hooks/useSession';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { useApi } from '../../hooks/useApi';
import { Dashboard } from '../../models/Dashboard';
import { ReviewCard } from './cards/ReviewCard';
import { ApplicationPeriodsCard } from './cards/ApplicationPeriodsCard';
import { RecentReviewsCard } from './cards/RecentReviewsCard';
import { BudgetCard } from './cards/BudgetCard';
import { Role } from '../../models/Role';
import { ProductVariant } from '../../models/ProductVariant';

const useStyles = createStyles((theme) => ({
  dividerRight: {
    borderRight: '1px solid',
    borderColor: '#cbd0dd',
  },
}));

export const DashboardView: React.FC = () => {
  const { selectedOrganisation } = useSession();
  const api = useApi();
  const [dashboard, setDashboard] = useState<Dashboard>();
  const { classes } = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      if (selectedOrganisation) {
        const result = await api.getDashboard(selectedOrganisation?.id);
        setDashboard(result);
      }
    };

    fetchData();
  }, [selectedOrganisation]);

  const canApprove = () => {
    if (selectedOrganisation) {
      return (
        selectedOrganisation.role === Role.Approver ||
        selectedOrganisation.role === Role.Administrator
      );
    }

    return false;
  };

  const canReview = () => {
    return selectedOrganisation?.productVariant !== ProductVariant.Small;
  };

  if (!dashboard || !selectedOrganisation) return <></>;

  return (
    <GrantMeContainer bg="gray.1">
      <Grid m={0} gutter={0}>
        {canReview() && <Grid.Col span="auto" className={ canApprove() ? classes.dividerRight : '' }>{<ReviewCard data={dashboard} />}</Grid.Col>}
        {canApprove() && (
          <>
            <Grid.Col span="auto">
              <BudgetCard data={dashboard} />
            </Grid.Col>
          </>
        )}
        <Grid.Col span={12}>
          <Divider />
        </Grid.Col>
        {canReview() && (
          <>
            <Grid.Col span={12}>
              <RecentReviewsCard data={dashboard} />
            </Grid.Col>
            <Grid.Col span={12}>
              <Divider />
            </Grid.Col>
          </>
        )}
        {canApprove() && (
          <>
            <Grid.Col span={12}>
              <ApplicationPeriodsCard data={dashboard} />
            </Grid.Col>
          </>
        )}
      </Grid>
    </GrantMeContainer>
  );
};
