import { Button, Grid, Menu, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import { FC } from 'react';
import { t } from 'ttag';
import { isPresent } from 'utilitype';
import { FormElementType } from '../models/FormElementType';
import { ToolboxItems } from './ToolBoxItems';
import { ToolboxSetting } from './ToolboxItemSettings';

interface EditorToolboxProps {
  addElement: (elementType: FormElementType) => void;
  toolbox: ToolboxSetting;
}

export const FormBuilderToolbox: FC<EditorToolboxProps> = ({ addElement, toolbox }) => {
  const mapItems = (elementType: FormElementType) => {
    const item = ToolboxItems[elementType];
    const { type, icon, label, limit } = item;

    const count = toolbox.counters.elements.find(countItem => item.type === countItem.type)?.count ?? 0;
    const disableOption = isPresent(limit) && limit <= count;
    
    return (
      <Grid.Col key={type} span={6}>
      <Menu.Item icon={icon} onClick={() => addElement(type)} disabled={disableOption}>
        {label}
      </Menu.Item>
      </Grid.Col>
    );
  };

  return (
    <Menu shadow="md" width={340}>      <Menu.Target>
        <Button leftIcon={<IconPlus />} variant="light">
          {t`Lägg till element`}
        </Button>
      </Menu.Target>
      <Menu.Dropdown p={'md'}>
        <Text size={'xs'} weight={'bold'} pl={'sm'} color={'dimmed'}>{t`Element`}</Text>
        <Grid gutter={0}>
        {toolbox.elementTypes.map(mapItems)}
        </Grid>
        </Menu.Dropdown>
    </Menu>
  );
};
