import { createStyles } from '@mantine/core';
import { CONTAINER_PADDING_WIDTH } from '../../layout/Layout';

export const styles = createStyles((theme) => ({
  table: {
    height: '1px',
    tableLayout: 'auto',
    minWidth: 700,
    borderCollapse: 'separate',
    borderSpacing: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    border: `0px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[8]
    }`,
  },
  th: {
    top: 0,
    position: 'sticky',
    zIndex: 2,
    fontWeight: 'bold',
    color: 'black',
    paddingTop: `${theme.spacing.md}px !important`,
    paddingBottom: `${theme.spacing.md}px !important`,
  },
  paddingOnFirstChild: {
    '&:first-of-type': {
      paddingLeft: CONTAINER_PADDING_WIDTH,
    },
  },
  paddingOnLastChild: {
    '&:last-of-type': {
      paddingRight: CONTAINER_PADDING_WIDTH,
    },
  },
  clickableTr: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
  tdNoBorder: {
    '> td': {
      borderBottom: '0 !important',
    },
  },
  expandedTd: {
    paddingTop: '0 !important',
    paddingLeft: '4px !important',
  },
  tooltipWrapper: {
    height: '100%',
    width: '100%',
  },
}));
