import { Flex, Text, Divider, Accordion, createStyles, Stack, Grid, Skeleton } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { t } from 'ttag';
import { isPresent } from 'utilitype';
import { formatValue } from '../../../components/LiitGrid/FormatValue';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { PeriodReviewSummary } from './PeriodReviewSummary';
import { useLocalStorage } from '@mantine/hooks';
import { ApplicationReviewStatus } from '../../../models/ApplicationReviewStatus';
import { BORDER_COLOR } from '../../../App';

const useAccordionStyles = createStyles((theme) => ({
  panel: {
    overflow: 'auto',
    maxHeight: '250px',
  },
  item: {
    backgroundColor: theme.white,
    borderRadius: 0,

    position: 'relative',
    '&[data-active]': {
      backgroundColor: theme.white,
    },
  },
  control: {
    padding: 8,
    '&:hover': {
      backgroundColor: theme.white,
    },
  },
}));

const useStyles = createStyles((theme) => ({
  valueContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  borderBottom: {
    borderBottom: `1px solid ${BORDER_COLOR}`,
  },
}));

interface ReviewInformationProps {
  filterOnId: string | null | undefined;
  triggerOnChange?: ApplicationReviewStatus | null;
}

export const ReviewInformation: React.FC<ReviewInformationProps> = ({
  filterOnId,
  triggerOnChange,
}) => {
  const { classes, theme, cx } = useStyles();
  const { classes: accordionClasses } = useAccordionStyles();
  const { selectedOrganisation } = useSession();
  const [reviewInformation, setReviewInformation] = useState<PeriodReviewSummary[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const api = useApi();
  const [expandedItem, setExpandedItem] = useLocalStorage({
    key: 'review-information-expanded',
    defaultValue: '',
  });

  useEffect(() => {
    const fetchData = async (organisationId: string): Promise<void> => {
      const result = await api.getReviewBudgetAndSuggestedAllPeriods(organisationId);

      setReviewInformation(result);

      setLoading(false);
    };
    if (selectedOrganisation) {
      fetchData(selectedOrganisation.id);
    }
  }, [selectedOrganisation, triggerOnChange]);

  const getTotalForValue = (prop: string, text: string, propertyType: PropertyType) => {
    let value = 0;
    if (filterOnId) {
      const item = reviewInformation.find((x) => x.periodId == filterOnId);
      if (isPresent(item)) {
        value = item[prop] ?? 0;
      }
    } else {
      value = reviewInformation.reduce((a, b) => a + b[prop], 0);
    }

    return (
      <Stack spacing={0} align={'center'}>
        <Text color="dimmed" transform="uppercase" weight={700} size="xs">
          {text}
        </Text>
        <Text weight={700} size="lg">
          {formatValue(value, propertyType) + (propertyType === PropertyType.Number ? ' st' : '')}
        </Text>
      </Stack>
    );
  };

  return (
    <Accordion
      transitionDuration={0}
      w={'100%'}
      variant={'filled'}
      value={expandedItem}
      onChange={(v: string) => {
        setExpandedItem(v);
      }}
      classNames={accordionClasses}
      chevron={<IconChevronDown color={theme.colors[theme.primaryColor][7]} />}
      chevronSize={32}>
      <Accordion.Item
        className={cx({ [classes.borderBottom]: !!expandedItem })}
        value="customization"
        color={theme.white}>
        <Accordion.Control className={classes.borderBottom}>
          <Flex direction={'row'} align={'center'}>
            <Flex className={classes.valueContainer}>
              {getTotalForValue('budget', t`Budget`, PropertyType.Currency)}
            </Flex>
            <Divider color={BORDER_COLOR} orientation={'vertical'} />
            <Flex className={classes.valueContainer}>
              {getTotalForValue('totalSuggested', t`Mina förslag`, PropertyType.Currency)}
            </Flex>
            <Divider color={BORDER_COLOR} orientation={'vertical'} />
            <Flex className={classes.valueContainer}>
              {getTotalForValue('toHandleCount', t`Att hantera`, PropertyType.Number)}
            </Flex>
            <Divider color={BORDER_COLOR} orientation={'vertical'} />
            <Flex className={classes.valueContainer}>
              {getTotalForValue('totalCount', t`Ansökningar`, PropertyType.Number)}
            </Flex>
          </Flex>
        </Accordion.Control>
        <Accordion.Panel style={{ maxHeight: '250px', overflow: 'auto' }}>
          <Skeleton visible={loading}>
            <Grid gutter={0} pt={8} pl={'xl'} pr={'xl'}>
              <Grid.Col span={2}>
                <Text size={'xs'} weight={700} color={'dimmed'}>
                  Period
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text align={'end'} size={'xs'} weight={700} color={'dimmed'}>
                  Att hantera
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text align={'end'} size={'xs'} weight={700} color={'dimmed'}>
                  Granskade
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text align={'end'} size={'xs'} weight={700} color={'dimmed'}>
                  Totalt
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text align={'end'} size={'xs'} weight={700} color={'dimmed'}>
                  Budget
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text align={'end'} size={'xs'} weight={700} color={'dimmed'}>
                  Mina förslag
                </Text>
              </Grid.Col>

              {reviewInformation.map((ri) => {
                return (
                  <React.Fragment key={ri.periodId}>
                    <Grid.Col span={2}>
                      <Text size={'xs'} weight={500}>
                        {ri.periodName}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <Text align={'end'} size={'xs'} weight={500}>
                        {formatValue(ri.toHandleCount, PropertyType.Number)}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <Text align={'end'} size={'xs'} weight={500}>
                        {formatValue(ri.completedCount, PropertyType.Number)}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <Text align={'end'} size={'xs'} weight={500}>
                        {formatValue(ri.totalCount, PropertyType.Number)}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <Text align={'end'} size={'xs'} weight={500}>
                        {ri.budget ? formatValue(ri.budget, PropertyType.Currency) : '-'}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <Text
                        align={'end'}
                        size={'xs'}
                        weight={500}
                        color={
                          ri.totalSuggested <= ri.budget
                            ? theme.colors.green[6]
                            : theme.colors.red[6]
                        }>
                        {formatValue(ri.totalSuggested, PropertyType.Currency)}
                      </Text>
                    </Grid.Col>
                  </React.Fragment>
                );
              })}
              {reviewInformation.length > 1 && (
                <>
                  <Grid.Col span={12}>
                    <Divider mt={2} pb={2} color={theme.colors.gray[1]} />
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text size={'xs'} weight={700}>{t`Total`}</Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text align={'end'} size={'xs'} weight={700}>
                      {formatValue(
                        reviewInformation.reduce((a, b) => a + b.toHandleCount, 0),
                        PropertyType.Number,
                      )}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text align={'end'} size={'xs'} weight={700}>
                      {formatValue(
                        reviewInformation.reduce((a, b) => a + b.completedCount, 0),
                        PropertyType.Number,
                      )}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text align={'end'} size={'xs'} weight={700}>
                      {formatValue(
                        reviewInformation.reduce((a, b) => a + b.totalCount, 0),
                        PropertyType.Number,
                      )}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text align={'end'} size={'xs'} weight={700}>
                      {formatValue(
                        reviewInformation.reduce((a, b) => a + b.budget, 0),
                        PropertyType.Currency,
                      )}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text align={'end'} size={'xs'} weight={700}>
                      {formatValue(
                        reviewInformation.reduce((a, b) => a + b.totalSuggested, 0),
                        PropertyType.Currency,
                      )}
                    </Text>
                  </Grid.Col>
                </>
              )}
            </Grid>
          </Skeleton>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
