/* eslint-disable @typescript-eslint/no-unused-vars */
import { HideCondition, IDisplayColumn } from '../../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../../components/LiitGrid/PropertyType';
import React from 'react';
import { RenderApplicantCell } from '../../common/grid-columns/RenderApplicantCell';
import { RenderAmountCell } from '../../common/grid-columns/RenderAmountCell';
import { RenderApplicationStatusCell } from '../../common/grid-columns/RenderApplicationStatusCell';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};

export const ArchiveApplicationListColumns = (): Array<IDisplayColumn> => [
  {
    displayName: 'Diarienr',
    propertyName: 'number',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles },
    hideFromPrint: true,
  },
  {
    displayName: 'Mottagare',
    propertyName: 'applicantName',
    propertyType: PropertyType.Text,
    renderCell: RenderApplicantCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
    hideAt: { size: 'lg', type: HideCondition.SmallerThan },
  },
  {
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    renderCell: RenderApplicantCell,
    columnStyles: { ...columnStyles, width: 'auto' },
    maxLength: 80,
  },
  {
    displayName: 'Sökt belopp',
    propertyName: 'requestedAmount',
    propertyType: PropertyType.Currency,
    renderCell: RenderAmountCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Beviljat belopp',
    propertyName: 'grantedAmount',
    propertyType: PropertyType.Currency,
    renderCell: RenderAmountCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Status',
    propertyName: 'status',
    propertyType: PropertyType.Text,
    renderCell: RenderApplicationStatusCell,
    columnStyles: { ...columnStyles, paddingRight: '8px' },
  },
];

export const ArchiveApplicationRequisitionListColumns = (): Array<IDisplayColumn> => [
  {
    displayName: 'Diarienr',
    propertyName: 'number',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles },
    hideFromPrint: true,
  },
  {
    displayName: 'Rekvirerad',
    propertyName: 'hasClaimedRequisition',
    propertyType: PropertyType.Boolean,
    columnStyles: { ...columnStyles },
    hideFromPrint: true,
  },
  {
    displayName: 'Mottagare',
    propertyName: 'applicantName',
    propertyType: PropertyType.Text,
    renderCell: RenderApplicantCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
    hideAt: { size: 'lg', type: HideCondition.SmallerThan },
  },
  {
    displayName: 'Titel',
    propertyName: 'title',
    propertyType: PropertyType.Text,
    renderCell: RenderApplicantCell,
    columnStyles: { ...columnStyles, width: 'auto' },
    maxLength: 80,
  },
  {
    displayName: 'Sökt belopp',
    propertyName: 'requestedAmount',
    propertyType: PropertyType.Currency,
    renderCell: RenderAmountCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Beviljat belopp',
    propertyName: 'grantedAmount',
    propertyType: PropertyType.Currency,
    renderCell: RenderAmountCell,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap' },
  },
  {
    displayName: 'Status',
    propertyName: 'status',
    propertyType: PropertyType.Text,
    renderCell: RenderApplicationStatusCell,
    columnStyles: { ...columnStyles, paddingRight: '8px' },
  },
];
