import { MantineTheme } from '@mantine/core';
import { ReactNode } from 'react';

export class CellRenderInformation {
  /**
   *
   */
  constructor(
    public rowId: string,
    public cellValue: any,
    public rowValues: Record<string, any>,
    public theme: MantineTheme,
    public setData: any,
    public onCellValueChanged?: () => void,
  ) {}
}

export interface RenderCellInformation {
  rowId: string;
  cellValue: any;
  rowValues?: Record<string, any>;
  theme?: MantineTheme;
  setData?: any;
  onCellValueChanged?: () => void;
}

export type RenderCell = (cellInformation: CellRenderInformation) => ReactNode;
