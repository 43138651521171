const settings: { [key: string]: { [key: string]: string } } = {
  // Production
  'https://admin.grantme.se': {
    SERVER_URL: 'https://api.grantme.se/admin',
    REDIRECT_URI: 'https://admin.grantme.se',
    CLIENT_URL: 'https://apply.grantme.se',
    SCOPES: 'https://grantmeb2c.onmicrosoft.com/a181b30b-d387-4e5d-94cf-2dd6459d1556/adminapi.full',
    CLIENTID: '318d1866-9682-4f83-b2cd-6b743399147e',
    AUTHORITY: 'https://grantmeb2c.b2clogin.com/grantmeb2c.onmicrosoft.com/B2C_1_signin',
    KNOWN_AUTHORITY: 'grantmeb2c.b2clogin.com',
  },

  'https://admin.grant-me.com': {
    SERVER_URL: 'https://api.grant-me.com/admin',
    REDIRECT_URI: 'https://admin.grant-me.com',
    CLIENT_URL: 'https://apply.grant-me.com',
    SCOPES: 'https://grantmeb2c.onmicrosoft.com/a181b30b-d387-4e5d-94cf-2dd6459d1556/adminapi.full',
    CLIENTID: '318d1866-9682-4f83-b2cd-6b743399147e',
    AUTHORITY: 'https://grantmeb2c.b2clogin.com/grantmeb2c.onmicrosoft.com/B2C_1_signin',
    KNOWN_AUTHORITY: 'grantmeb2c.b2clogin.com',
  },

  // Test
  'https://test-admin.grantme.se': {
    SERVER_URL: 'https://test-api.grantme.se/admin',
    REDIRECT_URI: 'https://test-admin.grantme.se',
    CLIENT_URL: 'https://test-client.grantme.se',
    SCOPES:
      'https://grantmetestb2c.onmicrosoft.com/e8fd0bf7-dfe6-405a-9ab0-4d2b9b27b58f/adminapi.full',
    CLIENTID: '82493aa8-3a10-4bbd-80b9-4e92bb7ba03a',
    AUTHORITY:
      'https://grantmetestb2c.b2clogin.com/grantmetestb2c.onmicrosoft.com/B2C_1_admin_signin',
    KNOWN_AUTHORITY: 'grantmetestb2c.b2clogin.com',
  },

  'https://test-admin.grant-me.com': {
    SERVER_URL: 'https://grantme-api-test.azurewebsites.net/admin',
    REDIRECT_URI: 'https://test-admin.grant-me.com',
    CLIENT_URL: 'https://test-client.grant-me.com',
    SCOPES:
      'https://grantmetestb2c.onmicrosoft.com/e8fd0bf7-dfe6-405a-9ab0-4d2b9b27b58f/adminapi.full',
    CLIENTID: '82493aa8-3a10-4bbd-80b9-4e92bb7ba03a',
    AUTHORITY:
      'https://grantmetestb2c.b2clogin.com/grantmetestb2c.onmicrosoft.com/B2C_1_admin_signin',
    KNOWN_AUTHORITY: 'grantmetestb2c.b2clogin.com',
  },

  // Development
  'http://localhost:3001': {
    SERVER_URL: 'https://localhost:7153/admin',
    REDIRECT_URI: 'http://localhost:3001',
    CLIENT_URL: 'http://localhost:3002',
    SCOPES:
      'https://grantmetestb2c.onmicrosoft.com/e8fd0bf7-dfe6-405a-9ab0-4d2b9b27b58f/adminapi.full',
    CLIENTID: '82493aa8-3a10-4bbd-80b9-4e92bb7ba03a',
    AUTHORITY:
      'https://grantmetestb2c.b2clogin.com/grantmetestb2c.onmicrosoft.com/B2C_1_admin_signin',
    KNOWN_AUTHORITY: 'grantmetestb2c.b2clogin.com',
  },
};

export const getSettings = (): { [key: string]: string } => {
  const rootUrl = `${window.location.protocol}//${window.location.host}`;
  return settings[rootUrl];
};
