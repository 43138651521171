/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { CellRenderInformation } from '../../components/LiitGrid/RenderCell';
import { PaymentBatchType } from '../../models/PaymentBatchType';

const columnStyles: React.CSSProperties = {
  width: '1%',
  cursor: 'pointer',
  userSelect: 'none',
};


export const PaymentBatchesViewColumns = (): Array<IDisplayColumn> => [
  {
    displayName: 'Datum',
    propertyName: 'createdUtc',
    propertyType: PropertyType.Date,
    columnStyles: { ...columnStyles, width: '50%' },
  },
  {
    displayName: 'Typ',
    propertyName: 'type',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, width: '30%' },
    renderCell: (rowInformation: CellRenderInformation) => {

      return (
        <>{rowInformation.cellValue === PaymentBatchType.Iso20022 ? 'Fil' : 'Manuell'}</>
      );
    },
  },
  {
    displayName: 'Antal transaktioner',
    propertyName: 'numberOfTransactions',
    propertyType: PropertyType.Text,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap', width: '10%' },
  },
  {
    displayName: 'Totalbelopp',
    propertyName: 'totalAmount',
    propertyType: PropertyType.Currency,
    columnStyles: { ...columnStyles, whiteSpace: 'nowrap', width: '20%' },
  },
];
