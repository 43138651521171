import { useMantineTheme } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { FC } from 'react';
import { t } from 'ttag';
import { LanguageCode } from '../../../../models/LanguageCode';
import Formatter from '../../../../utils/formatter';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';
import { FormLabel } from '../Common/FormLabel';
import { ViewerDatePickerElement } from './ViewerDatePickerElement';

type DatePickerElementProps = {
  element: FormElement
} & FormElementComponentProps;

export const DatePickerElement: FC<DatePickerElementProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  const theme = useMantineTheme();

  if (mode === FormElementMode.Viewer) {
    return <ViewerDatePickerElement number={number} element={element} />;
  }

  const { value, description, label, required, hideLabel } = element;

  const labelText = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);
  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);
  const date = value ? new Date(Formatter.formatDateString(value as string)) : null;

  return (
    <DatePicker 
      required={required}
      withAsterisk={false}
      error={error}
      disabled={mode === FormElementMode.Editor}
      placeholder={ hideLabel === undefined ?  t`Välj ett datum` : labelText }
      label={hideLabel ? undefined : <FormLabel element={element} mode={mode} position={'apart'} />}
      description={descriptionText} 
      value={date}
      onChange={(newDate) => { 
        if (onChange) {
          const formatedDate = Formatter.formatDate(newDate) ?? null;
          onChange(formatedDate);
        }
      }}
    />
  );
};
