import { Stack, useMantineTheme } from '@mantine/core';
import React from 'react';
import { PrintButton } from '../../../components/Printing/PrintButton';
import { ReviewStatuses } from '../common/ReviewStatuses';
import Formatter from '../../../utils/formatter';

export interface ReviewApplicationListPrintButtonProps {
  title: string;
  periodName: string | null | undefined;
  applications: any;
}

export const ReviewApplicationListPrintButton: React.FC<ReviewApplicationListPrintButtonProps> = ({
  title,
  periodName,
  applications,
}) => {
  const theme = useMantineTheme();
  const reviewStatuses = ReviewStatuses(theme);

  return (
    <PrintButton disabled={false} title="Skriv ut" landscape={true}>
      <Stack>
        <Stack>
          <h2>{title}</h2>
          {periodName} {Formatter.formatDate(new Date())}
        </Stack>
        <br />
        {applications && (
          <table>
            <thead>
              <tr>
                <th>Diarienr</th>
                <th>Inkom</th>
                <th>Sökande</th>
                <th>Titel</th>
                <th className="nowrap">Sökt belopp</th>
                <th className="nowrap">Ditt förslag</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {applications.map((application) => (
                <React.Fragment key={'app_print_' + application.id}>
                  <tr>
                    <td className="nowrap">{application.number}</td>
                    <td className="nowrap">
                      {Formatter.formatDateString(application.submittedUtc)}
                    </td>
                    <td className="nowrap">{application.applicantName}</td>
                    <td>{application.title}</td>
                    <td className="nowrap">
                      {Formatter.formatCurrency(application.requestedAmount)}
                    </td>
                    <td className="nowrap">
                      {Formatter.formatCurrency(application.suggestedAmount)}
                    </td>
                    <td className="nowrap">{reviewStatuses[application.reviewStatus].title}</td>
                  </tr>
                  {application.reviewComment && (
                    <tr>
                      <td colSpan={7}>
                        {application.reviewComment}
                        <br />
                        <br />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </Stack>
    </PrintButton>
  );
};
