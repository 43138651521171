import {
  ActionIcon,
  MantineNumberSize,
  MantineShadow,
  MantineSize,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import {
  IconRotate,
  IconZoomOut,
  IconZoomCancel,
  IconZoomIn,
  IconRotateClockwise,
} from '@tabler/icons';
import React from 'react';
import { t } from 'ttag';

interface PDFViewerControlsProps {
  buttonSize: MantineNumberSize;
  onRotateRight: () => void;
  onRotateLeft: () => void;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onZoomReset: () => void;
  disableZoomIn: boolean;
  disableZoomOut: boolean;
  disableZoomReset: boolean;
}

export const PDFViewerControls: React.FC<PDFViewerControlsProps> = ({
  buttonSize,
  onRotateRight,
  onRotateLeft,
  onZoomIn,
  onZoomOut,
  onZoomReset,
  disableZoomIn,
  disableZoomOut,
  disableZoomReset,
}) => {
  const theme = useMantineTheme();

  return (
    <>
      <Tooltip label={t`Rotera motsols`}>
        <ActionIcon
          color={theme.primaryColor}
          variant={'light'}
          onClick={() => onRotateLeft()}
          size={buttonSize}>
          <IconRotate />
        </ActionIcon>
      </Tooltip>
      <Tooltip label={t`Zooma ut`}>
        <ActionIcon
          ml={'sm'}
          color={disableZoomOut ? 'gray.5' : theme.primaryColor}
          variant={'light'}
          onClick={() => onZoomOut()}
          size={buttonSize}>
          <IconZoomOut />
        </ActionIcon>
      </Tooltip>
      <Tooltip label={t`Återställ zoom`}>
        <ActionIcon
          ml={'sm'}
          color={disableZoomReset ? 'gray.5' : theme.primaryColor}
          variant={'light'}
          onClick={() => onZoomReset()}
          size={buttonSize}>
          <IconZoomCancel />
        </ActionIcon>
      </Tooltip>
      <Tooltip label={t`Zooma in`}>
        <ActionIcon
          ml={'sm'}
          color={disableZoomIn ? 'gray.5' : theme.primaryColor} 
          variant={'light'}
          onClick={() => onZoomIn()}
          size={buttonSize}>
          <IconZoomIn />
        </ActionIcon>
      </Tooltip>
      <Tooltip label={t`Rotera medsols`}>
        <ActionIcon
          ml={'sm'}
          color={theme.primaryColor}
          variant={'light'}
          onClick={() => onRotateRight()}
          size={buttonSize}>
          <IconRotateClockwise />
        </ActionIcon>
      </Tooltip>
    </>
  );
};
