import { Text, Table } from '@mantine/core';
import { ReactNode } from 'react';
import { t } from 'ttag';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import Formatter from '../../../utils/formatter';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { HoverTooltip } from '../../../components/HoverTooltip';

export const RenderAverageCell: RenderCell = (rowInformation: CellRenderInformation): ReactNode => {
  const { rowValues, theme } = rowInformation;
  const { id, reviewTotalAverage, reviewDetails, reviewCompletedCount } = rowValues;

  const api = useApi();
  const { selectedOrganisation } = useSession();

  const getAverageScorePerCriteria = async () => {

    if (reviewDetails)
      return;

    const response = await api.getReviewDetails(selectedOrganisation!.id, id);

    rowInformation.setData((prev) => {
      const app = prev.find((x) => x.id === rowValues.id);
      app.reviewDetails = response; 
      const newPrev = [...prev];
      return newPrev;
    });

    if (rowInformation.onCellValueChanged) {
      rowInformation.onCellValueChanged();
    }
  };

  const getAverageToolTip = () => {    
    if (!reviewDetails) {
      return null;
    }

    if (reviewDetails.scores.length === 0) {

      if (reviewCompletedCount > 0) {
        return (
          <Text p={16} size={'xs'} weight={500}>
            {t`Inga poäng utdelade på denna ansökan`}
          </Text>
        );
      } else {
        return (
          <Text p={16} size={'xs'} weight={500}>
            {t`Inga granskningar gjorda på denna ansökan`}
          </Text>
        );
      }
    }

    const included = reviewDetails.scores.filter(x => !x.isExcluded);
    const excluded = reviewDetails.scores.filter(x => x.isExcluded);

    return (
      <Table horizontalSpacing={'xs'} verticalSpacing={4}>
        <thead>
          <tr>
            {/* <th style={{ paddingBottom: '2px', borderBottom: `1px solid ${theme.colors.gray[3]}` }}> */}
            <th>
              <Text size={'xs'}>{t`Kriterie`}</Text>
            </th>
            {/* <th style={{ paddingBottom: '2px', borderBottom: `1px solid ${theme.colors.gray[3]}` }}> */}
            <th>
              <Text size={'xs'}>{t`Medel`}</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {included.map((c) => (
            <tr key={`r_${c.criteriaTitle}`}>
              {/* <td style={{ paddingTop: 2, paddingBottom: 2, border: 0 }}> */}
              <td>
                <Text p={0} size={'xs'} weight={500}>
                  {c.criteriaTitle}
                </Text>
              </td>
              {/* <td style={{ textAlign: 'end', paddingTop: 2, paddingBottom: 2, border: 0 }}> */}
              <td>
                <Text p={0} size={'xs'} weight={'bold'}>
                  {Formatter.formatNumber(c.average) ?? '-'}
                </Text>
              </td>
            </tr>
          ))}
          {excluded.length > 0 && (
            <>
            <tr>
              <td colSpan={2} style={{ paddingBottom: '2px', border: 0,  borderBottom: `1px solid ${theme.colors.gray[3]}` }}>
                <Text size={'xs'} weight={'bold'} color={theme.colors[theme.primaryColor][7]}>
                  {t`Exkluderade kriterier`}
                </Text>
              </td>
            </tr>
            {excluded.length > 0 && excluded.map((c) => 
              <tr key={`r_excluded_${c.criteriaId}`}>
                {/* <td style={{ paddingTop: 2, paddingBottom: 2, border: 0 }}> */}
                <td>
                <Text p={0} size={'xs'} weight={500} color={'dimmed'}>
                  {c.criteriaTitle}
                </Text>
              </td>
              {/* <td style={{ textAlign: 'end', paddingTop: 2, paddingBottom: 2, border: 0 }}> */}
              <td>
                <Text p={0} size={'xs'} weight={'bold'} color={'dimmed'}>
                {Formatter.formatNumber(c.average) ?? '-'}
                </Text>
              </td>
              </tr>)}  
            </>
          )}
        </tbody>
      </Table>
    );
  };

  const roundedTotal = reviewTotalAverage
    ? Formatter.formatNumber(Math.round((reviewTotalAverage + Number.EPSILON) * 100) / 100)
    : null;

  return (
    <HoverTooltip content={getAverageToolTip()} onOpen={() => getAverageScorePerCriteria()} >
        <Text>{roundedTotal ?? '-'}</Text>
    </HoverTooltip>
  );
};
