import { Anchor, Box, SimpleGrid, Space, Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { isMissing } from 'utilitype';
import { useApi } from '../../../hooks/useApi';
import { useSession } from '../../../hooks/useSession';
import { Application } from '../../../models/Application';
import { Link } from 'react-router-dom';

interface ApplicationToolbarItemHistoryProps {
  application: Application;
}

export const ApplicationToolbarItemHistory: React.FC<ApplicationToolbarItemHistoryProps> = ({
  application,
}) => {
  const { selectedOrganisation } = useSession();
  const [applications, setApplications] = useState<any[]>();
  const api = useApi();

  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    const fetch = async () => {
      if (isMissing(selectedOrganisation)) {
        return;
      }

      const result = await api.getHistoryByApplication(selectedOrganisation.id, application.id);
      setApplications(result);
    };

    fetch();
  }, []);

  if (applications && applications.length > 0) {
    const groups = groupBy(applications, 'period');
    return (
      <>
        Andra ansökningar av <b>{application.applicantName}</b>:
        <Space h={'md'} />
        <table>
          <tbody>
            {Object.keys(groups).map((g) => (
              <React.Fragment key={'history_' + g}>
                <tr>
                  <td>
                    <b>{g}</b>
                  </td>
                </tr>

                {groups[g].map((app) => (
                  <tr key={'history_' + app.id}>
                    <td style={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}>
                      {app.canView && (
                        <Anchor component={Link} to={`/applications/${app.id}`}>
                          {app.number}
                        </Anchor>
                      )}
                      {!app.canView && <>{app.number}</>}
                    </td>
                    <td style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{app.title}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </>
    );
  }

  return (
    <>
      Inga andra ansökningar har gjorts av <b>{application.applicantName}</b>.
    </>
  );
};
