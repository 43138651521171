import { useMsal } from '@azure/msal-react';
import {
  Menu,
  Group,
  Stack,
  Space,
  Text,
  useMantineTheme,
  createStyles,
  Avatar,
} from '@mantine/core';
import { IconSettings, IconUser, IconLogout, IconChevronDown } from '@tabler/icons';
import React from 'react';
import { t } from 'ttag';
import { useSession } from '../hooks/useSession';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  menuItemWrapper: {
    //backgroundColor: theme.colors.blue[8],
    padding: '8px 8px',
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors[theme.primaryColor][7],
    },
  },

  truncate: {
    [`@media (max-width: ${theme.breakpoints.xl}px)`]: {
      width: '140px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const COLORS = ['indigo', 'red', 'orange', 'green', 'grape'];
const COLORS_LENGTH = COLORS.length;

export const OrganisationAndProfileMenu: React.FC = () => {
  const theme = useMantineTheme();
  const { selectedOrganisation, organisations, selectOrganisation } = useSession();

  const { instance } = useMsal();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const selectedIndex = organisations?.findIndex((x) => x.id === selectedOrganisation?.id) ?? 0;

  return (
    <Menu
      shadow={'lg'}
      transition={'pop'}
      position={'bottom-end'}
      styles={{
        item: {
          padding: '6px 12px',
        },
      }}>
      <Menu.Target>
        <div className={classes.menuItemWrapper}>
          <Group spacing={8} noWrap>
            <Stack p={0} spacing={0}>
              <Text
                size={'sm'}
                weight={'bold'}
                className={classes.truncate}
                color={theme.colors.gray[0]}>
                {selectedOrganisation?.name}
              </Text>
              {/* <Text size={'xs'} color={theme.colors.dark[5]}>
                {instance.getActiveAccount()?.name}
              </Text> */}
            </Stack>
            {selectedOrganisation && (
              <Avatar ml={4} size={'sm'} color={COLORS[selectedIndex % COLORS_LENGTH]}>
                {selectedOrganisation?.name[0]}
              </Avatar>
            )}
            <IconChevronDown size={18} color={theme.colors.gray[0]} />
          </Group>
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{t`Stiftelser`}</Menu.Label>
        {organisations?.map((f, index) => (
          <Menu.Item
            key={`menu_item_${f.id}`}
            onClick={async () => {
              const organisation = organisations.find((x) => x.id === f.id);
              if (organisation && selectOrganisation) {
                await selectOrganisation(organisation);
                navigate('/');
              }
            }}>
            <Group spacing={'xs'}>
              <Avatar size={'sm'} color={COLORS[index % COLORS_LENGTH]}>
                {f.name[0]}
              </Avatar>
              <Text>{f.name}</Text>
            </Group>
          </Menu.Item>
        ))}
        <Space h={8} />
        <Menu.Label>{instance.getActiveAccount()?.name}</Menu.Label>
        <Menu.Item onClick={() => navigate('/organisations')} icon={<IconSettings size={14} />}>
          <Text>Mina organisationer</Text>
        </Menu.Item>
        {/* <Menu.Item icon={<IconUser size={14} />}>
          <Text >Profil</Text>
        </Menu.Item> */}
        <Menu.Item icon={<IconLogout size={14} />} onClick={() => instance.logoutRedirect()}>
          <Text>Logga ut</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
