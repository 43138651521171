import { Text } from '@mantine/core';
import { FC } from 'react';
import { FormElementOption } from '../../models/FormElementOption';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import LanguageHandler from '../../../../utils/languageHandler';
import { LanguageCode } from '../../../../models/LanguageCode';
import { FormElement } from '../../models/FormElement';

export const ViewerRadioButtonGroup: FC<ViewerFormElementComponentProps> = ({
  number,
  element,
}) => {
  const value = element.value as string;
  const optionsValue = LanguageHandler.getTextByLanguage(element.options.find((x: FormElementOption) => x.id === value)?.label, LanguageCode.sv);
  const { labelAndTextPosition } = element as FormElement;
  return (
    <ViewerInputWrapper number={number} element={element}>
      <Text align={labelAndTextPosition ?? 'left' } size="sm">{optionsValue}</Text>
    </ViewerInputWrapper>
  );
};
