import {
  Progress,
  Text,
  Grid,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons';
import React from 'react';
import { Dashboard } from '../../../models/Dashboard';
import { DashboardCard } from './DashboardCard';
import Formatter from '../../../utils/formatter';

interface ReviewCardProps {
  data: Dashboard;
}

export const ReviewCard: React.FC<ReviewCardProps> = ({ data }) => {
  const numberOfReviewableApplications = () => {
    return data.applicationPeriods.reduce(
      (sum, item) => sum + item.numberOfReviewableApplications,
      0,
    );
  };

  const numberOfReviewedApplications = () => {
    return data.applicationPeriods.reduce(
      (sum, item) => sum + item.numberOfReviewedApplications,
      0,
    );
  };

  const getReviewedPercent = () => {
    return numberOfReviewedApplications() + numberOfReviewableApplications() === 0
      ? 0
      : (numberOfReviewedApplications() /
          (numberOfReviewedApplications() + numberOfReviewableApplications())) *
          100;
  };

  const getContent = () => {
    return (
      <>
        <Text size={32} fw={700}>
          {Formatter.formatNumber(getReviewedPercent())} %
        </Text>
        <Text color="dimmed">
          {numberOfReviewedApplications()}
          {' av '}
          {numberOfReviewedApplications() + numberOfReviewableApplications()} ansökningar granskade
        </Text>
        <Progress value={getReviewedPercent()} mt="md" size="lg" radius="xl" color="green.6" />
      </>
    );
  };

  const getCollapseContent = () => {
    if (data.applicationPeriods.filter((x) => x.isReviewable).length === 0) return null;

    return (
      <Grid gutter={'xs'}>
        {data.applicationPeriods
          .filter((x) => x.isReviewable)
          .map((period) => (
            <React.Fragment key={'reviewcard_' + period.id}>
              <Grid.Col span={6}>{period.title}</Grid.Col>
              <Grid.Col span={3}>
                {period.numberOfReviewedApplications} av{' '}
                {period.numberOfReviewedApplications + period.numberOfReviewableApplications} st
              </Grid.Col>
              <Grid.Col span={3}>
                <Progress
                  value={
                    (period.numberOfReviewedApplications /
                      (period.numberOfReviewedApplications +
                        period.numberOfReviewableApplications)) *
                    100
                  }
                  size="lg"
                  radius="xl"
                  color="green.6"
                />
              </Grid.Col>
            </React.Fragment>
          ))}
      </Grid>
    );
  };

  return (
    <DashboardCard
      title="Dina granskningar"
      icon={IconSearch}
      content={getContent()}
      collapse={getCollapseContent()}
    />
  );
};
