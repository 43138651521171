import { showFailNotification } from './notificationHelper';
import { t } from 'ttag';

const getErrorMessage = async (response?: Response) => {

  if (response) {
    if (response.status === 401) {
      return { title: t`Fel`, message: t`Du saknar behörighet för denna åtgärd.` };
    }
  }

  return { title: t`Ett fel uppstod`, message: t`Var god försök senare.` };
};

export async function handleError(response?: Response) {
  const errorMessage = await getErrorMessage(response);
  showFailNotification(errorMessage.title, errorMessage.message);
}
