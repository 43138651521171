import React, { useState } from 'react';
import { Title, Stack, Text } from '@mantine/core';
import { useSession } from '../../hooks/useSession';
import { LiitGrid } from '../../components/LiitGrid/LiitGrid';
import { IDisplayColumn } from '../../components/LiitGrid/IDisplayColumn';
import { PropertyType } from '../../components/LiitGrid/PropertyType';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { useNavigate } from 'react-router-dom';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';

export const OrganisationsView: React.FC = () => {
  const navigate = useNavigate();
  const { selectOrganisation, organisations } = useSession();
  const [isFetching, setIsFetching] = useState(false);

  const displayColumns: IDisplayColumn[] = [
    { displayName: 'Name', propertyName: 'name', propertyType: PropertyType.Text },
  ];

  return (
    <GrantMeContainer size={'xl'}>
      <Stack spacing={0}>
        <GrantMeTitleContainer>
          <Stack spacing={0}>
            <Title order={3}>Organisationer</Title>
            <Text>Välj vilken organisation du vill jobba med.</Text>
          </Stack>
        </GrantMeTitleContainer>
        {organisations && (


          <LiitGrid
            data={organisations}
            columnInfo={displayColumns}
            onRowClick={async (id: string) => {
              const organisation = organisations.find((x) => x.id === id);
              if (organisation && selectOrganisation) {
                await selectOrganisation(organisation);
                navigate('/');
              }
            }}
            fetchingData={isFetching}
          />

        )}
      </Stack>
    </GrantMeContainer>
  );
};
