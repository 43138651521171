import { MantineTheme } from '@mantine/core';

const BASE_SHADE_CHART = 0;
const muiColors = ['blue', 'cyan', 'teal', 'lime', 'grape'];

const padZero = (str, len = 2) => {
  len = len || 2;
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};

const invertColor = (hexColor: string, blackOrWhite: boolean, theme: MantineTheme) => {
  if (hexColor.indexOf('#') === 0) {
    hexColor = hexColor.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hexColor.length === 3) {
    hexColor = hexColor[0] + hexColor[0] + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2];
  }
  if (hexColor.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  const r = parseInt(hexColor.slice(0, 2), 16),
    g = parseInt(hexColor.slice(2, 4), 16),
    b = parseInt(hexColor.slice(4, 6), 16);
  if (blackOrWhite) {
    // https://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? theme.black
      : theme.white;
  }
  // invert color components
  const rString = (255 - r).toString(16), 
    gString = (255 - g).toString(16),
    bString = (255 - b).toString(16);
  // pad each with zeros and return
  return '#' + padZero(rString) + padZero(gString) + padZero(bString);
};

export const setColorsByDistinctId = (theme: MantineTheme, ids: Array<string>): { [key: string]: { color: string, inverted: string } } => {
  const colors: { [key: string]: { color: string, inverted: string } } = {};
  const shadeCount = theme.colors.blue.length;

  ids.forEach((id: string) => {
    const { length } = Object.keys(colors);
    const baseColor = Math.floor(length / shadeCount) >= muiColors.length ? 0 : Math.floor(length / shadeCount);
    const shade = (BASE_SHADE_CHART + length) - (baseColor * shadeCount);

    const color = theme.colors[muiColors[baseColor]][shade];
    const textShade = shade > 4 ? 1 : 8;
    const textColor = theme.colors[muiColors[baseColor]][textShade];

    //const inverted = invertColor(color, true, theme);

    if (!colors[id]) {
      colors[id] = { color: color, inverted: textColor };
    }
  });

  return colors;
};

