import { Badge, MantineSize, useMantineTheme } from '@mantine/core';
import { IconCircleCheck, IconCircleDashed, IconCircleX } from '@tabler/icons';
import React from 'react';
import { ApplicationStatus } from '../../../models/ApplicationStatus';
import { ApplicationStatuses } from '../../application/common/ApplicationStatuses';

interface ApplicationApprovalStatusBadgeProps {
  status: ApplicationStatus;
  badgeSize?: MantineSize;
}

const ICON_SIZE = 20;
const ICON_TOP_PADDING = 6;
const ICON_LEFT_PADDING = 4;

export const ApplicationStatusBadge: React.FC<ApplicationApprovalStatusBadgeProps> = ({
  status,
  badgeSize = 'lg',
}) => {
  const theme = useMantineTheme();

  const statusInformation = ApplicationStatuses(theme);

  const getIcon = (value: ApplicationStatus) => {
    switch (value) {
      case ApplicationStatus.Declined:
        return <IconCircleX size={ICON_SIZE} style={{ paddingTop: ICON_TOP_PADDING }} />;

      case ApplicationStatus.Draft:
        return <IconCircleDashed size={ICON_SIZE} style={{ paddingTop: ICON_TOP_PADDING }} />;

      case ApplicationStatus.Approved:
      case ApplicationStatus.Submitted:
      // case ApplicationStatus.SubmittedCompletionRequested:
      // case ApplicationStatus.SubmittedCompletionResponded:
      //   return <IconCircleCheck size={ICON_SIZE} style={{ paddingTop: ICON_TOP_PADDING }} />;

      default:
        return '';
    }
  };

  return (
    <Badge
      color={statusInformation[status].colorKey}
      variant="filled"
      size={badgeSize}
      // style={{ paddingLeft: ICON_LEFT_PADDING }}
      leftSection={getIcon(status)}>
      {statusInformation[status].title}
    </Badge>
  );
};
