import React from 'react';
import { Button, createStyles, Group } from '@mantine/core';

// const HEADER_HEIGHT = 84;

const useStyles = createStyles((theme) => ({
  controlsWrapper: {
    position: 'fixed',
    width: '100%',
    height: '100px',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors[theme.primaryColor][8],
  },
}));

interface FormRunnerStepsControlsProps {
  onNext: () => void;
  onPrev: () => void;
  active: number;
  numberOfPages: number;
  moveUpNavigation?: number;
}

export const FormRunnerStepsControls: React.FC<FormRunnerStepsControlsProps> = ({ onPrev, onNext, active, numberOfPages, moveUpNavigation }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.controlsWrapper} style={{ bottom: moveUpNavigation ?? 0 }}>
      <Group position={'center'}>
            <Button variant="default" onClick={onPrev} disabled={active === 0}>
              Föregående
            </Button>
            <Button onClick={onNext} disabled={active === numberOfPages}>
              Nästa
            </Button>
          </Group>
    </div>
  );
};
