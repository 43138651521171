import { t } from 'ttag';

export enum ApplicationPeriodType {
  /// <summary>
  /// Application period with start and end date
  /// </summary>
  Default,

  /// <summary>
  /// Application period with no end date
  /// </summary>
  Ongoing,

  /// <summary>
  /// Application period recurring on a yearly basis. Start and end date must be within the same year.
  /// </summary>
  Recurring,
}

export const ApplicationPeriodTypeItems: Record<ApplicationPeriodType, string> = {
  [ApplicationPeriodType.Default]: t`Fasta datum`,
  [ApplicationPeriodType.Ongoing]: t`Löpande`,
  [ApplicationPeriodType.Recurring]: t`Återkommande`,
};
