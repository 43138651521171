import React, { useEffect, useState } from 'react';
import {
  Title,
  Group,
  Button,
  Text,
  Tabs,
  TextInput,
  Textarea,
  Stack,
  Input,
  Anchor,
  Paper,
  PasswordInput,
  Flex,
  SimpleGrid,
  Grid,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useSession } from '../../hooks/useSession';
import { useApi } from '../../hooks/useApi';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { GrantMeTitleContainer } from '../../layout/GrantMeTitleHeader';
import { t } from 'ttag';
import { useParams } from 'react-router-dom';
import { Invitation } from '../../models/Invitation';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../AuthConfig';
import { INPUT_MAX_LENGTH } from '../../App';
import { MAX_FORM_WIDTH } from '../../layout/Layout';

interface FormValues {
  firstname: string;
  lastname: string;
  password: string;
  confirmPassword: string;
  email: string;
}

export const InvitationView: React.FC = () => {
  const api = useApi();
  const { invitationKey } = useParams();
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const { instance } = useMsal();

  const form = useForm<FormValues>({
    initialValues: { firstname: '', lastname: '', password: '', confirmPassword: '', email: '' },
    validate: {
      firstname: (value) => (value || value.length > 0 ? null : 'Förnamn måste anges'),
      lastname: (value) => (value || value.length > 0 ? null : 'Efternamn måste anges'),
      password: (value) =>
        value && value.length >= 8 ? null : 'Lösenord måste innehålla minst 8 tecken.',
      confirmPassword: (value, values) =>
        value || value.length > 0
          ? value === values.password
            ? null
            : 'Lösenorden matchar inte'
          : 'Bekräfta lösenord',
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (invitationKey) {
        const response = await api.getInvitation(invitationKey);
        if (response.ok) {
          form.setValues(await response.json());
        } else {
          setShowLogin(true);
        }

      }
    };

    fetchData();
  }, []);

  const acceptInvitation = async (values: FormValues) => {
    if (invitationKey) {
      const result = await api.acceptInvitation(
        invitationKey,
        values.firstname,
        values.lastname,
        values.password,
      );

      if (result.ok) {
        setShowLogin(true);
      } else {
      }
    }
  };

  return (
    <GrantMeContainer size="xl">
      {showLogin ? (
        <Stack spacing={'md'} p="xl">
          <Title order={3}>{t`Registrering slutförd!`}</Title>
          Du kan nu klicka på knappen nedan för att logga in.
          <Group>
            <Button
              size="sm"
              onClick={async () => {
                const clone = { ...loginRequest };
                clone.loginHint = form.values.email;
                await instance.loginRedirect(clone);
              }}>
              Logga in
            </Button>
          </Group>
        </Stack>
      ) : (
        <form onSubmit={form.onSubmit(async (values: FormValues) => acceptInvitation(values))}>
          <Stack spacing={0}>
            <GrantMeTitleContainer>
              <Title order={3}>{t`Välkommen!`}</Title>
            </GrantMeTitleContainer>

            <Grid p={24} gutter={16} maw={MAX_FORM_WIDTH}>
              <Grid.Col span={12}>
                <Text>{t`Välj ett lösenord för att slutföra registreringen.`}</Text>
              </Grid.Col>
              <Grid.Col md={6}>
                <TextInput
                  label={t`Förnamn`}
                  {...form.getInputProps('firstname')}
                  maxLength={INPUT_MAX_LENGTH}
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col md={6}>
                <TextInput
                  label={t`Efternamn`}
                  {...form.getInputProps('lastname')}
                  maxLength={INPUT_MAX_LENGTH}
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col md={12}>
                <PasswordInput
                  placeholder={t`Lösenord`}
                  label={t`Lösenord`}
                  {...form.getInputProps('password')}
                  description={t`Lösenordet måste bestå av minst 8 tecken`}
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col md={12}>
                <PasswordInput
                  placeholder={t` Bekräfta lösenord`}
                  label={t`Bekräfta lösenord`}
                  {...form.getInputProps('confirmPassword')}
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col md={12}>
                <Group position="right" mt="md">
                  <Button type="submit" radius="md">
                    {t`Fortsätt`}
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </Stack>
        </form>
      )}
    </GrantMeContainer>
  );
};
