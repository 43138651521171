import { Badge, Center, Grid, SimpleGrid, Stack, Tooltip } from '@mantine/core';
import { ReactNode } from 'react';
import { CellRenderInformation, RenderCell } from '../../../components/LiitGrid/RenderCell';
import { ApplicationStatuses } from '../../application/common/ApplicationStatuses';

export const RenderTagsCell: RenderCell = (
  rowInformation: CellRenderInformation,
): ReactNode => {
  const { cellValue, theme } = rowInformation;

  return (

    <>
    { cellValue && cellValue.map(tag => (
      <Badge key={tag.id} size="sm" mr={6} color={tag.color} radius="md" variant="dot">{tag.name}</Badge>
    ))}
    </>
    
  );
};
