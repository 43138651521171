import { t } from 'ttag';
import { isMissing, isPresent } from 'utilitype';

export interface EmailTextItem {
  text: string;
  color: string | undefined;
}

export enum EmailParameters {
  FirstName,
  LastMame,
  PeriodName,
  ApplicationNumber,
  Title,
  GrantedAmount,
}

export const EmailParametersToCheck: EmailParameters[] = [
  EmailParameters.FirstName,
  EmailParameters.LastMame,
  EmailParameters.PeriodName,
  EmailParameters.ApplicationNumber,
  EmailParameters.Title,
  EmailParameters.GrantedAmount,
];

export const EmailParameterInformation: Record<
  EmailParameters,
  { parameter: string; dummyValue: string; color: string; description: string }
> = {
  [EmailParameters.FirstName]: {
    parameter: '{{firstname}}',
    dummyValue: t`FÖRNAMN`,
    color: 'red.5',
    description: t`Förnamn på ansökaren`,
  },
  [EmailParameters.LastMame]: {
    parameter: '{{lastname}}',
    dummyValue: t`EFTERNAMN`,
    color: 'teal.5',
    description: t`Efternamn på ansökaren`,
  },
  [EmailParameters.PeriodName]: {
    parameter: '{{periodname}}',
    dummyValue: t`PERIODNAMN`,
    color: 'blue.5',
    description: t`Periodens namn`,
  },
  [EmailParameters.ApplicationNumber]: {
    parameter: '{{number}}',
    dummyValue: '00-0000',
    color: 'green.5',
    description: t`Diarienummer`,
  },
  [EmailParameters.Title]: {
    parameter: '{{title}}',
    dummyValue: t`TITEL`,
    color: 'pink.5',
    description: t`Titel på ansökan`,
  },
  [EmailParameters.GrantedAmount]: {
    parameter: '{{grantedamount}}',
    dummyValue: '000000',
    color: 'cyan.5',
    description: t`Beviljat belopp`,
  },
};

export const checkForParameters = (text: string, parameterIndex = 0): EmailTextItem[] => {
  const texts = text.split(
    EmailParameterInformation[EmailParametersToCheck[parameterIndex]].parameter,
  );

  const parameterResults = texts.reduce<EmailTextItem[]>((resultList, item, index) => {
    const canGoDeeper = parameterIndex + 1 < EmailParametersToCheck.length;

    if (texts.length > index + 1) {
      if (!item) {
        const parameter = EmailParameterInformation[EmailParametersToCheck[parameterIndex]];
        return [...resultList, { text: parameter.dummyValue, color: parameter.color }];
      }

      const goDeeper = canGoDeeper
        ? checkForParameters(item, parameterIndex + 1)
        : [{ text: item, color: undefined }];
      const parameter = EmailParameterInformation[EmailParametersToCheck[parameterIndex]];

      return [
        ...resultList,
        ...goDeeper,
        { text: parameter.dummyValue, color: parameter.color } as EmailTextItem,
      ];
    }

    const goDeeper = canGoDeeper
      ? checkForParameters(item, parameterIndex + 1)
      : [{ text: item, color: undefined }];

    return [...resultList, ...goDeeper];
  }, []);

  return parameterResults.filter((x) => !isMissing(x) && x.text !== '');
};
