import { ActionIcon, Card, Group, Text, Collapse, useMantineTheme, createStyles } from '@mantine/core';
import { IconChevronDown, TablerIcon } from '@tabler/icons';
import React, { useState } from 'react';

interface DashboardCardProps {
  title: string;
  icon?: TablerIcon;
  content?: React.ReactNode;
  collapse?: React.ReactNode;
}

const useStyles = createStyles(() => ({
  expandIcon: {
    transition: '0.3s',
  },
  iconRotate: {
    transform: 'rotate(180deg)',
  },
}));

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  icon: Icon,
  content,
  collapse,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();

  return (
    <Card p="xl" radius="sm" m="xl">
      <Card.Section inheritPadding>
        <Group position="apart" mt="md" mb="xs">
          <Group>
            <Text fz="sm" color="gray.8" fw={700}>
              {title}
            </Text>
          </Group>
          {collapse && (
            <ActionIcon variant="transparent" size={20} onClick={() => setCollapsed((o) => !o)}>
              <IconChevronDown className={cx(classes.expandIcon, { [classes.iconRotate]: collapsed === false })} size={20} color={theme.colors.blue[8]} />
            </ActionIcon>
          )}
        </Group>
      </Card.Section>
      <Card.Section bg="white" inheritPadding pt="xs" pb="sm" mih={140}>
        {content}
        {collapse && (
          <Collapse in={!collapsed} pt={'xl'}>
            {collapse}
          </Collapse>
        )}
      </Card.Section>
    </Card>
  );
};
