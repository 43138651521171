import { DateTime } from 'luxon';
import { isPresent } from 'utilitype';

export default class Formatter {
  static formatDateString(value: string | null) {
    return isPresent(value) ? DateTime.fromISO(value).toFormat('yyyy-MM-dd') : '';
  }

  static formatDateTimeString(value: string | null) {
    return isPresent(value) ? DateTime.fromISO(value).toLocal().toFormat('yyyy-MM-dd HH:mm') : '';
  }  

  static formatLocalDateTimeString(value: string | null) {
    return isPresent(value) ? DateTime.fromISO(value, { zone: 'utc' }).toLocal().toFormat('yyyy-MM-dd HH:mm') : '';
  }

  static formatDate(value: Date | null): string {
    return isPresent(value) ? DateTime.fromJSDate(value).toFormat('yyyy-MM-dd') : '';
  }

  static formatNumber(value: number) {
    return Intl.NumberFormat('sv-SE', {
      maximumFractionDigits: 2,
    }).format(value);
  }

  static formatBoolean(value: boolean) {
    return value ? 'Ja' : 'Nej';
  }

  static formatCurrency(value: number | null, zeroIndicator = '-') {
    if (!value) return zeroIndicator;

    return Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: 'SEK',
      maximumFractionDigits: 0,
    }).format(value);
  }
}
